import React from 'react';
import styled from 'styled-components';

import { TranslationKeys as T } from '../../../../core/constants/translationKeys';
import useTranslation from '../../../../hooks/useTranslation';
import { Button } from '..';

const ActionsWr = styled.div`
  display: flex;
`;

export const EditActions = ({
  onSave,
  onEdit,
  loading,
  onCancel,
  editMode,
  className,
  saveDisabled,
  editDisabled
}) => {

  const translate = useTranslation();

  return (
    <ActionsWr className={className}>
      {
        editMode ? (
          <>
            <Button
              key="cancel"
              size="medium"
              color="primary"
              onClick={onCancel}
              appearance="minimal"
              className="section-btn"
            >
              { translate(T.CANCEL) }
            </Button>
            <Button
              key="save"
              size="medium"
              color="primary"
              onClick={onSave}
              loading={loading}
              className="section-btn"
              disabled={saveDisabled || loading}
            >
              { translate(T.SAVE) }
            </Button>
          </>
        ) : (
          <Button
            key="edit"
            size="medium"
            color="primary"
            onClick={onEdit}
            className="section-btn"
            icon="bc-icon-edit-pen"
            disabled={editDisabled}
          >
            { translate(T.EDIT) }
          </Button>
        )
      }
    </ActionsWr>
  );
};
