import TypeChecker from './TypeChecker';
import { getDefaultDateRange } from './dateHelper';

export const parseQuery = queryString => {
  const query = {};
  if (!queryString) return query;
  const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (let i = 0; i < pairs.length; i++) {

    const pair = pairs[i].split('=');
    if (query[decodeURIComponent(pair[0])]) {
      if (Array.isArray(query[decodeURIComponent(pair[0])])) {


        query[decodeURIComponent(pair[0])].push(decodeURIComponent(pair[1] || ''));
      } else {

        query[decodeURIComponent(pair[0])] = [query[decodeURIComponent(pair[0])], decodeURIComponent(pair[1] || '')];
      }
    } else {
      query[decodeURIComponent(pair[0])] = (decodeURIComponent(pair[1])) ? decodeURIComponent(pair[1]) : decodeURIComponent(
        pair[1] || '');
    }
  }
  return query;
};

export const combineQuery = (queries) => {
  if (typeof queries === 'string') {
    return queries;
  } else {
    let query = '?';
    for (let key in queries) {
      if (key) {
        if (Array.isArray(queries[key])) {
          queries[key].forEach(val => {
            query += `${key}=${encodeURIComponent(val)}&`;
          });
        } else {
          if (!TypeChecker.isUndefined(queries[key]) && !TypeChecker.isNull(queries[key]) && !TypeChecker.isEmptyString(queries[key])) {
            query += `${key}=${encodeURIComponent(queries[key])}&`;
          }
        }
      }
    }
    return query.slice(0, -1);
  }
};

export const setDefaultQuery = (games, queryObject, history, setIsTestValue) => {
  const { start, end } = getDefaultDateRange();

  const queryObj = {
    dateFrom: queryObject.dateFrom || start,
    dateTo: queryObject.dateTo || end,
    ...queryObject,
  };
  if (setIsTestValue) {
    queryObj.isTest = queryObject.isTest || false;
  }
  if (
    games.length &&
    (
      (queryObject.hasOwnProperty('gameId') && !games.find(({ id }) => String(id) === String(queryObj.gameId)))
      ||
      !queryObject.hasOwnProperty('gameId')
    )
  ) {
    queryObj.gameId = games.find(game => game.id === Number(queryObject.gameId)) || games[0].id;
  }
  history.push(combineQuery({
    ...queryObj,
  }));
  return queryObj;
};

export const removeFalseValues = query => query.replace(/[&|]?\w+=(undefined|null)[&|]?/g, (match) => {
  if (match.match(/&/g)?.length > 1) {
    return '&';
  } else {
    return '';
  }
});
