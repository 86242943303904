import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

export const Hyperlink = ({
  children,
  ...restProps
}) => {

  const handleClick = useCallback((event) => {
    event.stopPropagation();
  }, []);

  return (
    <Link
      {...restProps}
      onClick={handleClick}
    >
      {children}
    </Link>
  );
};
