import { action, flow, makeObservable, observable } from 'mobx';

import { HttpResponse } from '../../core/classes/HttpResponse';
import { staticContentsService } from './staticContentsService';

class StaticContentsStore {
  /** @type {HttpResponse<StaticContentsResponse>}*/
  staticContents = new HttpResponse({ items: [], count: 0 });

  constructor() {
    makeObservable(this, {
      staticContents: observable,
      setStaticContents: action,
    });
  }

  /**
   * @name setStaticContents
   * @param {HttpResponse<StaticContentsResponse>} staticContents
   * @returns {void}
   */
  setStaticContents = (staticContents) => {
    this.staticContents = staticContents;
  };

  /**
   * @name addStaticContents
   * @param {StaticContentBody} data
   * @returns {Promise<void>}
   */
  addStaticContents = (data) => {
    return staticContentsService.addStaticContents(data);
  };

  /**
   * @name editStaticContents
   * @param {StaticContentBody} data
   * @returns {Promise<void>}
   */
  editStaticContents = (data) => {
    return staticContentsService.editStaticContents(data);
  };

  /**
   * @name deleteStaticContents
   * @param {number} id
   * @returns {Promise<void>}
   */
  deleteStaticContents = (id) => {
    return staticContentsService.deleteStaticContents(id);
  };

  /**
   * @name getStaticContents
   * @param {StaticContentsParams} params
   * @returns {Promise<StaticContentsResponse>}
   */
  getStaticContents = async function (params) {
    this.setStaticContents(this.staticContents.fetching(params));
    const staticContents = await staticContentsService.getStaticContents(params);
    this.setStaticContents(this.staticContents.fetched(staticContents));
    return staticContents;
  };
}

export const staticContentsStore = new StaticContentsStore();
