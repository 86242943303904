import React from 'react';
import { Breadcrumb as CoreBreadcrumb } from '@geneui/components';

export const Breadcrumb = ({
  ...props
}) => {
  return (
    <CoreBreadcrumb
      {...props}
    />
  );
};
