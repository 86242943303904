import React, { useMemo } from 'react';
import { DatePickerInput } from '@geneui/components';
import moment from 'moment';

import { TranslationKeys as T } from '../../../../core/constants/translationKeys';
import { RangeDatePickerOptions } from '../../../../core/helpers/dateHelper';
import { DATE_FORMAT_D_M_Y_H_M_S } from '../../../../core/constants/util';
import useTranslation from '../../../../hooks/useTranslation';

export const DatePickerWithRange = ({
  value: propsValue,
  placeholder = T.DATE,
  ...props
}) => {

  const translate = useTranslation();

  const value = useMemo(() => propsValue.map(item => (
    item ? moment(moment.utc(item).toDate()).local().format('YYYY-MM-DD HH:mm:ss') : null)
  ), [propsValue]);

  const pickerProps = useMemo(() => ({
    withRangeOptions: true,
    rangeOptions: RangeDatePickerOptions
      .filter(({ showInDatePicker }) => showInDatePicker)
      .map(({ name, start, end }) => ({ name: translate(name()), start: start(), end: end() }))
  }), [translate]);

  return (
    <DatePickerInput.WithRange
      withTime
      name="date"
      value={value}
      autoComplete="off"
      popoverAlign="start"
      labelAppearance="swap"
      pickerProps={pickerProps}
      isIncludeEndDateLastSecond
      format={DATE_FORMAT_D_M_Y_H_M_S}
      placeholder={translate(placeholder)}
      {...props}
    />
  );
};
