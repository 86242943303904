/**
 * Replace translation dynamic values string.
 * This function returns value replaced string.
 * @returns {String}
 */
export const setTranslationValues = (str = '', valuesObj = {}) => {
  // eslint-disable-next-line guard-for-in
  for (const key in valuesObj) {
    // eslint-disable-next-line no-param-reassign
    str = str.replaceAll(`{{${key}}}`, `${valuesObj[key]}`);
  }

  return str;
};
