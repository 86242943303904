import { observer } from 'mobx-react-lite';
import React from 'react';

import { hasPermission } from '../../../core/helpers/permissionsHelper';
import AccessDenied from '../../pages/access-denied';

const PermissionGuard = observer(({
  children,
  isPage = false,
  checkAll = true,
  requiredPermissions = []
}) => {
  if (hasPermission(requiredPermissions, checkAll)) return children;
  return isPage ? <AccessDenied /> : null;
});

export default PermissionGuard;
