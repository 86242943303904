import { ApiVersion } from '../../core/enums/ApiVersion';
import { httpClient } from '../httpClient/httpClient';
import {
  addPlatformsUrl,
  editPlatformsUrl,
  getPlatformsUrl,
  getPlatformGamesUrl,
  getPlatformInfoUrl,
  editPlatformGamesUrl,
  getPlatformPartnersUrl,
  addPlatformGameLimitsUrl,
  editHasTournamentPlatformPartnerUrl,
  editPlatformPartnersRealModeSwitcherUrl,
} from '../../core/constants/endpoints';
import { abortFetch } from '../../core/helpers/abortController';
import { ABORT_API_KEYS } from '../../core/constants/abortApiKeys';

class PlatformService {
  /**
   * @name addPlatforms
   * @param {PlatformBody} data
   * @param {ApiOption=} option
   * @return {Promise<Platform>}
   */
  async addPlatforms(data, option = { version: ApiVersion.V1 }) {
    return await httpClient.post(addPlatformsUrl(option), data);
  }

  /**
   * @name editPlatforms
   * @param {EditPlatformBody} data
   * @param {ApiOption=} option
   * @return {Promise<void>}
   */
  async editPlatforms(data, option = { version: ApiVersion.V1 }) {
    return await httpClient.put(editPlatformsUrl(option), data);
  }

  /**
   * @name getPlatforms
   * @param {PlatformParams=} params
   * @param {ApiOption=} option
   * @return {Promise<PlatformResponse>}
   */
  async getPlatforms(params = {}, option = { version: ApiVersion.V1 }) {
    const signal = abortFetch(ABORT_API_KEYS.GetPlatforms);
    return await httpClient.get(getPlatformsUrl(option), { params, signal });
  }

  /**
   * @name getPlatformsPartners
   * @param {PartnersPlatformsParams} params
   * @param {ApiOption=} option
   * @return {Promise<PartnersPlatformResponse>}
   */
  async getPlatformsPartners(params, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getPlatformPartnersUrl(option), { params });
  }

  /**
   * @name getPlatformGames
   * @param {number} id required
   * @param {ApiOption=} option
   * @returns {Promise<PlatformGamesResponse>}
   */
  async getPlatformGames(id, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getPlatformGamesUrl(id, option));
  }

  /**
   * @name getPlatformInfo
   * @param {number} id required
   * @param {ApiOption=} option
   * @returns {Promise<PlatformGamesResponse>}
   */
  async getPlatformInfo(id, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getPlatformInfoUrl(id, option));
  }

  /**
   * @name editPlatformGames
   * @param {EditPlatformGamesBody} data
   * @param {ApiOption=} option
   * @returns {Promise<PlatformGamesResponse>}
   */
  async editPlatformGames(data, option = { version: ApiVersion.V1 }) {
    return await httpClient.put(editPlatformGamesUrl(option), data);
  }

  /**
   * @name editPlatformPartnersRealModeSwitcher
   * @param {ApiOption=} option
   */
  async editPlatformPartnersRealModeSwitcher(id, data, option = { version: ApiVersion.V1 }) {
    return await httpClient.put(editPlatformPartnersRealModeSwitcherUrl(id, option), data);
  }

  async editHasTournamentPlatformPartner(id, data, option = { version: ApiVersion.V1 }) {
    return await httpClient.put(editHasTournamentPlatformPartnerUrl(id, option), data);
  }

  /**
   * @name addPlatformGameLimits
   * @param {number} id
   * @param {AddPlatformGameLimits} data
   * @param {ApiOption} option
   * @return {Promise<void>}
   */
  async addPlatformGameLimits(id, data, option = { version: ApiVersion.V1 }) {
    return httpClient.post(addPlatformGameLimitsUrl(id, option), data);
  }
}

export const platformService = new PlatformService();
