import React from 'react';

import useTranslation from '../../../../hooks/useTranslation';
import { TranslationKeys as T } from '../../../../core/constants/translationKeys';
import { Button } from '..';

export const ModalFooter = ({
  onSave,
  onCancel,
  saveDisabled,
  hideSave = false,
  saveText = T.SAVE,
  hideCancel = false,
  saveColor = 'primary',
  actionLoading = false,
  cancelText = T.CANCEL,
  flexibility = "",
}) => {

  const translate = useTranslation();

  return (
    <>
      {
        !hideCancel && (
          <Button
            color="default"
            onClick={onCancel}
            appearance="minimal"
            flexibility={flexibility}
          >
            { translate(cancelText) }
          </Button>
        )
      }
      {
        !hideSave && (
          <Button
            onClick={onSave}
            color={saveColor}
            appearance="default"
            loading={actionLoading}
            disabled={saveDisabled}
            flexibility={flexibility}
          >
            { translate(saveText) }
          </Button>
        )
      }
    </>
  );
};
