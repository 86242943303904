import React from 'react';
import { Section as CoreSection } from '@geneui/components';

export const Section = ({
  ...props
}) => {
  return (
    <CoreSection
      {...props}
    />
  );
};
