import React from 'react';
import styled from 'styled-components';

import useTranslation from '../../../hooks/useTranslation';
import { Icon } from '../../../view/components/shared';

const Title = styled.div`
  display: flex;
  grid-gap: 1rem;
  align-items: center;

  & .alert-icon {
    display: inline-block;
    margin-right: 10px;
  }
`;

const TitleText = styled.p`
  display: inline-block;
  padding-top: 3px;
`;

export const ToasterTitle = ({ title, icon, iconColor }) => {

  const translate = useTranslation();

  return (
    <Title>
      <Icon type={icon} style={{color: iconColor}} className='alert-icon' />
      <TitleText>
        {translate(title)}
      </TitleText>
    </Title>
  );
};