/**
 * @name UserType
 * @enum {number}
 * @type {{Test: number, Real: number}}
 */
export const UserType = {
  Real: 0,
  Test: 1,
};

/**
 * @name UserBooleanType
 * @enum {boolean}
 * @type {{Test: boolean, Real: boolean}}
 */
export const UserBooleanType = {
  Real: false,
  Test: true,
};
