import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Icon } from '../shared';
import { modalStore } from '../../../module/popUpModule';
import useTranslation from '../../../hooks/useTranslation';
import { TranslationKeys } from '../../../core/constants/translationKeys';
import PermissionGuard from '../../containers/permissionGuard';
import { PERMISSIONS } from '../../../core/constants/permissions';

const Content = styled.section`
  --item-size: 88px;
  display: grid;
  grid-template-columns: repeat(3, var(--item-size));
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 322px;
  padding: 10px;
  grid-gap: 10px;
  box-sizing: border-box;
`;

const Item = styled.div`
  text-align: center;
  box-sizing: border-box;
  padding: 10px 14px;
  width: var(--item-size);
  min-height: var(--item-size);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
  }

  &:hover span {
    color: #1473e6;
  }

  &:hover > div {
    background: #1473e6;
    color: #FFFFFF;
  }
`;

const IconContent = styled.div`
  width: 50px;
  height: 50px;
  min-height: 50px;
  min-width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: rgba(0, 0, 0, .03);
  transition-duration: .25s;
`;

const NameContent = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

const Name = styled.span`
  margin-top: 8px;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  color: #3c4043;
  transition-duration: .25s;
`;

const CreateButtonContainer = ({ setOpen }) => {

  const translate = useTranslation();
  const history = useHistory();

  const handleOpenCreatePlatformPopup = useCallback(() => {
    modalStore.openCreatePlatformModal();
    setOpen(false);
  }, []);

  const handleOpenCreatePartnerPopup = useCallback(() => {
    modalStore.openPartnerModal();
    setOpen(false);
  }, []);

  const handleRedirectBonusIsland = useCallback(() => {
    history.push('/bonus/game-master/add');
    setOpen(false);
  }, [history]);

  const navigateToCreateFreeBet = useCallback(() => {
    history.push('/bonus/free-bet/add');
    setOpen(false);
  }, []);

  const handleRedirectCreatePGTournament = useCallback(() => {
    history.push('/pg-tournament/add');
    setOpen(false);
  }, [history]);

  return (
    <Content>
      <PermissionGuard requiredPermissions={[PERMISSIONS.AddPlatform]}>
        <Item
          onClick={handleOpenCreatePlatformPopup}
        >
          <IconContent>
            <Icon
              size="big"
              color="default"
              type="bc-icon-pages"
            />
          </IconContent>
          <NameContent>
            <Name>{translate(TranslationKeys.PLATFORM)}</Name>
          </NameContent>
        </Item>
      </PermissionGuard>
      <PermissionGuard requiredPermissions={[PERMISSIONS.AddPartner]}>
        <Item
          onClick={handleOpenCreatePartnerPopup}
        >
          <IconContent>
            <Icon
              size="big"
              color="default"
              type="bc-icon-user"
            />
          </IconContent>
          <NameContent>
            <Name>{translate(TranslationKeys.PARTNER)}</Name>
          </NameContent>
        </Item>
      </PermissionGuard>
      <PermissionGuard requiredPermissions={[PERMISSIONS.UpdateBonusIsland]}>
        <Item
          onClick={handleRedirectBonusIsland}
        >
          <IconContent>
            <Icon
              size="big"
              color="default"
              type="bc-icon-send-bonus-block"
            />
          </IconContent>
          <NameContent>
            <Name>{translate(TranslationKeys.BONUS_ISLAND)}</Name>
          </NameContent>
        </Item>
      </PermissionGuard>
      <PermissionGuard requiredPermissions={[PERMISSIONS.UpdateFreebet]}>
        <Item
          onClick={navigateToCreateFreeBet}
        >
          <IconContent>
            <Icon
              size="big"
              color="default"
              type="bc-icon-crm-free-bet"
            />
          </IconContent>
          <NameContent>
            <Name>{translate(TranslationKeys.FREE_BET)}</Name>
          </NameContent>
        </Item>
      </PermissionGuard>
      <PermissionGuard requiredPermissions={[PERMISSIONS.UpdateTournament]}>
        <Item
          onClick={handleRedirectCreatePGTournament}
        >
          <IconContent>
            <Icon
              size="big"
              color="default"
              type="bc-icon-assign-tournament"
            />
          </IconContent>
          <NameContent>
            <Name>{translate(TranslationKeys.PG_TOURNAMENT)}</Name>
          </NameContent>
        </Item>
      </PermissionGuard>

    </Content>
  );
};

export default CreateButtonContainer;
