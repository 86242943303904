import React from 'react';
import { Widget as CoreWidget } from '@geneui/components';

import { TranslationKeys as T } from '../../../../core/constants/translationKeys';
import useTranslation from '../../../../hooks/useTranslation';

export const Widget = ({
  shadow = false,
  size = 'medium',
  type = 'compact',
  withBorder = true,
  titleIcon = 'bc-icon-info-48',
  noDataText = T.NO_DATA_TO_DISPLAY,
  ...props
}) => {

  const translate = useTranslation();

  const noDataTextTr = translate(noDataText);

  return (
    <CoreWidget
      size={size}
      type={type}
      shadow={shadow}
      titleIcon={titleIcon}
      withBorder={withBorder}
      noDataText={noDataTextTr}
      {...props}
    />
  );
};
