import React from 'react';
import styled from 'styled-components';

import { TranslationKeys as T } from '../../../../core/constants/translationKeys';
import useTranslation from '../../../../hooks/useTranslation';

import Logo from '../../../../assets/images/logo.png';

const Content = styled.section`
  left: 50%;
  top: 50%;
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: center;
  transform: translate(-50%, -50%);
`;

const ContentBottom = styled.section`
  display: flex;
  align-items: center;
`;

const Text = styled.section`
  margin-left: 1rem;
`;

const LogoImg = styled.img`
  padding: 2rem;
  width: 24rem;
`;

export const ItemNotFound = ({ message }) => {

  const translate = useTranslation();

  return (
    <Content>
      <LogoImg src={Logo} />
      <ContentBottom>
        <Text>{translate(message || T.ITEM_NOT_FOUND)}</Text>
      </ContentBottom>
    </Content>
  );
};
