import React, { useCallback, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import useOutSideClick from '../../../../hooks/useOutSideClick';
import { Popover } from '..';

const ContentWrapper = styled.section``;

export const PopoverWithHover = ({
  isOpen,
  Content,
  children,
  setIsOpen,
  ignoreClassName = 'ignore',
  ...restProps
}) => {
  const controlled = !!setIsOpen;
  const [open, setOpen] = useState(false);
  const ref = useRef();

  const toggleIsOpen = useMemo(() => controlled ? setIsOpen : setOpen, []);

  useOutSideClick(ref, () => {
    toggleIsOpen(false);
  }, ignoreClassName);

  const handleOpenToggle = useCallback(() => {
    toggleIsOpen(prev => !prev);
  }, []);

  const handleClose = useCallback(() => {
    toggleIsOpen(false);
  }, []);

  return (
    <Popover
      contentRef={ref}
      isOpen={controlled ? isOpen : open}
      Content={(
        <ContentWrapper onClick={handleClose}>
          {Content}
        </ContentWrapper>
      )}
      toggleHandler={handleOpenToggle}
      {...restProps}
    >
      {children}
    </Popover>
  );
};
