import React from 'react';
import styled from 'styled-components';

import { TranslationKeys as T } from '../../../../core/constants/translationKeys';
import useTranslation from '../../../../hooks/useTranslation';
import { Icon } from '..';

const DownloadWr = styled.div`
  box-sizing: border-box;
  & .download-icon {
    display: inline-flex;
    margin-right: 10px;
    align-items: center;
    font-size: 22px;
  }
`;

const DownloadLink = styled.a`
  display: flex;
  width: max-content;
  color: #378be2;
  align-items: center;
  font-size: 14px;
`;

export const DownloadFileExample = ({
  href,
  style,
  name,
}) => {

  const translate = useTranslation();

  return (
    <DownloadWr style={style}>
      <DownloadLink download={name} href={href}>
        <Icon
          type="bc-icon-download"
          className="download-icon"
        />
        <span>
          { translate(T.FILE_EXAMPLE) }
        </span>
      </DownloadLink>
    </DownloadWr>
  );
};
