import { betsService } from './betsService';

class BetsStore {
  /**
   * @name doBet
   * @param {DoBetBody} data
   * @returns {Promise<void>}
   */
  doBet = (data) => {
    return betsService.doBet(data);
  };

  /**
   * @name doRollback
   * @param {DoRollbackBody} data
   * @returns {Promise<void>}
   */
  doRollback = (data) => {
    return betsService.doRollback(data);
  };

  /**
   * @name doWin
   * @param {WinBody} data
   * @returns {Promise<void>}
   */
  doWin = (data) => {
    return betsService.doWin(data);
  };

  /**
   * @name batchWin
   * @param {Array<WinBody>} data
   * @returns {Promise<void>}
   */
  batchWin = (data) => {
    return betsService.batchWin(data);
  };
}

export const betsStore = new BetsStore();
