import React from 'react'
export const tableDataFormatter = (data, idKey) => data.map((item, index) => ({
  hasHover: true,
  dragDisable: true,
  id: idKey ? item[idKey] : index,
  data: {...item},
}));

export const tableDataFormatterMobile = (data, idKey) => data.map((item, index) => ({
  id: idKey ? item[idKey] : index,
  data: {...item},
  // expandDisabled: bool,
  // className: string,
  // render: func
}));

export const currentPageDataCalc = (freeBetRows, page, selectorValue) => (
  freeBetRows.slice((page - 1) * selectorValue, page * selectorValue)
);
