import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { combineQuery, parseQuery } from '../core/helpers/queryHelper';

/**
 * @name usePlatformPartnerFilter
 * @param {Array<PlatformPartnersResponseItem>} partnersPlatforms
 * @returns {{partnerIdsWithPlatform: Object<number, Array<number>>, handlePartnersSelect: ((function(*, *): void)|*)}}
 */
export const usePlatformPartnerFilter = (partnersPlatforms) => {
  const ref = useRef({ initial: false });
  const history = useHistory();
  const { search } = useLocation();
  const [state, setState] = useState({
    partnerIdsWithPlatform: {},
  });

  const platformPartnerMap = useMemo(() => {
    return partnersPlatforms.reduce((map, { id, partners }) => {
      map[id] = partners;
      return map;
    }, []);
  }, [partnersPlatforms]);


  useEffect(() => {
    const { platformIds, partnerIds: partnerIdsQuery } = parseQuery(search);
    const partnerIds = typeof partnerIdsQuery === 'string' ? [Number(partnerIdsQuery)] : partnerIdsQuery;
    if (!platformIds && !partnerIds?.length) {
      ref.current.initial = true;
    }
    if (ref.current.initial) {
      return;
    }


    if (Object.keys(platformPartnerMap).length) {
      if (platformIds !== undefined) {
        setState(() => ({
          partnerIdsWithPlatform: {
            [platformIds]: platformPartnerMap[platformIds]
              .filter(({ id }) => partnerIds?.some((partnerId) => +partnerId === id))
              .map(({ id }) => id),
          },
        }));
        ref.current.initial = true;
        return;
      }
      if (partnerIds !== undefined) {
        for (const id in platformPartnerMap) {
          const ids = platformPartnerMap[id].map(({ id }) => id);

          if (partnerIds.length === platformPartnerMap[id]?.length) {
            setState(() => ({
              partnerIdsWithPlatform: {
                [id]: ids,
              },
            }));
            ref.current.initial = true;
            return;
          }

          for (const idElement of partnerIds) {
            if (ids.includes(Number(idElement))) {
              setState(() => ({
                partnerIdsWithPlatform: {
                  [id]: partnerIds.map((n) => Number(n)),
                },
              }));
              ref.current.initial = true;
              return;
            }
          }
        }
      }
    }
  }, [partnersPlatforms, search, platformPartnerMap, ref]);

  const handleAddQuery = useCallback((data) => {
    const platformIds = [];
    const partnerIdList = [];
    for (const platformId in data.partnerIdsWithPlatform) {
      const partnerIds = data.partnerIdsWithPlatform[platformId];

      if (partnerIds?.length === platformPartnerMap[platformId]?.length) {
        platformIds.push(platformId);
      } else {
        partnerIdList.push(...partnerIds);
      }
    }

    const queryObj = parseQuery(search);
    delete queryObj.partnerIds;

    if (platformIds.length) {
      const [platformId] = platformIds;
      queryObj.platformIds = platformId;
    }

    if (partnerIdList.length) {
      queryObj.partnerIds = partnerIdList;
    }

    history.push({
      search: combineQuery(queryObj),
    });

  }, [platformPartnerMap, history, search]);

  const handlePartnersSelect = useCallback((platformId, partnerIds) => {
    setState(s => {
      const data = ({
        partnerIdsWithPlatform: {
          // ...s.partnerIdsWithPlatform, // TODO  add when we can have many selectedPlatforms
          [platformId]: partnerIds,
        },
      });
      handleAddQuery(data);
      return data;
    });
  }, [handleAddQuery]);


  return {
    partnerIdsWithPlatform: state.partnerIdsWithPlatform,
    handlePartnersSelect,
  };
};
