import React from 'react';
import { observer } from 'mobx-react-lite';

import { translationStore } from '../../../module';

const Translations = observer(({ translationKey }) => {
  const { backofficeTranslations: { data, isLoading } } = translationStore;
  return (
    <>
      {isLoading ? '...' : data[translationKey] || translationKey}
    </>
  );
});

const TranslationsText = Translations;

/**
 * @name translateText
 * @param {string} key
 * @param {boolean} isValue
 * @returns {JSX.Element|*}
 */
export const translateText = (key, isValue = false) => {
  if (isValue) {
    const { backofficeTranslations } = translationStore;
    return (
      backofficeTranslations.data[key] || key
    );
  }
  return (
    <TranslationsText translationKey={key}/>
  );
};
