import { action, computed, flow, makeObservable, observable } from 'mobx';

import { HttpResponse } from '../../core/classes/HttpResponse';
import { gamesService } from './gamesService';

class GamesStore {
  /** @type {HttpResponse<GameResponse>}*/
  games = new HttpResponse({ items: [], count: 0 });

  /** @type {HttpResponse<GameResponse>}*/
  gamesByLine = new HttpResponse({ items: [], count: 0 });

  /** @type {HttpResponse<GameResponse>}*/
  gamesByPartner = new HttpResponse({ items: [], count: 0 });

  /** @type {HttpResponse<GamePlatformsResponse>}*/
  gamesPlatforms = new HttpResponse({ platformsPartners: [] });

  /** @type {HttpResponse<Array<GameTypeByGame>>}*/
  gameTypesByGame = new HttpResponse([]);

  /** @type {HttpResponse<Array<number>>}*/
  gameLines = new HttpResponse([]);

  /** @type {HttpResponse<GetPlatformFeatureResponse>}*/
  platformFeature = new HttpResponse({ items: [], count: 0 });

  /** @type {HttpResponse<GetGameInfoResponse>}*/
  gameInfo = new HttpResponse({});

  /** @type {HttpResponse<GetGameConfigsResponse>}*/
  gameConfigs = new HttpResponse({});

  /** @type {HttpResponse<GamesFeaturePartnersResponse>} */
  gameFeaturePartners = new HttpResponse([]);

  /** @type {HttpResponse<GameResponse>}*/
  jackpotGames = new HttpResponse({ items: [], count: 0 })

  /**
   * @name gamesMap
   * @description returned object where key is Game's id value is game
   * @returns {Object<number, Game>}
   */
  get gamesMap() {
    return this.games.data.items.reduce((map, game) => {
      const { id } = game;
      map[id] = game;
      return map;
    }, {});
  }

  constructor() {
    makeObservable(this, {
      games: observable,
      gamesByLine: observable,
      gamesPlatforms: observable,
      gamesByPartner: observable,
      gameTypesByGame: observable,
      gameLines: observable,
      platformFeature: observable,
      gameInfo: observable,
      gameConfigs: observable,
      setGameFeaturePartners: action.bound,
      gameFeaturePartners: observable,
      jackpotGames: observable,
      gamesMap: computed,
      setGames: action.bound,
      setGamesByPartner: action.bound,
      setGamesByLine: action.bound,
      setGamesPlatforms: action.bound,
      setPlatformFeature: action.bound,
      setGameInfo: action.bound,
      setGameConfigs: action.bound,
      setJackpotGames: action.bound,
      resetGameByLine: action.bound,
      getGamesByLine: action.bound,
      setGameTypesByGame: action.bound,
      setGameLines: action.bound,
      getGames: flow.bound,
      getGamesPlatforms: flow.bound,
      getGamesByPartner: flow,
      getGameTypesByGame: flow,
      getGameLines: flow,
      getPlatformFeature: flow.bound,
      getGameInfo: flow.bound,
      getGameConfigs: flow.bound,
      getGameFeaturePartners: flow.bound,
      getJackpotGames: flow.bound,
    });
  }

  /**
   * @name setGames
   * @param {HttpResponse<GameResponse>} games
   * @returns {void}
   */
  setGames = (games) => {
    this.games = games;
  };

  /**
   * @name setJackpotGames
   * @param {HttpResponse<GameResponse>} jackpotGames
   * @returns {void}
   */
  setJackpotGames = (jackpotGames) => {
    this.jackpotGames = jackpotGames;
  };

  /**
   * @name setGamesByPartner
   * @param {HttpResponse<GameResponse>} games
   * @returns {void}
   */
  setGamesByPartner = (games) => {
    this.gamesByPartner = games;
  };

  /**
   * @name setGamesByLine
   * @param {HttpResponse<GameResponse>} games
   * @returns {void}
   */
  setGamesByLine = (games) => {
    this.gamesByLine = games;
  };

  /**
   * @name setGamesPlatforms
   * @param {HttpResponse<GamePlatformsResponse>} gamesPlatforms
   * @returns {void}
   */
  setGamesPlatforms = (gamesPlatforms) => {
    this.gamesPlatforms = gamesPlatforms;
  };

  /**
   * @name setGameTypesByGame
   * @param {HttpResponse<Array<GameTypeByGame>>} gameTypesByGame
   * @returns {void}
   */
  setGameTypesByGame = (gameTypesByGame) => {
    this.gameTypesByGame = gameTypesByGame;
  };

  /**
   * @name setGameLines
   * @param {HttpResponse<Array<number>>} data
   * @returns {void}
   */
  setGameLines = (data) => {
    this.gameLines = data;
  };

  /**
   * @name setPlatformFeature
   * @param {HttpResponse<GetPlatformFeatureResponse>} data
   * @returns {void}
   */
  setPlatformFeature = (data) => {
    this.platformFeature = data;
  };

  /**
   * @name setGameInfo
   * @param {HttpResponse<GetGameInfoResponse>} data
   * @returns {void}
   */
  setGameInfo = (data) => {
    this.gameInfo = data;
  };

  /**
   * @name setGameConfig
   * @param {GetGameConfigsResponse} data
   * @returns {void}
   */
  setGameConfigs = (data) => {
    this.gameConfigs = data;
  };

  /**
   * @name resetGameConfigs
   * @returns {void}
   */
  resetGameConfigs = () => {
    this.setGameConfigs(this.gameConfigs.fetched({}));
  };

  /**
   * @name setGameFeaturePartners
   * @param {GamesFeaturePartnersResponse} data
   */
  setGameFeaturePartners = (data) => {
    this.gameFeaturePartners = data;
  };

  /**
   * @name getGames
   * @param {GamesParams=} params
   * @returns {Generator<Promise<GameResponse>, void, *>}
   */
  getGames = function* (params = {}) {
    this.setGames(this.games.fetching());
    const games = yield gamesService.getAllGames(params);
    this.setGames(this.games.fetched(games));
  };

  /**
   * @name getJackpotGames
   * @param {GamesParams=} params
   * @returns {Generator<Promise<GameResponse>, void, *>}
   */
  getJackpotGames = function* (params = {}) {
    this.setJackpotGames(this.jackpotGames.fetching());
    const games = yield gamesService.getGames(params);
    this.setJackpotGames(this.jackpotGames.fetched(games));
  };

  /**
   * @name getGames
   * @param {GamesParams=} params
   * @returns {Generator<Promise<GameResponse>, void, *>}
   */
  getGamesByPartner = function* (params = {}) {
    this.setGamesByPartner(this.gamesByPartner.fetching());
    const games = yield gamesService.getGames(params);
    this.setGamesByPartner(this.gamesByPartner.fetched(games));
  };

  /**
   * @name getGamesByLine
   * @param {GamesParams=} params
   * @param params
   * @returns {Promise<{count: number, items: Array<Game>}>}
   */
  getGamesByLine = async (params = {}) => {
    this.setGamesByLine(this.gamesByLine.fetching());
    const gamesByLine = await gamesService.getGames(params);
    this.setGamesByLine(this.gamesByLine.fetched(gamesByLine));
    return gamesByLine;
  };

  /**
   * @name getGamesPlatforms
   * @param {number} id
   * @param {GamesParams=} params
   * @returns {Generator<Promise<GamePlatformsResponse>, void, *>}
   */
  getGamesPlatforms = function* (id, params = {}) {
    this.setGamesPlatforms(this.gamesPlatforms.fetching());
    const gamesPlatforms = yield gamesService.getGamesPlatforms(id, params);
    this.setGamesPlatforms(this.gamesPlatforms.fetched(gamesPlatforms));
  };

  /**
   * @name getGameTypesByGame
   * @param {number} id
   * @returns {Generator<Promise<Array<GameTypeByGame>>, void, *>}
   */
  getGameTypesByGame = function* (id) {
    this.setGameTypesByGame(this.gameTypesByGame.fetching());
    const gamesPlatforms = yield gamesService.getGameTypesByGame(id);
    this.setGameTypesByGame(this.gameTypesByGame.fetched(gamesPlatforms));
  };

  /**
   * @name getGameLines
   * @param {GetGameLinesParams} params
   * @returns {Generator<Promise<Array<number>>, void, *>}
   */
  getGameLines = function* (params = {}) {
    this.setGameLines(this.gameLines.fetching());
    const data = yield gamesService.getGameLines(params);
    this.setGameLines(this.gameLines.fetched(data));
  };

  /**
   * @name getPlatformFeatures
   * @param {GetPlatformFeatureParams} params
   * @returns {Generator<Promise<GetPlatformFeatureResponse>, void, *>}
   */
  getPlatformFeature = function* (params = {}) {
    this.setPlatformFeature(this.platformFeature.fetching());
    const data = yield gamesService.getGamesPlatformFeature(params);
    this.setPlatformFeature(this.platformFeature.fetched(data));
  };

  /**
   * @name getGameInfo
   * @param {number} id
   * @param {GetGameInfoResponse} params
   * @returns {Generator<Promise<GetGameInfoResponse>, void, *>}
   */
  getGameInfo = function* (id, params = {}) {
    this.setGameInfo(this.gameInfo.fetching());
    const data = yield gamesService.getGamesInfo(id, params);
    this.setGameInfo(this.gameInfo.fetched(data));
  };

  /**
   * @name getGameConfigs
   * @param {number} id
   * @returns {Generator<Promise<GetGameConfigsResponse>, void, *>}
   */
  getGameConfigs = function* (id) {
    this.setGameConfigs(this.gameConfigs.fetching());
    const data = yield gamesService.getGamesConfig(id);
    this.setGameConfigs(this.gameConfigs.fetched(data));
  };

  /**
   * @name getGameFeaturePartners
   * @param {number} id
   * @param {GetGamesFeaturePartnersParams} params
   * @return {Generator<Promise<void>, void, *>}
   */
  getGameFeaturePartners = function* (id, params) {
    this.setGameFeaturePartners(this.gameFeaturePartners.fetching());
    const data = yield gamesService.getGamesFeaturePartners(id, params);
    this.setGameFeaturePartners(this.gameFeaturePartners.fetched(data));
  };

  /**
   * @name addGame
   * @param {GameData} game
   * @returns {Promise<Game>}
   */
  addGame = (game) => {
    return gamesService.addGames(game);
  };

  /**
   * @name editGame
   * @param {GameData} game
   * @returns {Promise<void>}
   */
  editGame = (game) => {
    return gamesService.editGames(game);
  };

  /**
   * @name editGamePartners
   * @param {number} id
   * @param {EditGamePartnersBody} body
   * @return {Promise<void>}
   */
  editGamePartners = (id, body) => {
    return gamesService.editGamePartners(id, body);
  };

  /**
   * @name editGameConfigs
   * @param {number} id
   * @param {ConfigBody} body
   * @returns {Promise<void>}
   */
  editGameConfigs = (id, body) => {
    return gamesService.editGameConfigs(id, body);
  };

  /**
   * @name editGameFeature
   * @param {number} id
   * @param {EditGameFeatureBody} body
   * @returns {Promise<void>}
   */
  editGameFeature = (id, body) => {
    return gamesService.editGameFeature(id, body);
  };

  /**
   * @name editGameInfo
   * @param {ApiOption} body
   * @return {Promise<void>}
   */
  editGameInfo = (body) => {
    return gamesService.editGameInfo(body);
  };

  /**
   * @name createGameInfoFormData
   * @param {Object} values
   * @returns {Promise<GetGameInfoResponse>}
   */
  createGameInfoFormData(values) {
    const formData = new FormData();
    const { id, gameState, pgWebsiteLink, description, hasDemo, skipLobby, thumbnail, logo, hasTheme, defaultWageringMinOdd, displayName } = values;
    formData.append('id', id);
    formData.append('gameState', gameState);
    formData.append('pgWebsiteLink', pgWebsiteLink || '');
    formData.append('description', description);
    formData.append('hasDemo', hasDemo);
    formData.append('skipLobby', skipLobby);
    formData.append('hasTheme', hasTheme);
    formData.append('displayName', displayName);
    formData.append('defaultWageringMinOdd', defaultWageringMinOdd);
    if (thumbnail?.blob) {
      formData.append('thumbnail', thumbnail.blob, thumbnail.name);
    }
    if (logo?.blob) {
      formData.append('logo', logo.blob, logo.name);
    }
    return formData;
  };

  /**
   * @name resetGameByLine
   * @returns {void}
   */
  resetGameByLine = () => {
    this.setGamesByLine(this.gamesByLine.fetching(true));
  };

  resetJackpotGames = () => {
    this.setJackpotGames(this.jackpotGames.fetched({ items: [], count: 0 }));
  }
}

export const gamesStore = new GamesStore();
