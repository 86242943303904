import moment from 'moment';

import { DATE_FORMAT_BACK, DATE_FORMATS, DATE_FORMAT_D_M_Y_H_M_S, MONTHS } from '../constants/util';
import { DateRangeQuickFilters } from '../enums/date-range-quick-filters';
import { TranslationKeys as T } from '../constants/translationKeys';

export const getDefaultDateRange = () => {
  const start = moment().startOf('day').utc().format(DATE_FORMAT_BACK);
  const end = moment().add(1, 'days').startOf('day').utc().format(DATE_FORMAT_BACK);
  return { start, end };
};

export const dateToLocal = (date, format = DATE_FORMAT_D_M_Y_H_M_S) => moment(moment.utc(date).toDate()).format(format);
export const dateToUTC = date => moment(date, DATE_FORMAT_BACK).utc().format(DATE_FORMAT_BACK);
export const dateToUTCWithZ = date => `${moment(date, DATE_FORMAT_BACK).utc().format(DATE_FORMAT_BACK)}Z`;
export const dateToUTCFormatter = date => moment(date,
  DATE_FORMATS.TABLE_SHORT_TIME).utc().format(DATE_FORMATS.TABLE_SHORT_TIME_BACK);

export const RangeDatePickerOptions = [
  {
    type: DateRangeQuickFilters.Last6Hours,
    name: () => T.LAST_6_HOURS,
    start: () => moment().add(-6, 'hour').startOf('hour'),
    end: () => moment().startOf('hour'),
    showInDatePicker: false
  },
  {
    type: DateRangeQuickFilters.Today,
    name: () => T.TODAY,
    start: () => moment().startOf('day'),
    end: () => moment().add(1, 'day').startOf('day'),
    showInDatePicker: true
  },
  {
    type: DateRangeQuickFilters.Yesterday,
    name: () => T.YESTERDAY,
    start: () => moment().add(-1, 'day').startOf('day'),
    end: () => moment().startOf('day'),
    showInDatePicker: true
  },
  {
    type: DateRangeQuickFilters.Last7Days,
    name: () => T.LAST_7_DAYS,
    start: () => moment().add(-7, 'day').startOf('day'),
    end: () => moment().startOf('day'),
    showInDatePicker: true
  },
  {
    type: DateRangeQuickFilters.ThisMonth,
    name: () => T.THIS_MONTH,
    start: () => moment().startOf('month'),
    end: () => moment().add(1, 'day').startOf('day'),
    showInDatePicker: true
  },
  {
    type: DateRangeQuickFilters.LastMonth,
    name: () => T.LAST_MONTH,
    start: () => moment().add(-1, 'month').startOf('month'),
    end: () => moment().startOf('month'),
    showInDatePicker: true
  },
  {
    type: DateRangeQuickFilters.TwoMonthsAgo,
    name: () => {
      const month = moment().add(-2, 'month').startOf('month').month();
      return MONTHS[month];
    },
    start: () => moment().add(-2, 'month').startOf('month'),
    end: () => moment().add(-1, 'month').startOf('month'),
    showInDatePicker: true
  },
  {
    type: DateRangeQuickFilters.ThreeMonthsAgo,
    name: () => {
      const month = moment().add(-3, 'month').startOf('month').month();
      return MONTHS[month];
    },
    start: () => moment().add(-3, 'month').startOf('month'),
    end: () => moment().add(-2, 'month').startOf('month'),
    showInDatePicker: true
  },
];

/**
 * @name getDateByType
 * @param {string} type
 * @returns {{dateTo: null, dateFrom: null}|{dateTo: string, dateFrom: string}}
 */
export const getDateByType = (type) => {
  const selectedRangeType = RangeDatePickerOptions.find((range) => range.type === type);
  const dateFrom = moment(selectedRangeType.start().utc()).format(DATE_FORMAT_BACK);
  const dateTo = moment(selectedRangeType.end().utc()).format(DATE_FORMAT_BACK);
  return { dateFrom, dateTo };
};

export const equalsDate = (first, second) => {
  const secondString = moment(second?.toString()).format(DATE_FORMAT_BACK).toString();
  return Math.abs(moment(first).diff(moment(secondString), 'hour')) <= 24;
};

/**
 * @name getDateRange
 * @param arg
 * @returns {Date[]|*[]}
 */
export const getDateRange = (arg) => {
  if (Array.isArray(arg)) {
    const [start, end] = arg;
    return [new Date(start) || null, new Date(end) || null];
  }
  return [null, null];
};

/**
 * @name getDatePickerMinValue
 * @param {Object|string} date
 * @returns {moment.Moment}
 */
export const getDatePickerMinValue = (date = moment.now(), months = 6) => {
  const restrictDate = moment(date);
  return restrictDate.subtract(months * 30, 'day');
};

/**
 * @name getDatePickerMaxValue
 * @returns {moment.Moment}
 */
export const getDatePickerMaxValue = () => {
  return moment().add(1, 'day').toDate();
};
