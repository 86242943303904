import React from 'react'; 
import styled from 'styled-components';
import MaintenanceImg from '../../../assets/images/maintenance.png';

export const MaintenanceAndUserBlockedWr = styled.section`
  height: 100vh;
	width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const MaintenanceAndUserBlockedTitle = styled.p`
  font-size: 28px;
  font-weight: 600;
  margin: 40px 0 20px;
`;

export const MaintenanceAndUserBlockedDesc = styled.p`
  width: 514px;
  font-size: 14px;
  line-height: 1.36;
  text-align: center;
`;

const Image = styled.img`
  width: 400px;
`;

const Maintenance = () => {
  return (
    <MaintenanceAndUserBlockedWr>
      <Image src={MaintenanceImg} alt="warning"/>
      <MaintenanceAndUserBlockedTitle>
        Pascal Gaming is now under maintenance
      </MaintenanceAndUserBlockedTitle>
      <MaintenanceAndUserBlockedDesc>
        We apologize for any inconvenience this might cause to you and
        <b> we are doing </b>
        our utmost to restore the affected services back to normal
      </MaintenanceAndUserBlockedDesc>
    </MaintenanceAndUserBlockedWr>
  );
};

export default Maintenance;
