export const getFilteredData = (value, data) => {
  return data.filter((character) => {
    return character.label.toLowerCase().includes(value.target.value.toLowerCase());
  });
};

export const getSelectedCategoriesGames = (games, gamesCategory) => {
  return games?.filter((game) => {
    return gamesCategory.includes(game.category);
  });
};
