import React from 'react';
import { useDragLayer } from 'react-dnd';

import { Icon } from '../../icon';

const getPreviewCoords = (xy) => {
  const { x, y } = xy || {};
  const transform = `translate(${x - 10}px, ${y - 30}px)`;
  return {
    transform,
    WebkitTransform: transform
  };
};

export const CustomDragLayer = ({
  selectedCount,
  containerName,
  singleSelectedName
}) => {

  const { isDragging, clientOffset, itemType } = useDragLayer((monitor) => ({
    isDragging: monitor.isDragging(),
    clientOffset: monitor.getClientOffset(),
    itemType: monitor.getItemType()
  }));

  if (!isDragging || `ITEM_${containerName}` !== itemType) {
    return null;
  }

  return (
    <div className="drag-preview-wr">
      <div className="pg-transfer-list-item with-border" style={getPreviewCoords(clientOffset)}>
        <div className="pg-transfer-list-item-header">
          {
            selectedCount > 1 ? (
              <span>{selectedCount} Elements</span>
            ) : (
              <span>{singleSelectedName}</span>
            )
          }
          <Icon type="bc-icon-drag" />
        </div>
      </div>
    </div>
  );
};
