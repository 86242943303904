import React from 'react';
import { Search as CoreSearch } from '@geneui/components';

export const Search = ({
  labelAppearance = 'swap',
  ...props
}) => {

  return (
    <CoreSearch
      labelAppearance={labelAppearance}
      {...props}
    />
  );
};
