import React from 'react';
import styled from 'styled-components';

import { ModalFooter } from '../shared';

const FooterContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 6px 0;
`;

const CurrencyAndLanguageMobileFooter = ({
  onSave,
  onCancel,
  saveDisabled,
}) => {

  return (
    <FooterContent>
      <ModalFooter
        onSave={onSave}
        onCancel={onCancel}
        saveDisabled={saveDisabled}
      />
    </FooterContent>
  )
}

export default CurrencyAndLanguageMobileFooter;
