import React from 'react';
import { action, makeObservable, observable } from 'mobx';

import LocalStorageHelper from '../../../core/helpers/localStorageHelper';
import { LocalStorageKeys } from '../../../core/constants/localStorageKeys';

class SettingsStore {
  viewMode = LocalStorageHelper.getItem(LocalStorageKeys.viewMode);

  constructor() {
    makeObservable(this, {
      viewMode: observable,
      setViewMode: action
    });
  }

  setViewMode = (mode) => {
    this.viewMode = mode;
    LocalStorageHelper.setItem(LocalStorageKeys.viewMode, mode);
  };

}

export const settingsStore = new SettingsStore();