import React, { useState } from 'react';
import { withRouter, NavLink } from 'react-router-dom';

import { Content, Item, NavigationParent, SubContent, SubItem } from './navigation.styled';
import PermissionGuard from '../../containers/permissionGuard';
import { useMobileSize } from '../../../hooks/useMobileSize';
import useTranslation from '../../../hooks/useTranslation';
import useNavigation from '../../../hooks/useNavigation';
import { Icon, PopoverWithHover } from '../shared';
import { subRouteConfig } from './constants';

const Navigation = React.memo(() => {
  const translate = useTranslation();
  const { isMobile } = useMobileSize();
  const [childNavHover, setChildNavHover] = useState(false);
  const { base, activePath, subActive, getSearch, getSubSearch } = useNavigation();

  return (
    subRouteConfig[base] && (
      <NavigationParent isMobile={isMobile}>
        <Content isMobile={isMobile}>
          {subRouteConfig[base].map(({ text, to, key, subs, permissions, params = {} }) => {
            const parentActive = activePath === key;
            if (subs?.length) {
              return (
                <PermissionGuard requiredPermissions={permissions} checkAll={false}>
                  <PopoverWithHover
                    key={key}
                    childRenOpen={childNavHover}
                    setChildrenOpen={setChildNavHover}
                    align="start"
                    Content={(
                      <SubContent>
                        {subs.map(({ text, to: subTo, key: subKey, permissions }) => {
                          return (
                            <PermissionGuard requiredPermissions={permissions}>
                              <NavLink key={key} to={{
                                pathname: `/${base}${to}${subTo}`,
                                search: getSubSearch(subTo, params),
                              }}>
                                <SubItem active={subKey === subActive && parentActive}>
                                  {translate(text)}
                                </SubItem>
                              </NavLink>
                            </PermissionGuard>
                          );
                        })}
                      </SubContent>
                    )}
                    extendTargetWidth={false}
                    maxHeight={220}
                  >
                    <Item isMobile={isMobile} key={key} active={parentActive}>
                      {translate(text)}
                      <Icon type="bc-icon-arrow-next" />
                    </Item>
                  </PopoverWithHover>
                </PermissionGuard>
              );
            }
            return (
              <PermissionGuard requiredPermissions={permissions}>
                <NavLink
                  key={key} to={{
                  pathname: `/${base}${to}`,
                  search: getSearch(params),
                }}
                >
                  <Item isMobile={isMobile} active={parentActive}>
                    {translate(text)}
                  </Item>
                </NavLink>
              </PermissionGuard>
            );
          })}
        </Content>
      </NavigationParent>
    )
  );
});

export default withRouter(Navigation);
