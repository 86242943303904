import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { mapGameLinesToDropdownData } from '../../../core/helpers/dropdownDataConverter';
import { TranslationKeys as T } from '../../../core/constants/translationKeys';
import useTranslation from '../../../hooks/useTranslation';
import { gamesStore } from '../../../module';
import { Dropdown } from '../shared';

const GameLinesDropdown = observer(({
  label = T.GAME_LINES,
  ...restProps
}) => {

  const { gameLines: { data } } = gamesStore;

  const translate = useTranslation();

  const dropdownData = useMemo(() => {
    return mapGameLinesToDropdownData(data, translate);
  }, [data, translate]);

  return (
    <Dropdown
      data={dropdownData}
      appearance="outline"
      labelAppearance="swap"
      label={translate(label)}
      placeholder={translate(label)}
      {...restProps}
    />
  );
});

export default GameLinesDropdown;
