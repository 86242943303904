import { ApiVersion } from '../../core/enums/ApiVersion';
import { httpClient } from '../httpClient/httpClient';
import {
  getUserTournamentsRangeReportUrl,
  getUserTournamentsReportUrl,
  getUserTournamentsUrl,
  getUserTournamentUrl,
} from '../../core/constants/endpoints';

class UserTournamentsService {
  /**
   * @name getUserTournaments
   * @param {UserTournamentsParams=} params
   * @param {ApiOption=} option
   * @return {Promise<ResponseUserTournaments>}
   */
  async getUserTournaments(params, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getUserTournamentsUrl(option), { params });
  }

  /**
   * @name getUserTournamentsReports
   * @param {UserTournamentsReportParams=} params
   * @param {ApiOption=} option
   * @return {Promise<ResponseUserTournamentsReport>}
   */
  async getUserTournamentsReports(params, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getUserTournamentsReportUrl(option), { params });
  }

  /**
   * @name getUserTournamentsRangeReports
   * @param {UserTournamentsRangeReportParams=} params
   * @param {ApiOption=} option
   * @return {Promise<ResponseUserTournamentsRangeReport>}
   */
  async getUserTournamentsRangeReports(params, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getUserTournamentsRangeReportUrl(option), { params });
  }

  /**
   * @name getUserTournament
   * @param {UserTournamentParams=} params
   * @param {ApiOption=} option
   * @return {Promise<ResponseUserTournament>}
   */
  async getUserTournament(params, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getUserTournamentUrl(option), { params });
  }
}

export const userTournamentsService = new UserTournamentsService();
