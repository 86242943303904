import React from 'react';
import { TransferList as CoreTransferList } from '@geneui/components';

export const TransferList = ({ ...props }) => {
  return (
    <CoreTransferList
      {...props}
    />
  );
};
