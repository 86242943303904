export const HTTPStatus = {
  BadRequest: 400,
  Unauthorized: 401,
  PaymentRequired: 402,
  Forbidden: 403,
  NotFound: 404,
  ServerError: 500,
  NotImplemented: 501,
  BadGataway: 502,
  ServiceUnavailable: 503,
  GatawayTimeOut: 504,
  NotSupportedVersion: 505,
}
