import { TranslationKeys } from './translationKeys';

export const ViewModes = {
  small: 'view-small',
  normal: 'view-normal',
  large: 'view-big',
};

export const items = [
  { label: TranslationKeys.SMALL, value: ViewModes.small },
  { label: TranslationKeys.NORMAL, value: ViewModes.normal },
  { label: TranslationKeys.LARGE, value: ViewModes.large },
];
