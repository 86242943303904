export const ABORT_API_KEYS = {
  GetDashboardReport: 'GetDashboardReport',
  GetDashboardGamesReport: 'GetDashboardGamesReport',
  GetDashboardGameLinesReport: 'GetDashboardGameLinesReport',
  GetBetReport: 'GetBetReport',
  GetRoundReport: 'GetRoundReport',
  GetUserReport: 'GetUserReport',
  GetMarketReport: 'GetMarketReport',
  GetPlatforms: 'GetPlatforms',
  GetUserFreeBets: 'GetUserFreeBets',
  GetMaintenance: 'GetMaintenance',
  GetCurrencies: 'GetCurrencies',
  GetCurrencyChart: 'GetCurrencyChart',
  GetLanguages: 'GetLanguages',
  GetSliderTemplates: 'GetSliderTemplates',
  GetTournaments: 'GetTournaments',
  GetPromoTemplates: 'GetPromoTemplates',
  GetJackpotReports: 'GetJackpotReports',
  GetFreeBetTemplates: 'GetFreeBetTemplates',
  GetTournamentPartners: 'GetTournamentPartners',
  GetPGTournaments: 'GetPGTournaments',
  GetFinancialReports: 'GetFinancialReports',
  GetPromoPlayersReports: 'GetPromoPlayersReports',
  GetPlayerJackpotReports: 'GetPlayerJackpotReports',
  GetPGTournamentsReports: 'GetPGTournamentsReports',
  GetPartnerComparisonReports: 'GetPartnerComparisonReports',
  GetTransactionsReports: 'GetTransactionsReports',
  GetPGCashbacks: 'GetPGCashbacks',
  GetPGCashbackReport: 'GetPGCashbackReport',
  GetPGCashbackPlayerReport: 'GetPGCashbackPlayerReport',
  GetGameComparisonReport: 'GetGameComparisonReport',
  GetPGLobbies: 'GetPGLobbies',
};
