import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Row, Col } from '@geneui/components';

import Category from './category';
import Games from './games';
import SelectedGames from './selectedGames';

const SelectGames = observer(({
  gamesCategory,
  setGamesCategory,
  selectedGames,
  setSelectedGames,
  values,
}) => {
  const [filteredGameByCategory, setFilteredGameByCategory] = useState([]);

  return (
    <Row>
      <Col size={4} className="select-games-col">
        <Category
          gamesCategory={gamesCategory}
          setGamesCategory={setGamesCategory}
          setSelectedGames={setSelectedGames}
          selectedGames={selectedGames}
          filteredGameByCategory={filteredGameByCategory}
          setFilteredGameByCategory={setFilteredGameByCategory}
          values={values}
        />
      </Col>
      <Col size={4} className="select-games-col">
        <Games
          gamesCategory={gamesCategory}
          selectedGames={selectedGames}
          setSelectedGames={setSelectedGames}
          filteredGameByCategory={filteredGameByCategory}
          setFilteredGameByCategory={setFilteredGameByCategory}
        />
      </Col>
      <Col size={4} className="select-games-col">
        <SelectedGames
          selectedGames={selectedGames}
          setSelectedGames={setSelectedGames}
        />
      </Col>
    </Row>
  );
});

export default SelectGames;
