import { useState } from 'react';
import useDidUpdateEffect from './useDidUpdateEffect';
import { inputsValuePicker } from '../core/constants/inputValueTakers';

/**
 * @name useForm
 * @param callback
 * @param onRefreshPage
 * @param onToggleModal
 * @param toggleName
 * @param initialState
 * @return {{
 * onBlur: Function,
 * handleSubmit: Function,
 * setIsValid: Function,
 * handleChange: Function,
 * setValues: Function,
 * values: Object,
 * isValid: boolean,
 * setErrors: Function,
 * errors: any,
 * onFocus: Function
 * }}
 */
export const useForm = ({ callback, onRefreshPage, onToggleModal, toggleName, initialState = {} }) => {
  const copyInitialState = Object.assign({}, initialState);
  Object.keys(initialState).forEach((key) => {
    copyInitialState[key] = '';
  });

  const [values, setValues] = useState(initialState);
  const [changed, setChanged] = useState(0);
  const [errors, setErrors] = useState(copyInitialState);
  const [nameInput, setNameInput] = useState('');
  const [inputValidateData, setInputValidateData] = useState(null);
  const [isValid, setIsValid] = useState(true);

  const validateInputs = () => {
    let errors = {};
    if (nameInput) {
      if (values[nameInput] == null || values[nameInput] === '') {
        if (inputValidateData.requiredMessage) {
          errors[nameInput] = inputValidateData.requiredMessage;
        } else {
          errors[nameInput] = 'required error';
        }
      } else if (inputValidateData.hasOwnProperty('regExp')) {
        if (!inputValidateData.regExp.test(values[nameInput])) {
          if (inputValidateData.warningMessage) {
            errors[nameInput] = inputValidateData.warningMessage;
          } else {
            errors[nameInput] = 'warning error';
          }
        }
      } else if (inputValidateData.hasOwnProperty('customValidationError')) {
        errors[nameInput] = inputValidateData['customValidationError'];
      } else {
        errors[nameInput] = '';
      }
    }
    return errors;
  };

  const renderIsValid = () => {
    const errorValues = Object.values(errors).join('');
    if (!errorValues) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  };

  useDidUpdateEffect(() => {
    renderIsValid();
  }, [errors, values]);

  useDidUpdateEffect(() => {
    setErrors({
      ...errors,
      ...validateInputs(values),
    });
  }, [changed]);

  const onBlur = (validData, key) => {
    setInputValidateData(validData);
    setNameInput(key);
    setChanged(prevState => prevState + 1);
  };

  const onFocus = (key) => {
    setErrors({
      ...errors,
      [key]: '',
    });
  };

  const handleChange = (event, key, type) => {
    const data = inputsValuePicker(event, key, type);
    setValues(prevState => {
      return {
        ...prevState,
        ...data,
      };
    });
  };

  const handleSubmit = (payload) => {
    let data = values;
    if (Object.values(errors).join('')) {
      return false;
    } else {
      if (payload) {
        typeof payload === 'object' ? data = { ...data, ...payload } : data = { ...data, payload };
      }
      return callback(data).then(resp => {
        return resp ? (onToggleModal(modal => ({
          ...modal,
          [toggleName]: false,
        })), onRefreshPage()) : console.log('SERVER_ERROR');
      });
    }
  };

  return {
    handleChange,
    handleSubmit,
    values,
    errors,
    onBlur,
    onFocus,
    setValues,
    setErrors,
    isValid,
    setIsValid,
  };
};

