export const TableColumnType = {
  Text: 'TEXT',
  Date: 'DATE',
  Number: 'NUMBER',
  WithCurrency: 'WITH_CURRENCY',
  ConvertedNumber: 'CONVERTED_NUMBER',
  ConvertedNumberSameRate: 'CONVERTED_NUMBER_SAME_RATE',
  Odd: 'ODD',
  Object: 'OBJECT',
  None: 'NONE',
};
