import React from 'react';
import { Tab as CoreTab } from '@geneui/components';

export const Tab = ({ ...props }) => {
  return (
    <CoreTab
      {...props}
    />
  );
};
