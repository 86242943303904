import React from 'react';

import useTranslation from '../../../../hooks/useTranslation';

export const Translate = ({
  tKey = ''
}) => {

  const t = useTranslation();

  return tKey ? t(tKey) : '-';

};
 