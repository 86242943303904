import { PERMISSIONS } from '../../../core/constants/permissions';
import { TranslationKeys } from '../../../core/constants/translationKeys';

export const subRouteConfig = {
  'dashboard': null,
  'platform': [
    {
      text: TranslationKeys.PLATFORMS_SETTINGS,
      to: '/platforms-settings',
      key: 'platforms-settings',
      permissions: [PERMISSIONS.ViewPlatformSettings],
    },
    {
      text: TranslationKeys.PARTNERS_SETTINGS,
      to: '/partners-settings',
      key: 'partners-settings',
      permissions: [PERMISSIONS.ViewPartnerSettings],
    },
    {
      text: TranslationKeys.GAMES,
      to: '/games',
      key: 'games',
      permissions: [PERMISSIONS.ViewGames],
    },
    {
      text: TranslationKeys.GAME_TYPES,
      to: '/contest-types',
      key: 'contest-types',
      params: { isEnabled: true },
      permissions: [PERMISSIONS.ViewGameTypes],
    },
  ],
  'reports': [
    {
      text: TranslationKeys.BET_REPORTS,
      to: '/bet-reports',
      key: 'bet-reports',
      permissions: [PERMISSIONS.ViewBogBetReport, PERMISSIONS.ViewArcadeBetReport, PERMISSIONS.ViewTableBetReport, PERMISSIONS.ViewLottoBetReport, PERMISSIONS.ViewBetshopBetReport, PERMISSIONS.ViewSlotBetReport],
      subs: [
        {
          text: TranslationKeys.BOG,
          to: '/bog',
          key: 'bog',
          sharedParams: true,
          permissions: [PERMISSIONS.ViewBogBetReport],
        },
        {
          text: TranslationKeys.ARCADE,
          to: '/arcade',
          key: 'arcade',
          sharedParams: true,
          permissions: [PERMISSIONS.ViewArcadeBetReport],
        },
        {
          text: TranslationKeys.TABLE_GAMES,
          to: '/table-games',
          key: 'table-games',
          sharedParams: true,
          permissions: [PERMISSIONS.ViewTableBetReport],
        },
        {
          text: TranslationKeys.BET_ON_LOTTERY,
          to: '/lottery',
          key: 'lottery',
          sharedParams: true,
          permissions: [PERMISSIONS.ViewLottoBetReport],
        },
        {
          text: TranslationKeys.BET_SHOP,
          to: '/betshop',
          key: 'betshop',
          sharedParams: true,
          permissions: [PERMISSIONS.ViewBetshopBetReport],
        },
        {
          text: TranslationKeys.SLOT,
          to: '/slot',
          key: 'slot',
          sharedParams: true,
          permissions: [PERMISSIONS.ViewSlotBetReport],
        },
        {
          text: TranslationKeys.VIRTUAL_GAMES,
          to: '/virtual',
          key: 'virtual',
          sharedParams: true,
          permissions: [PERMISSIONS.ViewVirtualBetReport],
        },
        {
          text: TranslationKeys.CRYPTO_GAMES,
          to: '/crypto-games',
          key: 'crypto-games',
          sharedParams: true,
          permissions: [PERMISSIONS.ViewCryptoGamesBetReport],
        },
      ],
    },
    {
      text: TranslationKeys.PLAYER_REPORT,
      to: '/user-report',
      key: 'user-report',
      permissions: [ PERMISSIONS.ViewUserReport ]
    },
    {
      text: TranslationKeys.ROUND_REPORT,
      to: '/round-report',
      key: 'round-report',
      permissions: [PERMISSIONS.ViewBogRoundReport, PERMISSIONS.ViewTableRoundReport, PERMISSIONS.ViewLottoRoundReport, PERMISSIONS.ViewBetshopRoundReport],
      subs: [
        {
          text: TranslationKeys.BOG,
          to: '/bog',
          key: 'bog',
          sharedParams: true,
          permissions: [PERMISSIONS.ViewBogRoundReport],
        },
        {
          text: TranslationKeys.TABLE_GAMES,
          to: '/table-games',
          key: 'table-games',
          sharedParams: true,
          permissions: [PERMISSIONS.ViewTableRoundReport],
        },
        {
          text: TranslationKeys.BET_ON_LOTTERY,
          to: '/lottery',
          key: 'lottery',
          sharedParams: true,
          permissions: [PERMISSIONS.ViewLottoRoundReport],
        },
        {
          text: TranslationKeys.BET_SHOP,
          to: '/betshop',
          key: 'betshop',
          sharedParams: true,
          permissions: [PERMISSIONS.ViewBetshopRoundReport],
        },
        {
          text: TranslationKeys.VIRTUAL_GAMES,
          to: '/virtual',
          key: 'virtual',
          sharedParams: true,
          permissions: [PERMISSIONS.ViewVirtualBetReport],
        },
      ],
    },
    {
      text: TranslationKeys.MARKETS_REPORT,
      to: '/markets-report',
      key: 'markets-report',
      permissions: [PERMISSIONS.ViewVirtualMarketsReport],
      subs: [
        {
          text: TranslationKeys.BET_SHOP,
          to: '/betshop',
          key: 'betshop',
          sharedParams: true,
          permissions: [PERMISSIONS.ViewBetshopMarketsReport],
        },
        {
          text: TranslationKeys.VIRTUAL_GAMES,
          to: '/virtual',
          key: 'virtual',
          sharedParams: true,
          permissions: [PERMISSIONS.ViewVirtualMarketsReport],
        }
      ]
    },
    {
      text: TranslationKeys.FINANCIAL_REPORT,
      to: '/financial-report',
      key: 'financial-report',
      permissions: [PERMISSIONS.ViewFinancialReport],
    },
    {
      text: TranslationKeys.TRANSACTION_REPORT,
      to: '/transactions-report',
      key: 'transactions-report',
      permissions: [PERMISSIONS.ViewTransactionReport],
    },
    {
      text: TranslationKeys.FREE_BET_REPORT,
      to: '/free-bet',
      key: 'free-bet',
      permissions: [PERMISSIONS.ViewFreebetReport],
    },
    {
      text: TranslationKeys.PLAYER_PROMO_REPORT,
      to: '/player-promo-report',
      key: 'player-promo-report',
      permissions: [PERMISSIONS.ViewPlayerPromoReport],
    },
    {
      text: TranslationKeys.PLAYER_JACKPOT_REPORT,
      to: '/player-jackpot-report',
      key: 'player-jackpot-report',
      permissions: [PERMISSIONS.ViewFreebetReport],
    },
    {
      text: TranslationKeys.TOURNAMENT_REPORT,
      to: '/tournament-report',
      key: 'tournament-report',
      // permissions: [PERMISSIONS.ViewTournamentReport],
    },
    {
      text: TranslationKeys.PARTNER_COMPARISON_REPORT,
      to: '/comparison-report',
      key: 'comparison-report',
      permissions: [PERMISSIONS.ViewComparisonReport],
    },
    {
      text: TranslationKeys.CASHBACK_REPORT,
      to: '/cashback-report',
      key: 'cashback-report',
      permissions: [PERMISSIONS.ViewCashbackReport],
    },
    {
      text: TranslationKeys.CASHBACK_PLAYER_REPORT,
      to: '/cashback-player-report',
      key: 'cashback-player-report',
      permissions: [PERMISSIONS.ViewCashbackPlayerReport],
    },
    {
      text: TranslationKeys.CURRENCY_REPORT,
      to: '/currency-report',
      key: 'currency-report',
      permissions: [PERMISSIONS.ViewCurrencyReportList],
    },
    {
      text: TranslationKeys.GAME_COMPARISON_REPORT,
      to: '/game-comparison-report',
      key: 'game-comparison-report',
      permissions: [PERMISSIONS.ViewGameComparisonReportList],
    },
  ],
  'admin': [
    {
      text: TranslationKeys.USERS_AND_PERMISSIONS,
      to: '/users-and-permissions',
      key: 'users-and-permissions',
      permissions: [PERMISSIONS.ViewUserManagement],
    },
    {
      text: TranslationKeys.UNDER_MAINTENANCE,
      to: '/under-maintenance',
      key: 'under-maintenance',
      permissions: [PERMISSIONS.ViewUnderMaintenance],
    },
    // {
    //   text: TranslationKeys.STATIC_CONTENTS,
    //   to: '/static-contents',
    //   key: 'static-contents',
    //   permissions: [PERMISSIONS.ViewStaticContent],
    // },
    {
      text: TranslationKeys.MANAGE_LANGUAGES,
      to: '/manage-languages',
      key: 'manage-languages',
      permissions: [PERMISSIONS.ViewLanguages, PERMISSIONS.ViewApplications],
    },
    {
      text: TranslationKeys.CURRENCY_LIST,
      to: '/currencies',
      key: 'currencies',
      permissions: [PERMISSIONS.ViewDashboard, PERMISSIONS.ViewDashboard], //TODO add permissions ViewCurrencyList
    }
  ],
  'bonus': [
    {
      text: TranslationKeys.BONUS_ISLAND,
      to: '/game-master',
      key: 'game-master',
      permissions: [PERMISSIONS.ViewBonusIsland],
    },
    {
      text: TranslationKeys.BONUS_ISLAND_LIST,
      to: '/master-list',
      key: 'master-list',
      permissions: [PERMISSIONS.ViewBonusIslandList],
    },
    {
      text: TranslationKeys.FREE_BET,
      to: '/free-bet',
      key: 'free-bet',
      permissions: [PERMISSIONS.ViewFreebet],
    },
    {
      text: TranslationKeys.PROMO,
      to: '/promo',
      key: 'promo',
      permissions: [PERMISSIONS.ViewPromoList],
    },
    {
      text: TranslationKeys.JACKPOT,
      to: '/jackpot',
      key: 'jackpot',
      permissions: [PERMISSIONS.ViewJackpot],
    },
    {
      text: TranslationKeys.CASHBACK,
      to: '/cashback',
      key: 'cashback',
      permissions: [PERMISSIONS.ViewCashbackList],
    },
  ],
  'translation-tool': [
    {
      text: TranslationKeys.SIMPLE_CONTENT,
      to: '/simple-content',
      key: 'simple-content',
      permissions: [PERMISSIONS.ViewTranslations],
    },
    {
      text: TranslationKeys.RICH_CONTENT,
      to: '/rich-content',
      key: 'rich-content',
      permissions: [PERMISSIONS.ViewTranslations],
    },
  ],
  'slider-manager': null,
  'pg-tournament': null,
};
