import React from 'react';
import styled from 'styled-components';

import useTranslation from '../../../hooks/useTranslation';

const Message = styled.p`
  margin-left: 34px;
`;

export const ToasterMessage = ({ message }) => {
  const translate = useTranslation();
  return <Message>{translate(message)}</Message>;
};
