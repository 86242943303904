import React from 'react';

export const PGTransferListSectionHeader = ({
  title,
  totalCount,
  selectedCount
}) => {
  return (
    <div className="pg-transfer-list-section-header">
      <h3 className="section-header-title">{title}</h3>
      <span className="section-header-counts">{selectedCount}/{totalCount}</span>
    </div>
  );
};
