import { Toaster } from '@geneui/components';
import React from 'react';

import { ToasterMessage } from './components/toasterMessage';
import { ToasterTitle } from './components/toasterTitle';
import { TranslationKeys } from '../../core/constants/translationKeys';

class NotifyService {
  /**
   * @name success
   * @param {string} title
   * @param {string} message
   * @param {number=} duration
   * @param {string} iconColor
   * @param {string} icon
   */
  success({
    title = TranslationKeys.SUCCESS,
    message,
    duration = 4_000,
    iconColor = '#2bc784',
    icon = 'bc-icon-success',
  }) {
    Toaster.success({
      title: <ToasterTitle title={title} icon={icon} iconColor={iconColor} />,
      message: <ToasterMessage message={message} />,
      duration,
    });
  }

  /**
   * @name error
   * @param {string} title
   * @param {string} message
   * @param {number=} duration
   * @param {string} iconColor
   * @param {string} icon
   */
  error({
    title = TranslationKeys.ERROR,
    message = TranslationKeys.SOMETHING_WENT_WRONG,
    duration = 4_000,
    iconColor = '#e64e48',
    icon = 'bc-icon-error-fill',
  }) {
    Toaster.error({
      title: <ToasterTitle title={title} icon={icon} iconColor={iconColor} />,
      message: <ToasterMessage message={message} />,
      duration,
    });
  }
}

export const notifyService = new NotifyService();
