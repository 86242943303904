import { httpClient } from '../httpClient/httpClient';
import { ApiVersion } from '../../core/enums/ApiVersion';
import {
  getFreeBetTemplatesUrl,
  addFreeBetTemplateUrl,
  editFreeBetTemplateUrl,
  getFreeBetTemplateDetailsUrl,
  cancelFreeBetTemplateUrl,
  getFreeBetTemplateReportUrl,
} from '../../core/constants/endpoints';
import { abortFetch } from '../../core/helpers/abortController';
import { ABORT_API_KEYS } from '../../core/constants/abortApiKeys';

class FreeBetTemplatesService {
  /**
   * @name getFreeBetTemplates
   * @param {GetFreeBetTemplatesParams=} params
   * @param {ApiOption=} option
   * @returns {Promise<GetFreeBetTemplatesResponse>}
   */
  async getFreeBetTemplates(params, option = { version: ApiVersion.V1 }) {
    const signal = abortFetch(ABORT_API_KEYS.GetFreeBetTemplates);
    return await httpClient.get(getFreeBetTemplatesUrl(option), { params, signal });
  }

  /**
   * @name addFreeBetTemplate
   * @param {AddEditFreeBetTemplatesModel} data
   * @param {ApiOption=} option
   * @returns {Promise<void>}
   */
  async addFreeBetTemplate(data, option = { version: ApiVersion.V1 }) {
    return await httpClient.post(addFreeBetTemplateUrl(option), data);
  }

  /**
   * @name editFreeBetTemplate
   * @param {AddEditFreeBetTemplatesModel} data
   * @param {ApiOption=} option
   * @returns {Promise<void>}
   */
  async editFreeBetTemplate(data, option = { version: ApiVersion.V1 }) {
    return await httpClient.put(editFreeBetTemplateUrl(option), data);
  }

  /**
   * @name getFreeBetTemplateDetails
   * @param {number} id
   * @param {ApiOption=} option
   * @returns {Promise<GetFreeBetTemplateDetailsResponse>}
   */
  async getFreeBetTemplateDetails(id, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getFreeBetTemplateDetailsUrl(id, option));
  }

  /**
   * @name cancelFreeBetTemplate
   * @param {number} id
   * @param {ApiOption=} option
   * @returns {Promise<GetFreeBetTemplateDetailsResponse>}
   */
  async cancelFreeBetTemplate(id, option = { version: ApiVersion.V1 }) {
    return await httpClient.put(cancelFreeBetTemplateUrl(id, option));
  }

  /**
   * @name getFreeBetTemplateReport
   * @param {number} id
   * @param {ApiOption=} option
   * @returns {Promise<GetFreeBetTemplateReportResponse>}
   */
  async getFreeBetTemplateReport(id, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getFreeBetTemplateReportUrl(id, option));
  }

}

export const freeBetTemplatesService = new FreeBetTemplatesService();
