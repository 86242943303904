import { action, flow, makeObservable, observable } from 'mobx';
import { HttpResponse } from '../../core/classes/HttpResponse';
import { freeBetTemplatesService } from './freeBetTemplatesService';

class FreeBetTemplatesStore {
  /** @type {HttpResponse<GetFreeBetTemplatesResponse>}*/
  freeBetTemplates = new HttpResponse({ items: [], count: 0 });
  
  freeBetTemplatesByGameIdMap = {};

  /** @type {HttpResponse<freeBetTemplatesByPromoDate>}*/
  freeBetTemplatesByPromoDate = new HttpResponse({ items: [], count: 0 });

  /** @type {HttpResponse<GetFreeBetTemplateDetailsResponse>}*/
  freeBetTemplateDetails = new HttpResponse({});

  /** @type {HttpResponse<GetFreeBetTemplateReportResponse>}*/
  freeBetTemplateReport = new HttpResponse({ currencyConfigs: [], name: '' });

  constructor() {
    makeObservable(this, {
      freeBetTemplates: observable,
      freeBetTemplateDetails: observable,
      freeBetTemplateReport: observable,
      freeBetTemplatesByGameIdMap: observable,
      freeBetTemplatesByPromoDate: observable,
      setFreeBetTemplates: action.bound,
      setFreeBetTemplateDetails: action.bound,
      setFreeBetTemplateReport: action.bound,
      setFreeBetTemplatesByGameIdMap: action.bound,
      setFreeBetTemplatesByPromoDate: action.bound,
      getFreeBetTemplates: flow,
      getFreeBetTemplatesByPromoDate: flow,
      getFreeBetTemplateReport: flow,
      getFreeBetTemplatesByGameIdMap: flow,
    });
  }

  /**
   * @name setFreeBetTemplates
   * @param {HttpResponse<GetFreeBetTemplatesResponse>} data
   * @returns {void}
   */
  setFreeBetTemplates = (data) => {
    this.freeBetTemplates = data;
  };

  /**
   * @name setFreeBetTemplatesByPromoDate
   * @param {HttpResponse<GetFreeBetTemplatesResponse>} data
   * @returns {void}
   */
  setFreeBetTemplatesByPromoDate = (data) => {
    this.freeBetTemplatesByPromoDate = data;
  };

  /**
   * @name setFreeBetTemplateDetails
   * @param {HttpResponse<GetFreeBetTemplateDetailsResponse>} freeBet
   * @returns {void}
   */
  setFreeBetTemplateDetails = (data) => {
    this.freeBetTemplateDetails = data;
  };

  /**
   * @name setFreeBetTemplateReport
   * @param {HttpResponse<GetFreeBetTemplateReportResponse>} data
   * @returns {void}
   */
  setFreeBetTemplateReport = (data) => {
    this.freeBetTemplateReport = data;
  };

  setFreeBetTemplatesByGameIdMap = (data) => {
    this.freeBetTemplatesByGameIdMap = data;
  };

  /**
   * @name resetFreeBetDetails
   * @returns {void}
   */
  resetFreeBetDetails = () => {
    this.setFreeBetTemplateDetails(this.freeBetTemplateDetails.fetched({}));
  };

  /**
   * @name getFreeBetTemplates
   * @param {GetFreeBetTemplatesParams=} params
   * @returns {Generator<Promise<GetFreeBetTemplatesResponse>, void, *>}
   */
  getFreeBetTemplates = function* (params) {
    this.setFreeBetTemplates(this.freeBetTemplates.fetching());
    const data = yield freeBetTemplatesService.getFreeBetTemplates(params);
    this.setFreeBetTemplates(this.freeBetTemplates.fetched(data));
  };

  /**
   * @name getFreeBetTemplatesByPromoDate
   * @param {GetFreeBetTemplatesParams=} params
   * @returns {Generator<Promise<GetFreeBetTemplatesResponse>, void, *>}
   */
  getFreeBetTemplatesByPromoDate = function* (params) {
    this.setFreeBetTemplatesByPromoDate(this.freeBetTemplatesByPromoDate.fetching());
    const data = yield freeBetTemplatesService.getFreeBetTemplates(params);
    this.setFreeBetTemplatesByPromoDate(this.freeBetTemplatesByPromoDate.fetched(data));
  };

  /**
   * @name getFreeBetTemplatesByGameIdMap
   * @param {GetFreeBetTemplatesParams=} params
   * @returns {Generator<Promise<Record<number, GetFreeBetTemplatesResponse>>, void, *>}
   */
  getFreeBetTemplatesByGameIdMap = function* (params) {
    const data = yield freeBetTemplatesService.getFreeBetTemplates(params);
    const { gameId } = params;
    this.setFreeBetTemplatesByGameIdMap({
      ...this.freeBetTemplatesByGameIdMap,
      [gameId]: data.items || []
    });
  };

  /**
   * @name getFreeBetTemplateDetails
   * @param {number} id
   * @returns {Promise<GetFreeBetTemplateDetailsResponse>}
   */
  getFreeBetTemplateDetails = async function (id) {
    this.setFreeBetTemplateDetails(this.freeBetTemplateDetails.fetching());
    const data = await freeBetTemplatesService.getFreeBetTemplateDetails(id);
    this.setFreeBetTemplateDetails(this.freeBetTemplateDetails.fetched(data));
    return data;
  };

  /**
   * @name getFreeBetTemplateReport
   * @param {number} id
   * @returns {Generator<Promise<GetFreeBetTemplateReportResponse>, void, *>}
   */
  getFreeBetTemplateReport = function* (id) {
    this.setFreeBetTemplateReport(this.freeBetTemplateReport.fetching());
    const data = yield freeBetTemplatesService.getFreeBetTemplateReport(id);
    this.setFreeBetTemplateReport(this.freeBetTemplateReport.fetched(data));
  };

  /**
   * @name addFreeBetTemplate
   * @param {AddEditFreeBetTemplatesModel} data
   * @returns {Promise<void>}
   */
  addFreeBetTemplate = (data) => {
    return freeBetTemplatesService.addFreeBetTemplate(data);
  };

  /**
   * @name editFreeBetTemplate
   * @param {AddEditFreeBetTemplatesModel} data
   * @returns {Promise<void>}
   */
  editFreeBetTemplate = (data) => {
    return freeBetTemplatesService.editFreeBetTemplate(data);
  };

  /**
   * @name cancelFreeBetTemplate
   * @param {number} id
   * @returns {Promise<void>}
   */
  cancelFreeBetTemplate = (id) => {
    return freeBetTemplatesService.cancelFreeBetTemplate(id);
  };

}

export const freeBetTemplatesStore = new FreeBetTemplatesStore();
