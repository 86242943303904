import { GameCategoryNames } from '../enums/GameCategory';

export const currenciesDataConverter = (data) =>
  data.map((item) => ({
    id: item.currencyCode,
    label: item.currencyCode,
    value: item.currencyCode,
  }));
/**
 * @desc array of objects with label and value(core requires
 * @param data - array we are going convert for dropdown
 * @returns {Array<{id, label, value}>}
 */
export const dataToDropDownConverter = (data, translateFn) => {
  const translate = translateFn || (label => label);
  return data.map(({ id, name }) => ({
    id,
    label: translate(name || id),
    value: id.toString(),
  })
)};

/**
 * @name enumToDropDown
 * @param {object} dataEnum
 * @param {Function} translateFn
 * @returns {Array<{id, label, value}>}
 */
export const enumToDropDown = (dataEnum, translateFn) => {
  const translate = translateFn || (label => label); 
  return Object.keys(dataEnum).map((item) => ({
    id: item,
    label: translate(dataEnum[item]),
    value: item,
  }));
};

export const mapLanguagesToDropdownData = (languages) =>
  languages.map((lan) => ({
    label: lan.name,
    value: lan.uid,
  }));

export const gameIdDropdownConverter = (games) =>
  games.map((game) => ({
    label: game.name, // later to be changed into freeBetId
    value: game.id,
  }));

export const platformsDropdownConverter = (platforms) =>
  platforms.map(({ name, id }) => ({
    label: name,
    value: id,
  }));

export const partnerIdDropdownConverter = (partners) =>
  partners.map(partner => ({
    label: partner.name,
    value: partner.id,
    id: partner.id,
  }));

export const gameIdCheckboxConverter = (games) =>
  games.map((game) => ({
    label: game.name,
    value: game.id,
    category: game.category,
  }));

export const mapGameLinesToDropdownData = (gameLines, translate) => {
  return gameLines.map((id) => ({
    id,
    value: `${id}`,
    label: translate(GameCategoryNames[id])
  }));
};

export const mapGameLinesToSectionListModel = (gameLines, translate) => {
  return gameLines.map((id) => ({
    value: id,
    label: translate(GameCategoryNames[id])
  }));
};

export const platformsCheckboxConverter = (platforms) => {
  return platforms.map(platform => ({
    label: platform.name,
    value: platform.id,
    disabled: platform.disabled,
  }));
};
