import { TranslationKeys as T } from '../constants/translationKeys';

export const DashboardViewModes = {
  Widget: 'widget',
  List: 'list'
};

export const DashboardViewModeNames = {
  [DashboardViewModes.Widget]: T.WIDGET,
  [DashboardViewModes.List]: T.LIST,
};
