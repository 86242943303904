import React, { useCallback, useRef, useState } from 'react';
import { Badge } from '@geneui/components';

import { Button, Popover } from '../../../shared';
import Notifications from '../index';
import useOutSideClick from '../../../../../hooks/useOutSideClick';
import { MAX_BADGE_COUNT } from '../../../../../core/constants/util';

const NotificationsPopover = ({ unReadNotificationsCount }) => {

  const [ open, setIsOpen ] = useState(false);
  const ref = useRef();

  const handleOpen = useCallback(() => {
    setIsOpen(prev => !prev);
  }, []);

  useOutSideClick(ref, () => {
    setIsOpen(false);
  }, 'ignore-notif');

  const notificationComponent = (
    <div>
      <Button
        size="small"
        color="default"
        cornerRadius="round"
        flexibility="default"
        appearance="minimal"
        icon="bc-icon-bell-outline"
        className="ignore-notif"
        onClick={handleOpen}
      />
    </div>
  );

  return (
    <Popover
      align="end"
      extendTargetWidth={false}
      contentRef={ref}
      isOpen={open}
      Content={(
        <Notifications/>
      )}
    >
      <Badge size="default" maxCount={MAX_BADGE_COUNT} count={unReadNotificationsCount || null}>
        {notificationComponent}
      </Badge>
    </Popover>
  );
};

export default NotificationsPopover;
