import React from 'react';
import { Tabs as CoreTabs } from '@geneui/components';

export const Tabs = ({ ...props }) => {
  return (
    <CoreTabs
      {...props}
    />
  );
};
