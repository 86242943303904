import React, { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Paper } from '@geneui/components';
import styled, { css } from 'styled-components';

import NotificationItem from './notificationItem';
import { DEFAULT_TAKE } from '../../../../core/constants/util';
import { notificationStore } from '../../../../module';
import useTranslation from '../../../../hooks/useTranslation';
import { TranslationKeys } from '../../../../core/constants/translationKeys';
import { useInfiniteScroll } from '../../../../hooks/useInfiniteScroll';
import { useMobileSize } from '../../../../hooks/useMobileSize';
import PermissionGuard from '../../../containers/permissionGuard';
import { PERMISSIONS } from '../../../../core/constants/permissions';
import { Icon, Empty } from '../../shared';

const NotificationHolder = styled.div`
  width: 360px;
  height: auto;
  padding: 15px 1px 10px 0;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #e6e6e6;
  background-color: #ffffff;
  overflow: hidden;
  z-index: 400;
  ${props => props.isMobile && css`
    width: 100%;
    padding: 0;
    border-radius: 0;
  `}
`;

const NotificationHeader = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  align-items: center;
  border-bottom: 1px solid #E6E6E6;
  font-size: 14px;
  font-weight: bold;
  color: #3c4043;
`;

const MarkAll = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #1472e4;
  font-size: 13px;
  margin-right: 14px;
  cursor: pointer;
`;

const NotificationsScrollWrapper = styled.div`
  width: 376px;
  height: auto;
  min-height: 100px;
  max-height: 450px;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-right: 17px;
  box-sizing: content-box;
  ${props => props.isMobile && css`
    width: 100%;
  `}
`;

const Notifications = observer(() => {

  const translate = useTranslation();
  const ref = useRef(null);
  const { onInfiniteScroll } = useInfiniteScroll();
  const { isMobile } = useMobileSize();

  const {
    notifications: {
      data: {
        items: notifications,
        count,
      },
      isLoading,
    },
  } = notificationStore;

  const handleScroll = (event) => {
    onInfiniteScroll(event, () => {
      notificationStore.getNotifications({
        concat: true,
        skip: notifications.length,
        take: DEFAULT_TAKE,
      });
    }, [!isLoading, notifications.length < count]);
  };

  const handleMarkAll = () => {
    notificationStore.readAllNotifications()
      .then(() => {
        notificationStore.getNotifications({
          skip: 0,
          take: notifications.length,
        });
      });
  };

  return (
    <NotificationHolder isMobile={isMobile}  ref={ref}>
      <NotificationHeader justifyContent="center">
        <p>{translate(TranslationKeys.NOTIFICATIONS)}</p>
      </NotificationHeader>
      <NotificationHeader
        justifyContent="flex-end"
      >
        <PermissionGuard requiredPermissions={[PERMISSIONS.UpdateAllNotifications]}>
          <MarkAll onClick={handleMarkAll}>
            <Icon type="bc-icon-check" />
            {translate(TranslationKeys.MARK_ALL_AS_READ)}
          </MarkAll>
        </PermissionGuard>
      </NotificationHeader>
      <NotificationsScrollWrapper onScroll={handleScroll} isMobile={isMobile}>
        {
          notifications.length ? (
            notifications.map((notification, index) => {
              return (
                <NotificationItem
                  notification={notification}
                  showDivider={index !== notifications.length - 1}
                  currentCount={notifications.length}
                  key={notification.id}
                />
              );
            })
          ) : (
            <Paper justifyContent="center" padding={10}>
              <Empty />
            </Paper>
          )
        }
      </NotificationsScrollWrapper>
    </NotificationHolder>
  );
});

export default Notifications;
