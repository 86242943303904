import { action, flow, makeObservable, observable } from 'mobx';

import { HttpResponse } from '../../core/classes/HttpResponse';
import { permissionsService } from './permissionsService';

class PermissionStore {

  /** @type {HttpResponse<PermissionsResponse>}*/
  permissions = new HttpResponse([]);

  /** @type {HttpResponse<PermissionsResponse>}*/
  allPermissions = new HttpResponse([]);

  /** @type {HttpResponse<PermissionForEdit>}*/
  permissionForEdit = new HttpResponse([]);

  constructor() {
    makeObservable(this, {
      permissions: observable,
      allPermissions: observable,
      permissionForEdit: observable,
      setPermissions: action.bound,
      setAllPermissions: action.bound,
      setPermissionForEdit: action.bound,
      getPermissionsUserId: flow,
      getPermissionsByUserIdForEdit: flow,
      getAllPermissions: flow,
    });
  }

  /**
   * @name setPermissions
   * @params {HttpResponse<PermissionsResponse>} permissions
   * @returns {void}
   */
  setPermissions = (permissions) => {
    this.permissions = permissions;
  };

  /**
   * @name setAllPermissions
   * @params {HttpResponse<PermissionsResponse>} permissions
   * @returns {void}
   */
  setAllPermissions = (data) => {
    this.allPermissions = data;
  };

  /**
   * @name setPermissionForEdit
   * @params {HttpResponse<PermissionForEdit>} permissionForEdit
   * @returns {void}
   */
  setPermissionForEdit = (permissionForEdit) => {
    this.permissionForEdit = permissionForEdit;
  };

  /**
   * @name getPermissionsUserId
   * @param {number} userId
   * @returns {Generator<Promise<void>, void, *>}
   */
  getPermissionsUserId = function* (userId) {
    this.setPermissions(this.permissions.fetching());
    const data = yield permissionsService.getPermissionsUserId(userId);
    this.setPermissions(this.permissions.fetched(data));
  };

  /**
   * @name getPermissionsByUserIdForEdit
   * @param {number} userId
   * @returns {Generator<Promise<PermissionForEdit>, void, *>}
   */
  getPermissionsByUserIdForEdit = function* (userId) {
    this.setPermissionForEdit(this.permissionForEdit.fetching());
    const data = yield permissionsService.getPermissionsByUserIdForEdit(userId);
    this.setPermissionForEdit(this.permissionForEdit.fetched(data));
  };

  /**
   * @name getAllPermissions
   * @returns {Generator<Promise<PermissionsResponse>, void, *>}
   */
  getAllPermissions = function* () {
    this.setAllPermissions(this.allPermissions.fetching());
    const data = yield permissionsService.getAllPermissions();
    this.setAllPermissions(this.allPermissions.fetched(data));
  };
}

export const permissionStore = new PermissionStore();
