import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { routePathConstants } from '../core/constants/routePathConstants';
import { combineQuery, parseQuery } from '../core/helpers/queryHelper';
import { useSharedParams } from './useSharedParams';
import useQuery from './useQuery';

const useNavigation = () => {

  const params = useParams();
  const { search } = useSharedParams();
  const { queryObject } = useQuery();
  const [base, activePath, subActive] = params[0].split('/');

  const isCategoryPath = useCallback((path) => {
    let value = path;
    const hasSlash = path?.includes('/');
    if (hasSlash) {
      value = path.split('/')[1];
    }
    return (
      value === routePathConstants.bog
      || value === routePathConstants.arcade
      || value === routePathConstants.tableGames
      || value === routePathConstants.lottery
      || value === routePathConstants.betshop
      || value === routePathConstants.slot
      || value === routePathConstants.virtual
      || value === routePathConstants.crypto
    );
  }, []);

  const getSubSearch = useCallback((to, params) => {
    const isSame = to === subActive;
    return (
      (base === routePathConstants.reports && isCategoryPath(to) && isCategoryPath(subActive) && isSame)
        ? combineQuery({ ...queryObject, skip: 0, ...params }) : search
    );
  }, [base, search, isCategoryPath, queryObject, subActive]);

  const getSearch = useCallback((params) => {
    return combineQuery({ ...parseQuery(search), ...params });
  }, [search]);

  return {
    base,
    subActive,
    activePath,
    getSearch,
    getSubSearch
  };
};

export default useNavigation;
