import React from 'react';
import styled from 'styled-components';

import { modalStore } from '../../../module/popUpModule';

const FilterContent = styled.div`
  height: 400px;
  overflow-y: auto;
  width: 100%;
  overflow-x: hidden;
  padding: 20px 10px 60px 10px;

  & .grid-holder .grid-items-group {
    margin: 0 !important;
  }
`;

const MobileFilter = () => {

  const { filtersChildren } = modalStore;

  return (
    <FilterContent>
      {
        filtersChildren
      }
    </FilterContent>
  );
};

export default MobileFilter;
