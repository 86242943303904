import React, { useMemo } from 'react';

import { getGameCategoryForQuery } from '../../../../core/helpers/getGameCategory';
import { combineQuery } from '../../../../core/helpers/queryHelper';
import useTranslation from '../../../../hooks/useTranslation';
import { Hyperlink } from '..';

export const QuickActionColRenderer = ({
  value,
  rowInfo,
  reportType,
  queryKey,
  queryObject,
  games
}) => {
  const { gameId } = rowInfo.data;
  const translate = useTranslation();

  const hyperLink = useMemo(() => {
    return `reports/${reportType}/${getGameCategoryForQuery(gameId, games)}/${
      combineQuery({
        ...queryObject,
        gameId,
        [queryKey]: value
      })
    }`;
  }, []);

  return (
    <Hyperlink
      to={hyperLink}
      target="_blank"
      title={translate(value)}
    >
      {value}
    </Hyperlink>
  );
};
