import React from 'react';
import { Uploader } from '@geneui/components';

import './style.css';

export const ExcelUploader = ({ ...props }) => {
  return (
    <Uploader
      maxFileCount={1}
      allTypesAccepted={false}
      showPreviewButton={false}
      className="excel-uploader"
      typesList={['xls', 'xlsx']}
      {...props}
    />
  );
};
