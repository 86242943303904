import React, { useLayoutEffect } from 'react';

const Login = () => {

  useLayoutEffect(() => {
  }, []);

  return ( null );
};

export default Login;
