import { action, computed, flow, makeObservable, observable } from 'mobx';

import { HttpResponse } from '../../core/classes/HttpResponse';
import { platformService } from './platformService';

class PlatformStore {
  /** @type {HttpResponse<PlatformResponse>}*/
  platforms = new HttpResponse({ items: [], count: 0 });

  /** @type {HttpResponse<PartnersPlatformResponse>}*/
  platformsPartners = new HttpResponse({ items: [], count: 0 });

  /** @type {HttpResponse<PlatformGamesResponse>}*/
  platformsGames = new HttpResponse([]);

  /** @type {HttpResponse<PlatformInfoResponse>}*/
  platformInfo = new HttpResponse({ games: [] });

  /** @type {HttpResponse<PartnersPlatformResponse>}*/
  gamePlatformsPartners = new HttpResponse({ items: [], count: 0 });

  /** @type {HttpResponse<PartnersPlatformResponse>}*/
  gameTypePlatformsPartners = new HttpResponse({ items: [], count: 0 });

  constructor() {
    makeObservable(this, {
      platforms: observable,
      platformsPartners: observable,
      platformsGames: observable,
      platformInfo: observable,
      gamePlatformsPartners: observable,
      gameTypePlatformsPartners: observable,
      setPlatforms: action.bound,
      plaftormPartnersMap: computed,
      setPlatformsPartners: action.bound,
      setPlatformsGames: action.bound,
      setPlatformInfo: action.bound,
      setGamePlatformsPartners: action.bound,
      setGameTypePlatformsPartners: action.bound,
      getPlatforms: flow,
      getPlatformsPartners: flow,
      getPlatformsGames: flow,
      getGamePlatformsPartners: flow,
      getPlatformInfo: flow,
      getGameTypePlatformsPartners: flow
    });
  }
  
  /**
   * @name plaftormPartnersMap
   * @return {Object<number, Platform>}
   */
  get plaftormPartnersMap() {
    return this.platformsPartners.data.items.reduce((map, platform) => {
      map[platform.id] = platform;
      return map;
    }, {});
  };

  /**
   * @name setPlatforms
   * @param {HttpResponse<PlatformResponse>} platforms
   * @returns {void}
   */
  setPlatforms = (platforms) => {
    this.platforms = platforms;
  };

  /**
   * @name setPlatformsPartners
   * @param {HttpResponse<PartnersPlatformResponse>} platformsPartners
   * @return {void}
   */
  setPlatformsPartners = (platformsPartners) => {
    this.platformsPartners = platformsPartners;
  };

  /**
   * @name setPlatformsGames
   * @param {HttpResponse<PlatformGamesResponse>} platformsGames
   * @return {void}
   */
  setPlatformsGames = (platformsGames) => {
    this.platformsGames = platformsGames;
  };

  /**
   * @name setPlatformInfo
   * @param {HttpResponse<PlatformInfoResponse>} data
   * @return {void}
   */
   setPlatformInfo = (data) => {
    this.platformInfo = data;
  };

  /**
   * @name setGamePlatformsPartners
   * @param {HttpResponse<PartnersPlatformResponse>} gamePlatformPartners
   * @return {void}
   */
  setGamePlatformsPartners = (gamePlatformPartners) => {
    this.gamePlatformsPartners = gamePlatformPartners;
  };

  /**
   * @name setGameTypePlatformsPartners
   * @param {HttpResponse<PartnersPlatformResponse>} gameTypePlatformsPartners
   * @return {void}
   */
  setGameTypePlatformsPartners = (gameTypePlatformsPartners) => {
    this.gameTypePlatformsPartners = gameTypePlatformsPartners;
  };

  /**
   * @name getPlatforms
   * @param {PlatformParams=} params
   * @return {Generator<Promise<PlatformResponse>, void, *>}
   */
  getPlatforms = function* (params) {
    this.setPlatforms(this.platforms.fetching());
    const platforms = yield platformService.getPlatforms(params);
    this.setPlatforms(this.platforms.fetched(platforms));
  };

  /**
   * @name getPlatformsPartners
   * @param {PartnersPlatformsParams=} params
   * @return {Generator<Promise<PartnersPlatformResponse>, void, *>}
   */
  getPlatformsPartners = function* (params) {
    this.setPlatformsPartners(this.platformsPartners.fetching());
    const platformsPartners = yield platformService.getPlatformsPartners(params);
    this.setPlatformsPartners(this.platformsPartners.fetched(platformsPartners));
  };

  /**
   * @name getPlatformsGames
   * @param {number} id
   * @return {Generator<Promise<PlatformGamesResponse>, void, *>}
   */
  getPlatformsGames = function* (id) {
    this.setPlatformsGames(this.platformsGames.fetching());
    const platformGames = yield platformService.getPlatformGames(id);
    this.setPlatformsGames(this.platformsGames.fetched(platformGames));
  };

  /**
   * @name getGamePlatformsPartners
   * @param {PartnersPlatformsParams=} params
   * @return {Generator<Promise<PartnersPlatformResponse>, void, *>}
   */
  getGamePlatformsPartners = function* (params) {
    this.setGamePlatformsPartners(this.gamePlatformsPartners.fetching());
    const platformsPartners = yield platformService.getPlatformsPartners(params);
    this.setGamePlatformsPartners(this.gamePlatformsPartners.fetched(platformsPartners));
  };

  /**
   * @name getGameTypePlatformsPartners
   * @param {PartnersPlatformsParams=} params
   * @return {Generator<Promise<PartnersPlatformResponse>, void, *>}
   */
  getGameTypePlatformsPartners = function* (params) {
    this.setGameTypePlatformsPartners(this.gameTypePlatformsPartners.fetching());
    const platformsPartners = yield platformService.getPlatformsPartners(params);
    this.setGameTypePlatformsPartners(this.gameTypePlatformsPartners.fetched(platformsPartners));
  };

  /**
   * @name getPlatformInfo
   * @param {number} id
   * @return {Generator<Promise<PartnersPlatformResponse>, void, *>}
   */
  getPlatformInfo = function* (id) {
    this.setPlatformInfo(this.platformInfo.fetching());
    const data = yield platformService.getPlatformInfo(id);
    this.setPlatformInfo(this.platformInfo.fetched(data));
  };

  /**
   * @name addPlatform
   * @param {PlatformBody} data
   * @return {Promise<Platform>}
   */
  addPlatform = (data) => {
    return platformService.addPlatforms(data);
  };

  /**
   * @name editPlatform
   * @param {EditPlatformBody} data
   * @return {Promise<void>}
   */
  editPlatform = (data) => {
    return platformService.editPlatforms(data);
  };

  /**
   * @name editPlatform
   * @param {EditPlatformGamesBody} data
   * @return {Promise<void>}
   */
  editPlatformGames = (data) => {
    return platformService.editPlatformGames(data);
  };

  /**
   * @name editPlatformPartnersRealModeSwitcher
   * @return {Promise<void>}
   */
  editPlatformPartnersRealModeSwitcher = (id, data) => {
    return platformService.editPlatformPartnersRealModeSwitcher(id, data);
  };

  editHasTournamentPlatformPartner = (id, data) => {
    return platformService.editHasTournamentPlatformPartner(id, data);
  };

  /**
   * @name addPlatformGameLimits
   * @param {number} id
   * @param {AddPlatformGameLimits} data
   * @return {Promise<void>}
   */
  addPlatformGameLimits = (id, data) => {
    return platformService.addPlatformGameLimits(id, data);
  }

  /**
   * @name createPlatformGameLimitsBody
   * @param {AddPlatformGameLimits} values
   * @return {FormData}
   */
  createPlatformGameLimitsBody(values) {
    const { limitsExcel: { blob, fileName }, partnerIds, gameId, gameTypeId } = values;
    const formData = new FormData();
    formData.append('gameId', gameId);
    formData.append('gameTypeId', gameTypeId);
    formData.append('limitsExcel', blob, fileName);
    formData.append('partnerIds', JSON.stringify(partnerIds));
    return formData;
  };
}

export const platformStore = new PlatformStore();
