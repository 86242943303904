/**
 * @name HttpResponseStatus
 * @enum {string}
 * @type {{DONE: string, ERROR: string, PENDING: string}}
 */
export const HttpResponseStatus = {
  PENDING: 'PENDING',
  DONE: 'DONE',
  ERROR: 'ERROR',
};

/**
 * @template T
 */
export class HttpResponse {
  /**
   * @param {any} initialState
   * @param {HttpResponseStatus} status
   */
  constructor(initialState, status = HttpResponseStatus.PENDING) {
    this._initialState = initialState;
    /** @type {T} */
    this.data = initialState;
    /** @type {HttpResponseStatus} */
    this.status = status;
    this.error = '';
  }

  /**
   * @returns {boolean}
   */
  get isDone() {
    return this.status === HttpResponseStatus.DONE;
  }

  /**
   * @returns {boolean}
   */
  get isLoading() {
    return this.status === HttpResponseStatus.PENDING;
  }

  /**
   * @name fetching
   * @param {boolean} resetData
   * @returns {HttpResponse}
   */
  fetching(resetData = false) {
    if (resetData) {
      this.data = this._initialState;
    }
    this.status = HttpResponseStatus.PENDING;
    return this.clone();
  }

  failed(errorMessage) {
    this.error = errorMessage;
    this.status = HttpResponseStatus.ERROR;
    return this.clone();
  }

  fetched(data) {
    if (data?.data?.errorMessage) {
      // if has errorMessage then clone with done status
      this.data = this._initialState;
      return this.failed(data.data.errorMessage);
    }
    this.data = data;
    this.status = HttpResponseStatus.DONE;
    return this.clone();
  }

  concat(newData) {
    const items = [ ...this.data.items, ...newData.items ];
    return { ...this.data, items };
  }

  /**
   * @name clone
   * @return {HttpResponse}
   */
  clone() {
    const self = { ...this };
    Object.setPrototypeOf(self, HttpResponse.prototype);
    return self;
  }
}
