import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { currenciesDataConverter, dataToDropDownConverter } from '../../../core/helpers/dropdownDataConverter';
import { currenciesStore, notifyService, partnerStore, platformStore } from '../../../module';
import { TranslationKeys } from '../../../core/constants/translationKeys';
import { hasPermission } from '../../../core/helpers/permissionsHelper';
import { PERMISSIONS } from '../../../core/constants/permissions';
import useTranslation from '../../../hooks/useTranslation';
import { toggleStore } from '../../../module/toggleModule';
import { modalStore } from '../../../module/popUpModule';
import { useForm } from '../../../hooks/useForm';
import AddPartnerModal from './addPartnerModal';
import useQuery from '../../../hooks/useQuery';
import { Modal, ModalFooter } from '../shared';
import SelectGames from './selectGames';

const createPartnerInitialState = {
  platformId: null,
  name: '',
  partnerId: null,
  currencyCode: '',
  isTest: false,
  authType: null,
  hasRealModeSwitcher: null,
  hasTournament: null,
};

const CreatePartnerModal = observer(() => {
  const history = useHistory();
  const translate = useTranslation();
  const { queryObject } = useQuery();
  const { partnerModal } = modalStore;
  const { currencies: { data: currencies } } = currenciesStore;
  const { platformsPartners: { data: { items: platforms } }, plaftormPartnersMap } = platformStore;
  const [firstState, setFirstState] = useState(true);
  const [gamesCategory, setGamesCategory] = useState([]);
  const [selectedGames, setSelectedGames] = useState([]);
  const { handleChange, values, setValues } = useForm({
    initialState: createPartnerInitialState,
  });
  useEffect(() => {
    setValues((prev) => ({ ...prev, platformId: partnerModal.data }));
  }, [partnerModal.data]);

  useEffect(() => {
    if (hasPermission([PERMISSIONS.ViewPlatformSettings])) {
      platformStore.getPlatforms();
    }
  }, []);

  useEffect(() => {
    if(values.platformId) {
      const { hasRealModeSwitcherByDefault: hasRealModeSwitcher} = plaftormPartnersMap[values.platformId]
      setValues(prev => ({
        ...prev,
        hasRealModeSwitcher
      }))
    }
  }, [values.platformId]);

  const platformData = useMemo(() => {
    return dataToDropDownConverter(platforms);
  }, [platforms]);

  const currencyData = useMemo(() => {
    return currenciesDataConverter(currencies);
  }, [currencies]);

  const handleCancel = useCallback(() => {
    if (!firstState) {
      setFirstState(true);
      setGamesCategory([]);
      setSelectedGames([]);
    } else {
      modalStore.closePartnerModal();
      setValues({
        ...createPartnerInitialState,
      });
    }
  }, [setValues, firstState]);

  const isEmpty = useMemo(() => {
    const { hasTournament, timeZone, ...restValues } = values;
    return Object.values(restValues).some((item) => item === null || item === '');
  }, [values]);

  const onRefreshPage = useCallback(() => {
    const { pathname } = history.location;
    const isInPartnersPage = pathname.includes('partners-settings');
    if (isInPartnersPage) {
      partnerStore.getPartners(queryObject);
    }
    partnerStore.getAllPartners();
  }, [queryObject, history]);

  const handleSave = useCallback(() => {
    if (firstState) {
      setFirstState(false);
    } else {
      toggleStore.setButtonLoading(true);
      partnerStore.addExternalPartner({
        ...values,
        platformId: Number(values.platformId),
        gameIds: selectedGames.map((game) => game.value),
      }).then(() => {
        onRefreshPage();
        notifyService.success({
          message: TranslationKeys.SUCCESS_MESSAGE_OPERATION_HAS_COMPLETED_SUCCESSFULLY_TITLE,
        });
        setFirstState(true);
        modalStore.closePartnerModal();
        history.push('/platform/partners-settings');
        setValues({
          ...createPartnerInitialState,
        });
        setGamesCategory([]);
      }).catch((err) => {
        notifyService.error({
          message: err?.data?.errorMessage,
        });
      }).finally(() => toggleStore.setButtonLoading(false));
    }
  }, [partnerModal, firstState, setValues, selectedGames, onRefreshPage]);

  return (
    <Modal
      visible={partnerModal.isVisible}
      width={firstState ? 600 : '100%'}
      closeOnClickOutside={false}
      title={translate(TranslationKeys.ADD_PARTNER)}
      onCancel={handleCancel}
      className="add-partner-modal"
      footer={(
        <ModalFooter
          onSave={handleSave}
          saveDisabled={isEmpty}
          onCancel={handleCancel}
          actionLoading={toggleStore.buttonLoading}
          saveText={firstState ? TranslationKeys.CONTINUE : TranslationKeys.SAVE}
        />
      )}
    >
      {firstState ? (
        <AddPartnerModal
          values={values}
          setValues={setValues}
          platformData={platformData}
          currencyData={currencyData}
          handleChange={handleChange}
        />
      ) : (
        <SelectGames
          gamesCategory={gamesCategory}
          selectedGames={selectedGames}
          setSelectedGames={setSelectedGames}
          setGamesCategory={setGamesCategory}
          values={values}
          setValues={setValues}
          handleEditContest={handleSave}
        />
      )}
    </Modal>
  );
});

export default CreatePartnerModal;
