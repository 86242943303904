class TypeChecker {
  static isNumber(val) {
    return typeof val === 'number';
  }

  static isNotPositiveNumber(val) {
    return val <= 0;
  }

  static isEmptyString(val) {
    return val === '';
  }

  static isString(val) {
    return typeof val === 'string';
  }

  static isArray(val) {
    return Array.isArray(val);
  }

  static isNull(val) {
    return val === null;
  }

  static isUndefined(val) {
    return val === undefined;
  }

  static isFalsyValues(val) {
    return this.isUndefined(val) || this.isNull(val) || this.isEmptyString(val) || this.isNotPositiveNumber(val);
  }

  static isEmptyObject(val = {}) {
    return !Object.keys(val).length;
  }
}

export default TypeChecker;
