import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Holder } from '@geneui/components';
import styled from 'styled-components';

import useTranslation from '../../../../hooks/useTranslation';
import { useMobileSize } from '../../../../hooks/useMobileSize';

const MobileFilterWrapper = styled.div`
  & .grid-items-group {
    margin: 0;
  }

  & > div {
    margin-bottom: 1rem;
  }

  & div:last-child {
    margin-bottom: 0;
  }
`;

export const FiltersWrapper = ({ children, title, applyDisabled, ...props }) => {
  const translate = useTranslation();
  const { isMobile } = useMobileSize();

  const portalContainer = useMemo(() => {
    return document.getElementById('holder-portal-container');
  }, []);

  return (
    isMobile ? (
      <MobileFilterWrapper>
        {children}
      </MobileFilterWrapper>
    ) : (
      <Holder
        withPortal
        expandText=""
        collapseText=""
        title={translate(title)}
        applyDisabled={applyDisabled}
        portalContainer={portalContainer}
        {...props}
      >
        {children}
      </Holder>
    )
  );
};

FiltersWrapper.defaultProps = {
  title: 'FILTERS',
};

FiltersWrapper.propsTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  onApply: PropTypes.func,
  onReset: PropTypes.func,
};
