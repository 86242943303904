import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { Paper, ComboTable } from '@geneui/components';

import { tableDataFormatter } from '../../../../core/helpers/tableDataFormatter';
import { selectorData } from '../../../../core/constants/selectorData';
import { currenciesStore } from '../../../../module';
import { Breadcrumb } from '..';
import useTableColumns from '../../../../hooks/useTableColumns';
import TableTitleActions from './tableTitleActions';

import './style.css';

export const FullTable = memo(observer(({
  rows,
  columns,
  tableTitle,
  titleActions,
  onRefreshClick,
  border = false,
  ...restProps
}) => {
  const { current: currentCurrency } = currenciesStore;
  const { cols } = useTableColumns(columns, currentCurrency);

  return (
    <Paper isTransparent border={border} cornerRadius="full-radius">
      <ComboTable
        rowsHover
        columns={cols}
        noDataWithImage
        selectorData={selectorData}
        className="table-height-limit"
        rows={tableDataFormatter(rows)}
        name={tableTitle ? <Breadcrumb data={tableTitle}/> : null}
        titleActions={(
          <TableTitleActions
            titleActions={titleActions}
            onRefreshClick={onRefreshClick}
          />
        )}
        {...restProps}
      />
    </Paper>
  );
}));

FullTable.defaultProps = {
  rows: [],
  columns: [],
};

FullTable.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array,
};
