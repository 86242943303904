import React from 'react'; 
import { userStore } from '../../../module';
import {
  MaintenanceAndUserBlockedWr,
  MaintenanceAndUserBlockedDesc,
  MaintenanceAndUserBlockedTitle,
} from '../maintenance';
import { TranslationKeys } from '../../../core/constants/translationKeys';
import { setTranslationValues } from '../../../core/helpers/setTranslationValues';
import useTranslation from '../../../hooks/useTranslation';
import UserBlockedImg from '../../../assets/images/user-blocked.png';
import styled from 'styled-components';

const Image = styled.img`
  width: 200px;
`;

const UserBlocked = () => {

  const { blockedUsername: username } = userStore;
  const translate = useTranslation();

  return (
    <MaintenanceAndUserBlockedWr>
      <Image src={UserBlockedImg} alt="warning" />
      <MaintenanceAndUserBlockedTitle>
        {setTranslationValues(translate(TranslationKeys.USER_BLOCKED_1), {username})}
      </MaintenanceAndUserBlockedTitle>
      <MaintenanceAndUserBlockedDesc>
        {translate(TranslationKeys.USER_BLOCKED_2)}
      </MaintenanceAndUserBlockedDesc>
    </MaintenanceAndUserBlockedWr>
  );
};

export default UserBlocked;
