import { action, flow, makeObservable, observable } from 'mobx';
import moment from 'moment';

import { HttpResponse } from '../../core/classes/HttpResponse';
import { maintenancesService } from './maintenancesService';
import { DATE_FORMAT_BACK } from '../../core/constants/util';

class MaintenanceStore {
  /** @type {HttpResponse<MaintenancesResponse>}*/
  maintenances = new HttpResponse({ items: [], count: 0 });

  /** @type {boolean}*/
  isUnderMaintenance = false;

  constructor() {
    makeObservable(this, {
      maintenances: observable,
      isUnderMaintenance: observable,
      setIsUnderMaintenance: action,
      setMaintenances: action,
      getMaintenances: flow,
    }, { autoBind: true });
  }

  /**
   * @name setIsUnderMaintenance
   * @param {boolean} isUnderMaintenance
   * @returns {void}
   */
   setIsUnderMaintenance = (isUnderMaintenance) => {
    this.isUnderMaintenance = isUnderMaintenance;
  };

  /**
   * @name setMaintenances
   * @param {HttpResponse<MaintenancesResponse>} maintenances
   * @return {void}
   */
  setMaintenances = (maintenances) => {
    this.maintenances = maintenances;
  };

  /**
   * @name getMaintenances
   * @param {MaintenancesParams} params
   * @return {Generator<Promise<MaintenancesResponse>, void, *>}
   */
  getMaintenances = function* (params) {
    this.setMaintenances(this.maintenances.fetching());
    const data = yield maintenancesService.getMaintenances(params);
    this.setMaintenances(this.maintenances.fetched(data));
  };

  /**
   * @name addMaintenances
   * @param {MaintenanceRequestBody} maintenance
   * @return {Promise<MaintenancesItem>}
   */
  addMaintenances = (maintenance) => {
    return maintenancesService.addMaintenances(maintenance);
  };

  /**
   * @name editMaintenancesById
   * @param {number} maintenanceId
   * @param {MaintenanceByIdRequest} data
   * @return {Promise<MaintenancesItem>}
   */
  editMaintenancesById = (maintenanceId, data) => {
    return maintenancesService.editMaintenancesById(maintenanceId, data);
  };
  
}

export const maintenanceStore = new MaintenanceStore();
