import React from 'react';
import { Tag as CoreTag } from '@geneui/components';

export const Tag = ({
  ...props
}) => {
  return (
    <CoreTag
      {...props}
    />
  );
};
