import React, { useCallback, useMemo } from 'react';
import { Dropdown as CoreDropdown } from '@geneui/components';

import { TranslationKeys } from '../../../../core/constants/translationKeys';
import useTranslation from '../../../../hooks/useTranslation';

const DROPDOWN_ALL_VALUE = 'DROPDOWN_ALL_VALUE';

export const Dropdown = ({
  data,
  value,
  hasAll,
  onChange,
  ...props
}) => {

  const translate = useTranslation();

  const _data = useMemo(() => {
    if (hasAll) {
      return [
        {
          id: DROPDOWN_ALL_VALUE,
          value: DROPDOWN_ALL_VALUE,
          label: translate(TranslationKeys.ALL)
        },
        ...data
      ];
    }
    return data;
  }, [hasAll, data, translate]);

  const handleChange = useCallback((data) => {
    const _data = data?.value === DROPDOWN_ALL_VALUE ? null : data;
    onChange(_data);
  }, [onChange]);

  const _value = useMemo(() => {
    if (!hasAll) return value;
    return value == null || value === '' ? DROPDOWN_ALL_VALUE : value;
  }, [hasAll, value]);

  return (
    <CoreDropdown
      data={_data}
      value={_value}
      onChange={handleChange}
      {...props}
    />
  );
};
