export const deepEqual = (a, b) => {

  if (a === b) {
    return true;
  }

  if (a == null || typeof a != "object" ||
    b == null || typeof b != "object") {
    return false;
  }

  let propsInA = 0, propsInB = 0;

  for (let prop in a) {
    propsInA += 1;
  }

  for (let prop in b) {
    propsInB += 1;
    if (!(prop in a) || !deepEqual(a[prop], b[prop])) {
      if (!(prop in a)) console.log('Returning false (no prop in a)');
      if (!deepEqual(a[prop], b[prop])) console.log('Returning false (Deep Equal returns false)');
      return false;
    }
  }

  return propsInA == propsInB;
}
