import { ApiVersion } from '../../core/enums/ApiVersion';
import { DEFAULT_HEADERS, httpClient } from '../httpClient/httpClient';
import {
  exportGameTypesLimitsUrl,
  gameTypesLimitsUrl,
  getEditGameTypeUrl,
  getGameTypeDetailedUrl,
  getGameTypesUrl,
} from '../../core/constants/endpoints';
import { combineQuery } from '../../core/helpers/queryHelper';

class GameTypesService {
  /**
   * @name getGameTypes
   * @param {GameTypesParams=} params
   * @param {ApiOption=} option
   * @returns {Promise<GameTypesResponse>}
   */
  async getGameTypes(params = {}, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getGameTypesUrl(option), { params });
  }

  /**
   * @name addGameType
   * @param {GameTypesPartnersBody} data
   * @param {ApiOption=} option
   * @returns {Promise<GameTypeResponse>}
   */
  async addGameType(data, option = { version: ApiVersion.V1 }) {
    return await httpClient.post(getGameTypesUrl(option), data);
  }

  /**
   * @name editGameType
   * @param {number} id
   * @param {GameTypesPartnersBody} data
   * @param {ApiOption=} option
   * @returns {Promise<GameTypeResponse>}
   */
  async editGameType(id, data, option = { version: ApiVersion.V1 }) {
    return await httpClient.put(getEditGameTypeUrl(id, option), data);
  }

  /**
   * @name getDetailedGameType
   * @param {number} id
   * @param {ApiOption=} option
   * @returns {Promise<GameTypeDetailed>}
   */
  async getDetailedGameType(id, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getGameTypeDetailedUrl(id, option));
  }

  /**
   * @name getGameTypesLimits
   * @param {number} id
   * @param {ApiOption} option
   * @param {GameTypesLimitsParams} params
   * @returns {Promise<GameTypesLimitsResponse>}
   */
  async getGameTypesLimits(id, params, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(gameTypesLimitsUrl(id, option), { params });
  }

  /**
   * @name editGameTypesLimits
   * @param {number} id
   * @param {GameTypesLimitsBody} data
   * @param {ApiOption} option
   * @returns {Promise<GameTypesLimitsResponse>}
   */
  async editGameTypesLimits(id, data, option = { version: ApiVersion.V1 }) {
    return await httpClient.put(gameTypesLimitsUrl(id, option), data);
  }

  /**
   * @name addGameTypesLimits
   * @param {number} id
   * @param {AddGameTypesLimitsBody} data
   * @param {ApiOption} option
   * @returns {Promise<GameTypesLimitsResponse>}
   */
  async addGameTypesLimits(id, data, option = { version: ApiVersion.V1 }) {
    return await httpClient.post(gameTypesLimitsUrl(id, option), data);
  }

  /**
   * @name exportGameTypesLimit
   * @param {number} id
   * @param {ExportGameTypesLimitParams} params
   * @param {ApiOption=} option
   * @return {Promise<File>}
   */
  async exportGameTypesLimit(id, params, option = { version: ApiVersion.V1 }) {
    return await fetch(`${exportGameTypesLimitsUrl(id, option)}${combineQuery(params)}`, {
      credentials: 'include',
      headers: {
        ...DEFAULT_HEADERS
      }
    });
  }

}

export const gameTypesService = new GameTypesService();
