import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { dataToDropDownConverter } from '../../../../core/helpers/dropdownDataConverter';
import { currenciesStore, notifyService, userStore } from '../../../../module';
import { TranslationKeys } from '../../../../core/constants/translationKeys';
import useTranslation from '../../../../hooks/useTranslation';
import { Dropdown } from '../../shared';

const CurrencyContent = styled.div`
  --background-sc-rgb: #3d3d5f;
  height: 42px;
  display: flex;
  align-items: center;

  & input {
    padding: 20px 14px;
  }

  & .input-element-back {
    border: none;
  }
`;

const CurrencyFilter = observer(() => {
  const { userInfo: { data: userInfo } } = userStore;
  const { currencies: { data: currencies } } = currenciesStore;
  const translate = useTranslation();
  const [selectedCurrency, setSelectedCurrency] = useState(null);

  useEffect(() => {
    if (userInfo.currencyCode) {
      setSelectedCurrency(userInfo.currencyCode);
    }
  }, [userInfo]);

  useEffect(() => {
    if (currencies.length && selectedCurrency) {
      const result = currencies.find((item) => item.currencyCode === selectedCurrency);
      currenciesStore.setCurrentCurrency(result);
    }
  }, [currencies, selectedCurrency]);

  const currencyChangeHandler = useCallback((event) => {
    setSelectedCurrency(event.value);
    userStore.editUserInfo({
      id: userInfo.id,
      currencyCode: event.value,
    }).catch((err) => {
      notifyService.error({
        message: err?.data?.errorMessage,
      });
    });
  }, [currencies, selectedCurrency]);

  const convertedCurrency = useMemo(() => {
    return currencies.map((item) => ({
      id: item.currencyCode,
      name: item.currencyCode,
    }));
  }, [currencies]);

  return (
    <CurrencyContent>
      <Dropdown
        placeholder={translate(TranslationKeys.CURRENCY)}
        data={dataToDropDownConverter(convertedCurrency)}
        onChange={currencyChangeHandler}
        name='currencyCode'
        value={selectedCurrency}
        flexibility='content-size'
        appearance='minimal'
        inputSize='small'
        swipeable
        align='center'
      />
    </CurrencyContent>
  );
});

export default CurrencyFilter;
