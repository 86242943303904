import React, { useCallback, useMemo, useState } from 'react';
import Profile from '@geneui/components/Profile';
import { observer } from 'mobx-react-lite';

import useTranslation from '../../../../hooks/useTranslation';
import { authStore, translationStore, userStore } from '../../../../module';
import { TranslationKeys } from '../../../../core/constants/translationKeys';
import { modalStore } from '../../../../module/popUpModule';
import { applicationsStore } from '../../../../module/applicationsModule';
import { BACKOFFICE_APPLICATION_ID } from '../../../../core/constants/util';

const avatarPropsConfig = {
  color: 'white',
  size: 'default',
};

const UserProfile = observer(() => {
  const { setResetPasswordModal } = modalStore;
  const { userInfo: { data: userInfo } } = userStore;
  const { backofficeLanguages: { data: languages } } = applicationsStore;

  const [isOpenUserProfile, setIssOpenUserProfile] = useState(false);
  const { setSettingsModal } = modalStore;
  const translate = useTranslation();
  const [selectedLanguage, setLanguage] = useState(null);
  const languageList = useMemo(() =>
    (
      languages.map(item => ({
        ...item,
        checkMark: true,
        active: selectedLanguage ? selectedLanguage === item.id : item.isDefault,
        title: item.name,
        onClick: () => {
          translationStore.getBackofficeTranslations({ languageId: item.id }, BACKOFFICE_APPLICATION_ID);
          setLanguage(item.id);
        },
      }))
    ), [languages, selectedLanguage]);

  const menuData = useMemo(() => {
    return [
      {
        title: translate(TranslationKeys.SETTINGS),
        divider: { hasFullWidth: true },
        onClick: () => {
          setIssOpenUserProfile(false);
          setSettingsModal({ isVisible: true });
        },
      },
      {
        title: translate(TranslationKeys.CHANGE_PASSWORD),
        divider: { hasFullWidth: true },
        onClick: () => {
          setIssOpenUserProfile(false);
          setResetPasswordModal({ isVisible: true });
        },
      },
      {
        title: `${translate(TranslationKeys.LANGUAGE)}`,
        divider: { hasFullWidth: true },
        children: languageList,
        border: 'bottom',
      },
      {
        title: translate(TranslationKeys.LOG_OUT),
        divider: { hasFullWidth: true },
        onClick: () => {
          authStore.signOut();
        },
      },
    ];
  }, [translate, languageList]);

  const handleToggle = useCallback(() => {
    setIssOpenUserProfile(isOpen => !isOpen);
  }, []);

  return (
    <>
      <Profile
        showIcon={false}
        isOpen={isOpenUserProfile}
        toggleHandler={handleToggle}
        username={userInfo.userName}
        menuProps={{ data: menuData }}
        avatarProps={avatarPropsConfig}
        onOutsideClick={() => setIssOpenUserProfile(false)}
      />
    </>
  );
});

export default UserProfile;
