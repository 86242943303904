import { useEffect } from 'react';

const hasIgnoredClass = (element, ignoredClass) => (
  element.correspondingElement
    ? element.correspondingElement
    : element
).classList.contains(ignoredClass);

const isInIgnoredElement = (element, ignoredClass) => {
  do {
    if (hasIgnoredClass(element, ignoredClass)) {
      return true;
    }
  } while ((element = element.parentElement));
  return false;
};

const useOutSideClick = (ref, callBack, ignoredClass) => {
  useEffect(
    () => {
      const listener = event => {
        if (
          !ref.current
          || ref.current.contains(event.target)
          || (ignoredClass && isInIgnoredElement(event.target, ignoredClass))
        ) {
          return;
        }

        callBack(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    [ref, callBack],
  );
};

export default useOutSideClick;
