import { httpClient } from '../httpClient/httpClient';
import { addMaintenanceUrl, editMaintenancesByIdUrl, getMaintenancesUrl } from '../../core/constants/endpoints';
import { ApiVersion } from '../../core/enums/ApiVersion';
import { abortFetch } from '../../core/helpers/abortController';
import { ABORT_API_KEYS } from '../../core/constants/abortApiKeys';

class MaintenancesService {
  /**
   * @name getMaintenances
   * @param {MaintenancesParams=} params
   * @param {ApiOption=} option
   * @returns {Promise<MaintenancesResponse>}
   */
  async getMaintenances(params= {}, option = { version: ApiVersion.V1 }) {
    const signal = abortFetch(ABORT_API_KEYS.GetMaintenance);
    return await httpClient.get(getMaintenancesUrl(option), { params, signal });
  }

  /**
   * @name addMaintenances
   * @param {MaintenanceRequestBody} data
   * @param {ApiOption=} option
   * @returns {Promise<MaintenancesItem>}
   */
  async addMaintenances(data, option = { version: ApiVersion.V1 }) {
    return await httpClient.post(addMaintenanceUrl(option), data);
  }

  /**
   * @name editMaintenancesById
   * @param {number} maintenanceId
   * @param {MaintenanceByIdRequest} data
   * @param {ApiOption=} option
   * @returns {Promise<MaintenancesItem>}
   */
  async editMaintenancesById(maintenanceId, data, option = { version: ApiVersion.V1 }) {
    return await httpClient.put(editMaintenancesByIdUrl(maintenanceId, option), data);
  }
}

export const maintenancesService = new MaintenancesService();
