import { ApiVersion } from '../../core/enums/ApiVersion';
import { DEFAULT_HEADERS, httpClient } from '../httpClient/httpClient';
import {
  getPartnersUrl,
  getPartnerInfoByIdUrl,
  getPartnersGameConfigUrl,
  editPartnersGameConfigUrl,
  getPartnersGameTypeUrl,
  partnersGameTypeLimitUrl,
  getAddExternalPartnerUrl,
  getAddInternalPartnerUrl,
  partnersIdGameTypeLimitUrl,
  exportPartnersGameTypeLimitsUrl,
  importPartnersGameTypeLimitsUrl,
  exportPartnerGameTypeLimitsCurrenciesUrl,
  getPartnersGameTypeLimitsCurrenciesUrl, editPartnerGamesUrl,
} from '../../core/constants/endpoints';
import { combineQuery } from '../../core/helpers/queryHelper';

class PartnerService {
  /**
   * @name getPartners
   * @param {PartnersParams=} params
   * @param {ApiOption=} option
   * @return {Promise<Partners>}
   */
  async getPartners(params = {}, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getPartnersUrl(option), { params });
  }

  /**
   * @name editPartners
   * @param {PartnerRequestBody} data
   * @param {ApiOption=} option
   * @returns {Promise<Partners>}
   */
  async editPartners(data, option = { version: ApiVersion.V1 }) {
    return await httpClient.put(getPartnersUrl(option), data);
  }

  /**
   * @name editPartnerGames
   * @param {EditPartnerGamesBody} data
   * @param {ApiOption} option
   * @return {Promise<void>}
   */
  async editPartnerGames(data, option = {version: ApiVersion.V1}) {
    return await httpClient.put(editPartnerGamesUrl(option), data);
  }

  /**
   * @name addInternalPartner
   * @param {PartnerInternalBody} data
   * @param {ApiOption=} option
   * @return {Promise<PartnerItem>}
   */
  async addInternalPartner(data, option = { version: ApiVersion.V1 }) {
    return await httpClient.post(getAddInternalPartnerUrl(option), data);
  }

  /**
   * @name getPartnerInfo
   * @param {number} id
   * @param {ApiOption=} option
   * @return {Promise<PartnerInfoResponse>}
   */
  async getPartnerInfo(id, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getPartnerInfoByIdUrl(id, option));
  }

  /**
   * @name getPartnerGameTypeLimitById
   * @param {number} id
   * @param {PartnerGameTypeLimitParams=} params
   * @param {ApiOption=} option
   * @return {Promise<PartnerGameTypeLimitResponse>}
   */
  async getPartnerGameTypeLimitById(id, params = {}, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(partnersIdGameTypeLimitUrl(id, option), { params });
  }

  /**
   * @name editPartnerGameTypeLimitById
   * @param {number} id
   * @param {EditPartnerGameTypeLimitBody} data
   * @param {ApiOption=} option
   * @returns {Promise<void>}
   */
  async editPartnerGameTypeLimitById(id, data, option = { version: ApiVersion.V1 }) {
    return await httpClient.put(partnersIdGameTypeLimitUrl(id, option), data);
  }

  /**
   * @name getPartnersGameConfigById
   * @param {number} id
   * @param {number} gameId
   * @param {ApiOption=} option
   * @returns {Promise<PartnersGameConfig>}
   */
  async getPartnersGameConfigById(id, gameId, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getPartnersGameConfigUrl(id, gameId, option));
  }

  /**
   * @name getPartnersGameTypeLimits
   * @param {GetPartnerGameTypeLimitsParams} params
   * @param {ApiOption} option
   * @return {Promise<GameTypesLimitsResponse>}
   */
  async getPartnersGameTypeLimitsCurrencies(params, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getPartnersGameTypeLimitsCurrenciesUrl(option), { params });
  }

  /**
   * @name editPartnersGameConfig
   * @param {number} id
   * @param {PartnersGameConfig} data
   * @param {ApiOption=} option
   * @returns {Promise<void>}
   */
  async editPartnersGameConfig(id, data, option = { version: ApiVersion.V1 }) {
    return await httpClient.put(editPartnersGameConfigUrl(id, option), data);
  }

  /**
   * @name getPartnersGameTypeById
   * @param {number} partnerId
   * @param {number} gameTypeId
   * @param {ApiOption=} option
   * @returns {Promise<PartnersGameType>}
   */
  async getPartnersGameTypeById(partnerId, gameTypeId, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getPartnersGameTypeUrl(partnerId, gameTypeId, option));
  }

  /**
   * @name partnersGameTypeLimit
   * @param {number} gameTypeId
   * @param {PartnersGameTypeLimitParams=} params
   * @param {ApiOption=} option
   * @returns {Promise<PartnersGameTypeLimit>}
   */
  async partnersGameTypeLimit(gameTypeId, params = {}, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(partnersGameTypeLimitUrl(gameTypeId, option), { params });
  }

  /**
   * @name addExternalPartner
   * @param {PartnerExternalBody} data
   * @param {ApiOption=} option
   * @return {Promise<PartnerExternalResponse>}
   */
  async addExternalPartner(data, option = { version: ApiVersion.V1 }) {
    return await httpClient.post(getAddExternalPartnerUrl(option), data);
  }

  /**
   * @name exportPartnersGameTypeLimits
   * @param {number} id
   * @param {ExportPartnersGameTypeLimitsParams} params
   * @param {ApiOption} option
   * @return {Promise<File>}
   */
  async exportPartnersGameTypeLimits(id, params, option = { version: ApiVersion.V1 }) {
    return await fetch(`${exportPartnersGameTypeLimitsUrl(id, option)}${combineQuery(params)}`, {
      credentials: 'include',
      headers: {
        ...DEFAULT_HEADERS,
      },
    });
  }

  /**
   * @name exportPartnerGameTypeLimitsCurrencies
   * @param {Object} params
   * @param {number} id
   * @param {ApiOption} option
   * @return {Promise<File>}
   */
  async exportPartnerGameTypeLimitsCurrencies(id, params, option = { version: ApiVersion.V1 }) {
    return await fetch(`${exportPartnerGameTypeLimitsCurrenciesUrl(id, option)}${combineQuery(params)}`, {
      credentials: 'include',
      headers: {
        ...DEFAULT_HEADERS,
      },
    });
  }

  /**
   * @name importPartnersGameTypeLimits
   * @param {number} id
   * @param {ImportPartnersGameTypeLimits} data
   * @param {ApiOption} option
   * @return {Promise<any>}
   */
  async importPartnersGameTypeLimits(id, data, option = { version: ApiVersion.V1 }) {
    return httpClient.put(importPartnersGameTypeLimitsUrl(id, option), data);
  }
}

export const partnerService = new PartnerService();
