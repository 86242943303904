import { DEFAULT_TAKE } from './util';

export const selectorData =  [
    {
        label: '10',
        value: 10,
    },
    {
        label: '20',
        value: DEFAULT_TAKE,
        defaultSelected: true,
    },
    {
        label: '50',
        value: 50,
    },
    {
        label: '100',
        value: 100,
    },
];