import { action, flow, makeObservable, observable } from 'mobx';

import { HttpResponse } from '../../core/classes/HttpResponse';
import { promoService } from './promoService';

class PromoStore {

  /** @type {HttpResponse<PromoTemplates>}*/
  promoTemplates = new HttpResponse({ items: [], count: 0 });

  /** @type {HttpResponse<AddEditPromoTemplate>}*/
  promoTemplateById = new HttpResponse({});

  /** @type {HttpResponse<PromoPlayerReport>}*/
  promoPlayersReport = new HttpResponse({ items: [], count: 0 });

  constructor() {
    makeObservable(this, {
      promoTemplates: observable,
      promoTemplateById: observable,
      promoPlayersReport: observable,
      setPromoTemplates: action.bound,
      setPromoTemplateById: action.bound,
      setPromoPlayersReport: action.bound,
      getPromoTemplates: flow.bound,
      getPromoTemplateById: flow.bound,
      getPromoPlayersReport: flow.bound,
    });
  }

  /**
   * @name setPromoTemplates
   * @param {HttpResponse<PromoTemplates>} data
   * @return {void}
   */
  setPromoTemplates = (data) => {
    this.promoTemplates = data;
  }

  /**
   * @name setPromoTemplateById
   * @param {HttpResponse<AddEditPromoTemplate>} data
   * @return {void}
   */
  setPromoTemplateById = (data) => {
    this.promoTemplateById = data;
  }

  /**
   * @name setPromoPlayersReport
   * @param {HttpResponse<PromoPlayerReport>} data
   * @return {void}
   */
  setPromoPlayersReport = (data) => {
    this.promoPlayersReport = data;
  }

  /**
   * @name getPromoTemplates
   * @param {GetPromoTemplatesParams=} params
   * @return {Generator<Promise<PromoTemplates>, void, *>}
   */
  getPromoTemplates = function* (params) {
    this.setPromoTemplates(this.promoTemplates.fetching());
    const data = yield promoService.getPromoTemplates(params);
    this.setPromoTemplates(this.promoTemplates.fetched(data));
  };

  /**
   * @name getPromoTemplateById
   * @param {number} id
   * @return {Generator<Promise<AddEditPromoTemplate>, void, *>}
   */
  getPromoTemplateById = function* (id) {
    this.setPromoTemplateById(this.promoTemplateById.fetching());
    const data = yield promoService.getPromoTemplateById(id);
    this.setPromoTemplateById(this.promoTemplateById.fetched(data));
  };

  /**
   * @name getPromoPlayersReport
   * @param {object} params
   * @return {Generator<Promise<PromoPlayerReport>, void, *>}
   */
  getPromoPlayersReport = function* (params) {
    const { concat, ...restParams } = params;
    this.setPromoPlayersReport(this.promoPlayersReport.fetching());
    const report = yield promoService.getPromoPlayersReport(restParams);
    const data = concat ? this.promoPlayersReport.concat(report) : report;
    this.setPromoPlayersReport(this.promoPlayersReport.fetched(data));
  };

  /**
   * @name addPromoTemplate
   * @param {AddEditPromoTemplate} data
   * @return {Promise<void>}
   */
  addPromoTemplate = (data) => {
    return promoService.addPromoTemplate(data);
  }
  
  /**
   * @name editPromoTemplate
   * @param {AddEditPromoTemplate} data
   * @return {Promise<void>}
   */
  editPromoTemplate = (data) => {
    return promoService.editPromoTemplate(data);
  }
  
  /**
   * @name cancelPromoTemplate
   * @param {number} id
   * @return {Promise<void>}
   */
  cancelPromoTemplate = (id) => {
    return promoService.cancelPromoTemplate(id);
  }

}

export const promoStore = new PromoStore();
