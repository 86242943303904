export const DateRangeQuickFilters = {
  Last6Hours: 'Last6Hours',
  Today: 'Today',
  Yesterday: 'Yesterday',
  Last7Days: 'Last7Days',
  ThisMonth: 'ThisMonth',
  LastMonth: 'LastMonth',
  TwoMonthsAgo: 'TwoMonthsAgo',
  ThreeMonthsAgo: 'ThreeMonthsAgo'
};
