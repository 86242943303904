import React, { useCallback, useEffect, useMemo } from 'react';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useDrag } from 'react-dnd';

import { PGTransferListItem } from '../pg-transfer-list-item';

export const PGTransferListItemWrapper = ({
  label,
  value,
  children,
  onSelect,
  containerName,
  selectedItems,
}) => {

  const isGroupSelected = useMemo(() => {
    return !!selectedItems[value] || !!children?.every(({ value }) => selectedItems[value]);
  }, [selectedItems, value, children]);

  const canDrag = useMemo(() => {
    return !!selectedItems[value] || !!children?.some(({ value }) => selectedItems[value]);
  }, [selectedItems, value, children]);

  const [{ isDragging }, drag, preview] = useDrag({
    item: { id: value },
    type: `ITEM_${containerName}`,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: canDrag
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);


  const handleGroupSelect = useCallback((checked) => {
    if (children) {
      onSelect(children, checked);
    } else {
      onSelect([{ label, value }], checked);
    }
  }, [label, value, onSelect, children]);

  const handleItemSelect = useCallback((item, checked) => {
    onSelect([item], checked);
  }, [onSelect]);

  return (
    <div
      ref={drag}
      className="drag-group-item-wr"
    >
      <PGTransferListItem
        label={label}
        value={value}
        selected={isGroupSelected}
        onSelect={handleGroupSelect}
      >
        {
          children?.map(({ label, value }) => (
            <PGTransferListItem
              key={value}
              label={label}
              value={value}
              withBorder={false}
              selected={!!selectedItems[value]}
              onSelect={(checked) => handleItemSelect({ label, value }, checked)}
            />
          ))
        }
      </PGTransferListItem>
    </div>
  );
};
