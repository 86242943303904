import React from 'react';
import { Popover as CorePopover } from '@geneui/components';

export const Popover = ({ ...props }) => {
  return (
    <CorePopover
      {...props}
    />
  );
};
