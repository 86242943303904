import { action, flow, makeObservable, observable } from 'mobx';

import { HttpResponse } from '../../core/classes/HttpResponse';
import { gameTypesService } from './gameTypesService';
import { downloadFileFromHttpResponse } from '../../core/helpers/fileDownloader';

class GameTypesStore {
  /** @type {HttpResponse<GameTypesResponse>}*/
  gameTypes = new HttpResponse({ items: [], count: 0 });

  /** @type {HttpResponse<GameTypeDetailed>}*/
  detailedGameType = new HttpResponse({});

  /** @type {HttpResponse<>}*/
  gameTypeLimits = new HttpResponse({ items: [], count: 0 });

  constructor() {
    makeObservable(this, {
      gameTypes: observable,
      detailedGameType: observable,
      gameTypeLimits: observable,
      setGameTypes: action,
      setGameTypeDetailed: action,
      setGameTypeLimits: action,
      getGameTypes: flow,
      getDetailedGameType: flow,
      getGameTypeLimits: flow,
    }, { autoBind: true });
  }

  /**
   * @name setGameTypes
   * @param {HttpResponse<GameTypesResponse>} gameTypes
   * @returns {void}
   */
  setGameTypes = (gameTypes) => {
    this.gameTypes = gameTypes;
  };

  /**
   * @name setGameTypeDetailed
   * @param {HttpResponse<GameTypeDetailed>} detailedGameType
   * @returns {void}
   */
  setGameTypeDetailed = (detailedGameType) => {
    this.detailedGameType = detailedGameType;
  };

  /**
   * @name setGameTypeLimits
   * @param {HttpResponse<GameTypesLimitsResponse>} limits
   * @returns {void}
   */
  setGameTypeLimits = (limits) => {
    this.gameTypeLimits = limits;
  };

  /**
   * @name addGameTypes
   * @param {GameTypesPartnersBody} gameType
   * @returns {Promise<GameTypeResponse>}
   */
  addGameTypes = (gameType) => {
    return gameTypesService.addGameType(gameType);
  };

  /**
   * @name editGameType
   * @param {number} id
   * @param {Object} body
   * @returns {Promise<GameTypeResponse>}
   */
  editGameType = (id, body) => {
    const formData = new FormData();
    const entriesData = Object.entries(body);
    entriesData.forEach(([key, value]) => {
      if (value?.blob) {
        formData.append(key, value.blob, value.fileName);
      } else if (key === 'config' || Array.isArray(value)) {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    });
    return gameTypesService.editGameType(id, formData);
  };

  /**
   * @name getGameTypes
   * @param {GameTypesParams=} params
   * @returns {Generator<Promise<GameTypesResponse>, void, *>}
   */
  getGameTypes = function* (params) {
    this.setGameTypes(this.gameTypes.fetching());
    const data = yield gameTypesService.getGameTypes(params);
    this.setGameTypes(this.gameTypes.fetched(data));
  };

  /**
   * @name getDetailedGameType
   * @param {number} id
   * @returns {Generator<Promise<GameTypeDetailed>, void, *>}
   */
  getDetailedGameType = function* (id) {
    this.setGameTypeDetailed(this.detailedGameType.fetching());
    const detailedGameType = yield gameTypesService.getDetailedGameType(id);
    this.setGameTypeDetailed(this.detailedGameType.fetched(detailedGameType));
  };

  /**
   * @name getGameTypeLimits
   * @param {number} id
   * @param {GameTypesLimitsParams=} params
   * @return {Generator<Promise<GameTypesLimitsResponse>, void, *>}
   */
  getGameTypeLimits = function* (id, params) {
    this.setGameTypeLimits(this.gameTypeLimits.fetching());
    const gameTypeLimits = yield gameTypesService.getGameTypesLimits(id, params);
    this.setGameTypeLimits(this.gameTypeLimits.fetched(gameTypeLimits));
  };

  /**
   * @name editGameTypeLimits
   * @param {number} id
   * @param {GameTypesLimitsBody} data
   * @return {Promise<GameTypesLimitsResponse>}
   */
  editGameTypeLimits = (id, data) => {
    return gameTypesService.editGameTypesLimits(id, data);
  };

  /**
   * @name addGameTypeLimits
   * @param {number} id
   * @param {AddGameTypesLimitsBody} data
   * @return {Promise<GameTypesLimitsResponse>}
   */
  addGameTypeLimits = (id, data) => {
    return gameTypesService.addGameTypesLimits(id, data);
  };

  /**
   * @name createGameTypeBody
   * @param {Object<{partnerIds: Array<number>, name: string, duration: string}>} values
   * @param {any} limitsBLOB
   * @param {boolean} isDefault
   * @param {number} gameId
   * @return {FormData}
   */
  createGameTypeBody({ values, limitsBLOB, gameId, isDefault }) {
    const formData = new FormData();
    formData.append('Name', values.name);
    formData.append('GameId', String(gameId));
    formData.append('isDefault', isDefault);
    formData.append('LimitsExcel', limitsBLOB.blob, limitsBLOB.name);
    formData.append('Config', JSON.stringify(values.config));
    formData.append('partners', JSON.stringify(values.partners));
    return formData;
  };

  resetDetailedGameType = () => {
    this.setGameTypeDetailed(this.detailedGameType.fetching(true));
  };

  /**
   * @name exportGameTypesLimits
   * @param {number} id
   * @param {ExportGameTypesLimitParams} params
   * @return {Promise<{ok}>}
   */
  exportGameTypesLimits = async (id, params) => {
    const res = await gameTypesService.exportGameTypesLimit(id, params);
    if (!res.ok) {
      throw new Error('Something went wrong while exporting.');
    }
    try {
      downloadFileFromHttpResponse(res, 'gameTypes.xlsx');
    } catch (error) {
      console.error(error);
    }
    return res;
  };
}

export const gameTypesStore = new GameTypesStore();
