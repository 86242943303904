import React from 'react';
import styled from 'styled-components';
import useTranslation from '../../../../hooks/useTranslation';

const Wrapper = styled.li`
  display: flex;
  margin-top: 1.6rem;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  .label {
    color: #00000080;
    flex: 1;
  }
  .value {
    color: #000000cc;
    flex: 1;
  }
`;

export const SectionListItem = ({
  label,
  value
}) => {

  const translate = useTranslation();

  return (
    <Wrapper>
      <span className="label">{ translate(label) }</span>
      <span className="value">{ value }</span>
    </Wrapper>
  );
};
