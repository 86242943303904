import React from 'react';
import { Divider as CoreDivider } from '@geneui/components';

export const Divider = ({ ...props }) => {
  return (
    <CoreDivider
      {...props}
    />
  );
};
