import { action, flow, makeObservable, observable } from 'mobx';

import { HttpResponse } from '../../core/classes/HttpResponse';
import { userTournamentsService } from './userTournamentsService';

class UserTournamentsStore {
  /** @type {HttpResponse<ResponseUserTournaments>}*/
  userTournaments = new HttpResponse({ selections: [], winingCount: 0 });

  /** @type {HttpResponse<ResponseUserTournamentsReport>}*/
  userTournamentsReport = new HttpResponse({});

  /** @type {HttpResponse<ResponseUserTournamentsRangeReport>}*/
  userTournamentsRangeReport = new HttpResponse({});

  /** @type {HttpResponse<ResponseUserTournament>}*/
  userTournament = new HttpResponse({});

  constructor() {
    makeObservable(this, {
      userTournaments: observable,
      userTournamentsReport: observable,
      userTournamentsRangeReport: observable,
      userTournament: observable,
      setUserTournaments: action.bound,
      setUserTournamentsReport: action.bound,
      setUserTournamentsRangeReport: action.bound,
      setUserTournament: action.bound,
      getUserTournaments: flow,
      getUserTournamentsReport: flow,
      getUserTournamentsRangeReport: flow,
      getUserTournament: flow,
    });
  }

  /**
   * @name setUserTournaments
   * @param {HttpResponse<ResponseUserTournaments>} userTournaments
   * @return {void}
   */
  setUserTournaments = (userTournaments) => {
    this.userTournaments = userTournaments;
  };

  /**
   * @name setUserTournamentsReport
   * @param {HttpResponse<ResponseUserTournamentsReport>} userTournamentsReport
   * @return {void}
   */
  setUserTournamentsReport = (userTournamentsReport) => {
    this.userTournamentsReport = userTournamentsReport;
  };

  /**
   * @name setUserTournamentsRangeReport
   * @param {HttpResponse<ResponseUserTournamentsRangeReport>} userTournamentsRangeReport
   * @return {void}
   */
  setUserTournamentsRangeReport = (userTournamentsRangeReport) => {
    this.userTournamentsRangeReport = userTournamentsRangeReport;
  };

  /**
   * @name setUserTournament
   * @param {HttpResponse<ResponseUserTournament>} userTournament
   * @return {void}
   */
  setUserTournament = (userTournament) => {
    this.userTournament = userTournament;
  };

  /**
   * @name getUserTournaments
   * @param {UserTournamentsParams} params
   * @return {Generator<Promise<ResponseUserTournaments>, void, *>}
   */
  getUserTournaments = function* (params) {
    this.setUserTournaments(this.userTournaments.fetching());
    const data = yield userTournamentsService.getUserTournaments(params);
    this.setUserTournaments(this.userTournaments.fetched(data));
  };

  /**
   * @name getUserTournamentsReport
   * @param {UserTournamentsReportParams} params
   * @return {Generator<Promise<ResponseUserTournamentsReport>, void, *>}
   */
  getUserTournamentsReport = function* (params) {
    this.setUserTournamentsReport(this.userTournamentsReport.fetching());
    const data = yield userTournamentsService.getUserTournamentsReports(params);
    this.setUserTournamentsReport(this.userTournamentsReport.fetched(data));
  };

  /**
   * @name getUserTournamentsRangeReport
   * @param {UserTournamentsRangeReportParams} params
   * @return {Generator<Promise<ResponseUserTournamentsRangeReport>, void, *>}
   */
  getUserTournamentsRangeReport = function* (params) {
    this.setUserTournamentsRangeReport(this.userTournamentsRangeReport.fetching());
    const data = yield userTournamentsService.getUserTournamentsRangeReports(params);
    this.setUserTournamentsRangeReport(this.userTournamentsRangeReport.fetched(data));
  };

  /**
   * @name getUserTournament
   * @param {UserTournamentParams} params
   * @return {Generator<Promise<ResponseUserTournament>, void, *>}
   */
  getUserTournament = function* (params) {
    this.setUserTournament(this.userTournament.fetching());
    const data = yield userTournamentsService.getUserTournament(params);
    this.setUserTournament(this.userTournament.fetched(data));
  };
}

export const userTournamentsStore = new UserTournamentsStore();
