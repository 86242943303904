import React from 'react';
import styled from 'styled-components';
import { Checkbox } from '..';

const ColumnWr = styled.div`
  display: flex;
  align-items: center;
`;

const ColumnLabel = styled.span`
  ${({ noPadding }) => !noPadding && `
    margin-left: 2.4rem;
  `}
`;

export const TableColumnCheckbox = ({
  text,
  onChange,
  checked = false,
  hideCheckbox = false,
  indeterminate = false,
}) => {
  return (
    <ColumnWr>
      {
        !hideCheckbox && (
          <Checkbox
            checked={checked}
            onChange={onChange}
            indeterminate={indeterminate}
          />
        )
      }
      <ColumnLabel
        title={text}
        noPadding={hideCheckbox}
      >
        { text }
      </ColumnLabel>
    </ColumnWr>
  );
};
