import { action, flow, makeObservable, observable } from 'mobx';
import { HttpResponse } from '../../core/classes/HttpResponse';
import { applicationsService } from './applicationsService';

class ApplicationsStore {

  /**@type {HttpResponse<ApplicationResponse>}*/
  applications = new HttpResponse({ items: [], count: 0 });

  /**@type {HttpResponse<ApplicationsUserResponse>}*/
  applicationUser = new HttpResponse([]);

  /**@type {HttpResponse<ApplicationsUserResponse>}*/
  applicationsByUserId = new HttpResponse([]);

  /**@type {HttpResponse<ApplicationLanguagesResponse>}*/
  applicationLanguages = new HttpResponse([]);

  /**@type {HttpResponse<ApplicationLanguagesResponse>}*/
  applicationLanguagesFilters = new HttpResponse([]);

  /**@type {HttpResponse<ApplicationLanguagesResponse>}*/
  backofficeLanguages = new HttpResponse([]);

  constructor() {
    makeObservable(this, {
      applications: observable,
      applicationUser: observable,
      applicationsByUserId: observable,
      applicationLanguages: observable,
      applicationLanguagesFilters: observable,
      backofficeLanguages: observable,
      setApplications: action.bound,
      setApplicationUser: action.bound,
      setApplicationsByUserId: action.bound,
      setApplicationLanguages: action.bound,
      setBackofficeLanguages: action.bound,
      setApplicationLanguagesFilters: action.bound,
      updateApplicationLanguagesFromFilters: action.bound,
      resetApplicationLanguages: action.bound,
      resetApplicationLanguagesFilters: action.bound,
      resetApplicationUser: action.bound,
      getApplications: flow,
      getApplicationLanguages: flow,
    });
  }

  /**
   * @name setApplications
   * @param {HttpResponse<ApplicationResponse>} applications
   * @returns {void}
   */
  setApplications(applications) {
    this.applications = applications;
  }

  /**
   * @name setApplicationUser
   * @param {HttpResponse<ApplicationsUserResponse>} applicationUser
   * @returns {void}
   */
  setApplicationUser(applicationUser) {
    this.applicationUser = applicationUser;
  }

  /**
   * @name setApplicationsByUserId
   * @param {HttpResponse<ApplicationsUserResponse>} applicationUser
   * @returns {void}
   */
  setApplicationsByUserId(applicationUser) {
    this.applicationsByUserId = applicationUser;
  }

  /**
   * @name setApplicationLanguages
   * @param {number} applicationLanguage
   * @returns {void}
   */
  setApplicationLanguages(applicationLanguage) {
    this.applicationLanguages = applicationLanguage;
  }

  /**
   * @name setBackofficeLanguages
   * @param {HttpResponse<ApplicationLanguagesResponse>} data
   * @returns {void}
   */
  setBackofficeLanguages(data) {
    this.backofficeLanguages = data;
  }

  /**
   * @name updateApplicationlanguagesFromFilters
   * @returns {void}
   */
  updateApplicationLanguagesFromFilters() {
    this.applicationLanguages = this.applicationLanguages.fetched([...this.applicationLanguagesFilters.data]);
  }

  /**
   * @name resetApplicationLanguages
   * @returns {void}
   */
   resetApplicationLanguages() {
    this.applicationLanguages = this.applicationLanguages.fetched([]);
  }

  /**
   * @name resetApplicationLanguagesFilters
   * @returns {void}
   */
   resetApplicationLanguagesFilters() {
    this.applicationLanguagesFilters = this.applicationLanguagesFilters.fetched([]);
  }

  /**
   * @name resetApplicationUser
   * @returns {void}
   */
   resetApplicationUser() {
    this.applicationUser = this.applicationUser.fetched([]);
  }

  /**
   * @name setApplicationLanguagesFilters
   * @param {HttpResponse<ApplicationLanguagesResponse>} data
   * @returns {void}
   */
  setApplicationLanguagesFilters(data) {
    this.applicationLanguagesFilters = data;
  }

  /**
   * @name getApplications
   * @param {ApplicationQuery=} params
   * @returns {Generator<Promise<ApplicationResponse>, void, *>}
   */
  getApplications = function* (params) {
    this.setApplications(this.applications.fetching());
    const applications = yield applicationsService.getApplications(params);
    this.setApplications(this.applications.fetched(applications));
  };

  /**
   * @name getApplicationUser
   * @returns {Array<ApplicationsUserResponse>}
   */
  getApplicationUser = async function () {
    this.setApplicationUser(this.applicationUser.fetching());
    const data = await applicationsService.getApplicationsUser();
    this.setApplicationUser(this.applicationUser.fetched(data));
    return data;
  };

  /**
   * @name getApplicationsByUserId
   * @param {{userId: number}} params
   * @returns {<Promise<ApplicationsUserResponse>}
   */
  getApplicationsByUserId = async function (params) {
    this.setApplicationsByUserId(this.applicationsByUserId.fetching());
    const data = await applicationsService.getApplicationsUser(params);
    this.setApplicationsByUserId(this.applicationsByUserId.fetched(data));
    return data;
  };

  /**
   * @name getApplicationLanguages
   * @param {number} id
   * @returns {Array<ApplicationLanguagesResponse>}
   */
  getApplicationLanguages = async function* (id) {
    this.setApplicationLanguages(this.applicationLanguages.fetching());
    const data = await applicationsService.getApplicationLanguages(id);
    this.setApplicationLanguages(this.applicationLanguages.fetched(data));
    return data;
  };

  /**
   * @name getApplicationLanguages
   * @param {number} id
   * @returns {ApplicationsByIdResponse}
   */
  getBackofficeLanguages = async function (id) {
    this.setBackofficeLanguages(this.backofficeLanguages.fetching());
    const data = await applicationsService.getApplicationLanguagesV2(id);
    this.setBackofficeLanguages(this.backofficeLanguages.fetched(data));
    return data;
  };

  /**
   * @name getApplicationLanguages
   * @param {number} id
   * @returns {ApplicationsByIdResponse}
   */
  getApplicationLanguagesFilters = async function (id) {
    this.setApplicationLanguagesFilters(this.applicationLanguagesFilters.fetching());
    const data = await applicationsService.getApplicationLanguages(id);
    this.setApplicationLanguagesFilters(this.applicationLanguagesFilters.fetched(data));
    return data;
  };

  /**
   * @name addApplications
   * @param {ApplicationRequestBody} data
   * @returns {Promise<void>}
   */
  addApplications = (data) => {
    return applicationsService.addApplications(data);
  };

  /**
   * @name editApplications
   * @param {EditApplicationBody} data
   * @returns {Promise<void>}
   */
  editApplications = (data) => {
    return applicationsService.editApplications(data);
  };

  /**
   * @name addApplicationsUser
   * @param {ApplicationUserRequestBody} data
   * @returns {Promise<void>}
   */
  addApplicationsUser = (data) => {
    return applicationsService.addApplicationsUser(data);
  };

  /**
   * @name deleteApplication
   * @param {number} id
   * @returns {Promise<void>}
   */
  deleteApplication = (id) => {
    return applicationsService.deleteApplication(id);
  };

}

export const applicationsStore = new ApplicationsStore();
