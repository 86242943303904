import { httpClient } from '../httpClient/httpClient';
import {
  addLanguagesUrl,
  deleteLanguagesUrl,
  editLanguagesUrl,
  getLanguagesUrl, getLanguageUrl,
} from '../../core/constants/endpoints';
import { ApiVersion } from '../../core/enums/ApiVersion';
import { abortFetch } from '../../core/helpers/abortController';
import { ABORT_API_KEYS } from '../../core/constants/abortApiKeys';

class LanguagesService {

  /**
   * @name getLanguages
   * @param {LanguagesQuery} params
   * @param {ApiOption} option
   * @returns {Promise<LanguagesResponse>}
   */
  async getLanguages(params = {}, option = { version: ApiVersion.V1 }) {
    const signal = abortFetch(ABORT_API_KEYS.GetLanguages);
    return await httpClient.get(getLanguagesUrl(option), { params, signal });
  }

  /**
   * @name getLanguage
   * @param {string} id
   * @param {ApiOption} option
   * @returns {Promise<LanguageResponse>}
   */
  async getLanguage(id, option = {version: ApiVersion.V1}) {
    return await httpClient.get(getLanguageUrl(id, option));
  }

  /**
   * @name addLanguages
   * @param {LanguagesRequestBody} data
   * @param {ApiOption} option
   * @returns {Promise<void>}
   */
  async addLanguages(data = {}, option = { version: ApiVersion.V1 }) {
    return await httpClient.post(addLanguagesUrl(option), data);
  }

  /**
   * @name editLanguagesById
   * @param {string} id
   * @param {LanguagesRequestBody} data
   * @param {ApiOption} option
   * @returns {Promise<void>}
   */
  async editLanguage(id, data, option = { version: ApiVersion.V1 }) {
    return await httpClient.put(editLanguagesUrl(id, option), data);
  }

  /**
   *
   * @param {string} id
   * @param {ApiOption} option
   * @returns {Promise<void>}
   */
  async deleteLanguage(id, option = { version: ApiVersion.V1 }) {
    return await httpClient.delete(deleteLanguagesUrl(id, option));
  }
}

export const languagesService = new LanguagesService();
