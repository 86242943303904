import { FormType } from '../enums/FormType';

const inputType = {
  [FormType.Text]: (e, key) => ({ [key]: e.target?.value }),
  [FormType.Number]: (e, key) => ({ [key]: e.target.value === '' ? '' : Number(e.target.value) }),
  [FormType.RangeDate]: (e, key) => ({ [key]: e.map(libDate => new Date(libDate)) }),
  [FormType.SingleDate]: (e, key) => ({ [key]: e }),
  [FormType.Radio]: (e, key) => ({ [key]: e.target.checked }),
  [FormType.RadioValue]: (e, key) => ({ [key]: e.target.value }),
  [FormType.Checkbox]: (e, key) => ({ [key]: e.target.checked }),
  [FormType.Time]: (e, key) => ({ [key]: e.target.value }),
  [FormType.Dropdown]: (e, key) => ({ [key]: Array.isArray(e) ? e.map(item => item.value) : e?.value }),
  [FormType.Switcher]: (e, key) => ({ [key]: e.target.checked }),
  [FormType.File]: (e, key) => ({ [key]: e.value[0] ? { fileName: e.value[0].name, blob: e.value[0].blob } : null }),
  [FormType.Object]: (value, key) => ({ [key]: value }),
};

export const inputsValuePicker = (e, key = '', type) => {
  return inputType[type](e, key);
};
