import React from 'react';
import { KeyValue as CoreKeyValue } from '@geneui/components';

export const KeyValue = ({
  ...props
}) => {
  return (
    <CoreKeyValue
      appearance="vertical"
      {...props}
    />
  );
};
