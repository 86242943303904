import React from 'react';
import { Icon as CoreIcon } from '@geneui/components';

export const Icon = ({ ...props }) => {
  return (
    <CoreIcon
      {...props}
    />
  );
};
