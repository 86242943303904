import React, { Suspense, useLayoutEffect } from 'react';
import { Route, useRouteMatch, useLocation, useHistory } from 'react-router-dom';

import { hasPermission } from '../../core/helpers/permissionsHelper';
import PermissionGuard from '../../view/containers/permissionGuard';
import { Loader } from '../../view/components/shared';

const NestedRoutes = ({ routes }) => {

  const { path: basePath } = useRouteMatch();
  const { pathname } = useLocation();
  const history = useHistory();

  useLayoutEffect(() => {
    if (routes.length && basePath === pathname) {
      // first available page permission
      const firstAvailableRoute = routes.find(({ permissions = [] }) => hasPermission(permissions));
      // first available page path
      history.replace(`${basePath}${firstAvailableRoute?.path || ''}`);
    }
  }, []);

  return (
    <>
      {routes.map(({ path, component: Component, permissions }, index) => {
        return (
          <Suspense
            key={`${path}_${index}`}
            fallback={(
              <Loader isBusy type="spinner"/>
            )}
          >
            <Route path={`${basePath}${path}`}>
              <PermissionGuard requiredPermissions={permissions} isPage>
                <Component />
              </PermissionGuard>
            </Route>
          </Suspense>
        );
      })}
    </>
  );
};

export default NestedRoutes;
