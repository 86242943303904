import { httpClient } from '../httpClient/httpClient';
import { ApiVersion } from '../../core/enums/ApiVersion';
import {
  doWinUrl,
  doBetUrl,
  batchWinUrl,
  doRollbackUrl,
} from '../../core/constants/endpoints';

class BetsService {
  /**
   * @name {doBet}
   * @param {DoBetBody} data
   * @param {ApiOption=} option
   * @returns {Promise<void>}
   */
  async doBet(data, option = { version: ApiVersion.V1 }) {
    return await httpClient.post(doBetUrl(option), data);
  }

  /**
   * @name doRollback
   * @param {DoRollbackBody} data
   * @param {ApiOption=} option
   * @returns {Promise<void>}
   */
  async doRollback(data, option = { version: ApiVersion.V1 }) {
    return await httpClient.post(doRollbackUrl(option), data);
  }

  /**
   * @name doWin
   * @param {WinBody} data
   * @param {ApiOption=} option
   * @returns {Promise<void>}
   */
  async doWin(data, option = { version: ApiVersion.V1 }) {
    return await httpClient.post(doWinUrl(option), data);
  }

  /**
   * @name batchWin
   * @param {Array<WinBody>} data
   * @param  {ApiOption} option
   * @returns {Promise<void>}
   */
  async batchWin(data, option = { version: ApiVersion.V1 }) {
    return await httpClient.post(batchWinUrl(option), data);
  }
}

export const betsService = new BetsService();
