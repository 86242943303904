import React, { useCallback, useMemo } from 'react';
import { ExtendedInput } from '@geneui/components';

import { REGEX_CONSTANTS } from '../../../../core/constants/RegEx';
import { noop } from '../../../../core/constants/util';

export const NumberInput = ({
  max,
  value,
  onChange = noop,
  preventFloats = false,
  onInputValidationRegEx,
  labelAppearance = 'swap',
  preventNegatives = false,
  floatingPoints = Infinity,
  ...props
}) => {
  const numberRegEx = useMemo(() => {
    if (preventFloats && preventNegatives) {
        return REGEX_CONSTANTS.PositiveAndInteger;
    }
    if (preventFloats || (!preventFloats && floatingPoints === 0)) {
        return REGEX_CONSTANTS.Integer;
    }
    if (preventNegatives) {
        return REGEX_CONSTANTS.Positive;
    }
    return REGEX_CONSTANTS.AllNumbers;
  }, [preventFloats, preventNegatives, floatingPoints]);

  const handleChange = useCallback((event) => {
    const { value } = event.target;

    // onInput RegEx validation
    if (onInputValidationRegEx && !onInputValidationRegEx?.test(value)) return;

    // max value validation
    if (max && +value > max) return;

    // numberRegEx validation
    if (!numberRegEx.test(value)) return;
    onChange(event);
  }, [onInputValidationRegEx, max, onChange, numberRegEx]);

  return (
    <ExtendedInput
      type="number"
      value={value}
      onChange={handleChange}
      labelAppearance={labelAppearance}
      {...props}
    />
  );
};
