/**
 * @name getLineType
 * @returns {string}
 */
export const getLineType = () => {
  const paths = window.location.pathname.split('/');
  const linePath = paths[3];
  if (linePath) {
    return linePath;
  }
  return '';
};
