import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { CheckboxGroup } from '@geneui/components';

import {
  Content,
  PartnerList,
  PartnersHeader,
  PartnersHeaderBack,
  PartnersHeaderName,
  PartnersListDescription,
  PartnersListTitle,
  PlatformHeader,
  PlatformItem,
  PlatformItemIconContent,
  PlatformItemId,
  PlatformItemTitle,
  PlatformList,
  SearchContent,
  PartnerQuantity,
  ListWrapper
} from './partnerFilter.styled';
import { TranslationKeys } from '../../../../core/constants/translationKeys';
import { Icon, Search, TextInput, Divider, Button } from '../../shared';
import { useMobileSize } from '../../../../hooks/useMobileSize';
import useTranslation from '../../../../hooks/useTranslation';

export const PartnerPlatformFilter = observer(({
  partnersData,
  partnerListView,
  selectedPlatform,
  handlePlatformClick,
  handlePartnerSearch,
  selectedPlatformData,
  handlePlatformSearch,
  isDisabledResetButton,
  platformsPartnersData,
  partnerIdsWithPlatform,
  handleBackPlatformList,
  handlePartnerListChange,
  handleResetSelectedPlatform,
}) => {
  const translate = useTranslation();
  const { isMobile } = useMobileSize();

  const selectedPlatformId = Number(selectedPlatform);

  const value = partnerIdsWithPlatform[selectedPlatformId] || [];

  useEffect(() => {
    return () => {
      handleBackPlatformList();
    };
  } , []);

  const platformView = useMemo(() => {
    return partnerListView ? 'none' : 'block';
  }, [partnerListView])

  return (
    <Content mobile={isMobile}>
      {
        partnerListView && (
          <>
            <PartnersHeader onClick={handleBackPlatformList}>
              <PartnersHeaderBack>
                <Icon type="bc-icon-arrow-left"/>
              </PartnersHeaderBack>
              <PartnersHeaderName>
                {selectedPlatformData.name}
              </PartnersHeaderName>
            </PartnersHeader>
            <Divider type="horizontal" className="divider-item"/>
            <SearchContent>
              <TextInput
                placeholder={translate(TranslationKeys.SEARCH_PARTNER)}
                onChange={handlePartnerSearch}
              />
            </SearchContent>
            <Divider type="horizontal" className="divider-item"/>
            <PartnersListTitle>
              <PartnersListDescription>
                {translate(TranslationKeys.PARTNER_NAME)}
              </PartnersListDescription>
              <PartnersListDescription flexEnd id>
                {translate(TranslationKeys.ID)}
              </PartnersListDescription>
            </PartnersListTitle>
            <Divider type="horizontal" className="divider-item"/>
            <PartnerList mobile={isMobile}>
              <CheckboxGroup
                value={value}
                data={partnersData}
                className="checkbox-group"
                onChange={handlePartnerListChange}
              />
            </PartnerList>
          </>
        )
      }
      <ListWrapper display={platformView}>
        <PlatformHeader>
          <SearchContent>
            <Search
              onChange={handlePlatformSearch}
              placeholder={translate(TranslationKeys.SEARCH_PLATFORM)}
            />
          </SearchContent>
        </PlatformHeader>
        <Divider type="horizontal" className="divider-item"/>
        <PartnersListTitle>
          <PartnersListDescription id>
            {translate(`${TranslationKeys.ID}, ${translate(TranslationKeys.PLATFORM_NAME)}`)}
          </PartnersListDescription>
          <Button
            appearance='minimal'
            disabled={isDisabledResetButton}
            onClick={handleResetSelectedPlatform}
            text={translate(TranslationKeys.RESET_FILTERS)}
          />
        </PartnersListTitle>
        <Divider type="horizontal" className="divider-item"/>
        <PlatformList>
          {platformsPartnersData.map(({ id, name, partners }) => {
            return (
              <PlatformItem
                key={id}
                active={selectedPlatformId === id}
                onClick={() => handlePlatformClick(id)}
              >
                <PlatformItemId>{id}</PlatformItemId>
                <PlatformItemTitle>{name}</PlatformItemTitle>
                <PartnerQuantity>{partners.length}</PartnerQuantity>
                <PlatformItemIconContent>
                  <Icon type="bc-icon-arrow-right"/>
                </PlatformItemIconContent>
              </PlatformItem>
            );
          })}
        </PlatformList>
      </ListWrapper>
    </Content>
  );
});
