import styled, { css } from 'styled-components';

export const FilterHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 42px;
  max-width: ${(props) => props.mobile ? '155px' : '170px'};
  min-width: ${(props) => props.mobile ? '155px' : '170px'};
  padding: 4px 7px 4px 11px;
  border-radius: 10px;
  color: #ffffff;
  background-color: #3d3d5f;
  box-sizing: border-box;

  & .icon-down {
    font-size: 22px;
  }
`;

export const FilteredName = styled.span`
  font-weight: 600;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  text-overflow: ellipsis;
  margin-right: 5px;
  min-width: ${props => props.mobile ? '120px' : '140px'};
`;

export const PartnerName = styled.div`
  height: 14px;
  margin: 1px 4px 0 0;
  font-size: 10px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: rgba(255, 255, 255, 0.7);
`;

export const Content = styled.section`
  min-width: 310px;
  max-height: ${props => props.mobile ? 'auto' : '420px'};

  & .divider-item {
    width: 100%;
  }

  & .checkbox-group {
    flex-direction: column;
  }
`;

export const PlatformHeader = styled.header`
  padding-top: 15px;
`;

export const SearchContent = styled.div`
  padding: 0 14px;

  & .c-g-w-s-footer {
    display: none;
  }

  & .c-g-w-s-content {
    background-color: transparent;
  }

  & .input-element-back {
    background: #fafafa;
  }

  & .c-g-w-s-head {
    padding: 0;
  }

`;

export const PartnersListTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px;
  box-sizing: border-box;
`;

export const PartnersListDescription = styled.div`
  color: rgba(60, 64, 67, 0.5);
  font-size: 12px;
  margin-right: 3px;

  ${props => props.flexEnd && css`
    justify-content: flex-end;
    min-width: 24px;
    margin-right: 0;
  `}
`;

export const PlatformList = styled.section`
  max-height: 270px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-bottom: 15px;
`;

export const PlatformItem = styled.div`
  padding: 10px 14px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  transition-duration: .25s;
  border-radius: 4px;
  color: #3c4043;
  font-weight: 600;

  ${props => props.active && css`
    color: #1473E6;
  `}
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

export const PlatformItemTitle = styled.div`
  font-size: 14px;
  flex-grow: 1;
  padding-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PlatformItemId = styled.div`
  min-width: 32px;
  color: rgba(60, 64, 67, 0.7);
`;

export const PlatformItemIconContent = styled.div`
  min-width: 24px;
  color: #3c4043;
`;

export const PartnersHeader = styled.header`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 14px;
  box-sizing: border-box;
  margin-bottom: -10px;
  cursor: pointer;
  margin-top: 8px;
`;

export const PartnersHeaderBack = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
`;

export const PartnersHeaderName = styled.span`
  margin-left: 10px;
`;

export const PartnerList = styled.div`
  width: 100%;
  max-height: 160px;
  overflow: auto;
  position: relative;
  margin-bottom: 20px;
  padding-right: 18px;

  & .cha-ra-group .crs-holder {
    border-radius: 4px;
    box-sizing: border-box;
    padding: 10px 14px;
    transition-duration: .25s;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
    
    & .ellipsis-text {
      display: flex;
      width: 100%;
    }
    
  }
`;

export const PartnerQuantity = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  height: 20px;
  background: #1473E6;
  color: #ffffff;
  border-radius: 10px;
  font-size: 12px;
  padding: 2px;
`;

export const PartnerGroup = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const ListWrapper = styled.div`
  display: ${props => props.display};
`;
