import { action, flow, makeObservable, observable } from 'mobx';

import { downloadFileFromHttpResponse } from '../../core/helpers/fileDownloader';
import { HttpResponse } from '../../core/classes/HttpResponse';
import { userFreeBetsService } from './userFreeBetsService';

class UserFreeBetsStore {
  /** @type {HttpResponse<Array<any>>}*/
  userFreeBets = new HttpResponse({ items: [], count: 0 });

  /** @type {HttpResponse<Array<UserFreeBetStatusHistoryItem>>}*/
  userFreeBetStatusHistory = new HttpResponse([]);

  /** @type {HttpResponse<Array<UserFreeBetReportDetailsItem>>}*/
  userFreeBetReportDetails = new HttpResponse([]);

  constructor() {
    makeObservable(this, {
      userFreeBets: observable,
      userFreeBetStatusHistory: observable,
      userFreeBetReportDetails: observable,
      setUserFreeBets: action.bound,
      setUserFreeBetStatusHistory: action.bound,
      setUserFreeBetReportDetails: action.bound,
      getUserFreeBets: flow,
      getUserFreeBetStatusHistory: flow,
      getUserFreeBetReportDetails: flow,
    });
  }

  /**
   * @name setUserFreeBets
   * @param {HttpResponse<Array<any>>} userFreeBets
   * @returns {void}
   */
  setUserFreeBets = (userFreeBets) => {
    this.userFreeBets = userFreeBets;
  };

  /**
   * @name setUserFreeBetStatusHistory
   * @param {HttpResponse<Array<UserFreeBetStatusHistoryItem>>} data
   * @returns {void}
   */
  setUserFreeBetStatusHistory = (data) => {
    this.userFreeBetStatusHistory = data;
  };

  /**
   * @name setUserFreeBetReportDetails
   * @param {HttpResponse<Array<UserFreeBetReportDetailsItem>>} data
   * @returns {void}
   */
  setUserFreeBetReportDetails = (data) => {
    this.userFreeBetReportDetails = data;
  };

  /**
   * @name addUserFreeBets
   * @param {UserFreeBetsBody} data
   * @returns {Promise<void>}
   */
  addUserFreeBets = (data) => {
    return userFreeBetsService.addUserFreeBets(data);
  };

  /**
   * @name getUserFreeBets
   * @param {UserFreeBetsQuery=} params
   * @returns {Generator<Promise<Array<*>>, void, *>}
   */
  getUserFreeBets = function* (params) {
    const { concat, ...restParams } = params;
    this.setUserFreeBets(this.userFreeBets.fetching());
    const userFreeBets = yield userFreeBetsService.getUserFreeBets(restParams);
    const data = concat ? this.userFreeBets.concat(userFreeBets) : userFreeBets;
    this.setUserFreeBets(this.userFreeBets.fetched(data));
  }

  /**
   * @name exportUserFreeBetsReport
   * @param {UserFreeBetsQuery=} params
   * @return {Promise<{ok}>}
   */
  exportUserFreeBetsReport = async (params) => {
    const res = await userFreeBetsService.exportUserFreeBetsReport(params);
    if (!res.ok) {
      throw new Error('Something went wrong while exporting.');
    }
    try {
      downloadFileFromHttpResponse(res, 'financial-report.xlsx');
    } catch (error) {
      console.error(error);
    }
    return res;
  };

  /**
   * @name getUserFreeBetStatusHistory
   * @param {number} id
   * @returns {Generator<Promise<Array<UserFreeBetStatusHistoryItem>>, void, *>}
   */
  getUserFreeBetStatusHistory = function* (id) {
    this.setUserFreeBetStatusHistory(this.userFreeBetStatusHistory.fetching());
    const userFreeBets = yield userFreeBetsService.getUserFreeBetStatusHistory(id);
    this.setUserFreeBetStatusHistory(this.userFreeBetStatusHistory.fetched(userFreeBets));
  }

  /**
   * @name getUserFreeBetReportDetails
   * @param {number} id
   * @returns {Generator<Promise<Array<UserFreeBetStatusHistoryItem>>, void, *>}
   */
  getUserFreeBetReportDetails = function* (userFreeBetId) {
    this.setUserFreeBetReportDetails(this.userFreeBetReportDetails.fetching());
    const data = yield userFreeBetsService.getUserFreeBetReportDetails(userFreeBetId);
    this.setUserFreeBetReportDetails(this.userFreeBetReportDetails.fetched(data));
  }
}

export const userFreeBetsStore = new UserFreeBetsStore();
