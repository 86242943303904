import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { defaultPath } from '../router';
import { authStore } from '../../module';

const IsNotAuthLayer = observer(({ children }) => {
  const { isAuth } = authStore;
  return (
    <>
      {!isAuth ? children : <Redirect to={defaultPath}/>}
    </>
  );
});

export default withRouter(IsNotAuthLayer);

