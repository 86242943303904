import { useContext, useCallback } from 'react';

import { TranslationContext } from '../contexts';

/**
 * @name useTranslation
 * @returns {(function(*): (*|undefined))|*}
 */
const useTranslation = () => {
  const translations = useContext(TranslationContext);
  return useCallback(
    (key) => {
      if (translations) {
        return Object.keys(translations).length && translations[key] ? translations[key] : key;
      }
    },
    [translations],
  );
};

export default useTranslation;
