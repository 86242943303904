import React from 'react';
import { Empty as CoreEmpty } from '@geneui/components';

import { TranslationKeys as T } from '../../../../core/constants/translationKeys';
import useTranslation from '../../../../hooks/useTranslation';

export const Empty = ({
  title = T.NO_DATA_TO_DISPLAY,
  ...props
}) => {

  const translate = useTranslation();

  const titleTr = translate(title);

  return (
    <CoreEmpty
      title={titleTr}
      {...props}
    />
  );
};
