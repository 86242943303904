import { action, flow, makeObservable, observable } from 'mobx';

import { downloadFileFromHttpResponse } from '../../core/helpers/fileDownloader';
import { HttpResponse } from '../../core/classes/HttpResponse';
import { transactionsService } from './transactionsService';

class TransactionsStore {
  /** @type {HttpResponse<TransactionsReportsResponse>}*/
  reports = new HttpResponse({ items: [], count: 0 });

  /** @type {HttpResponse<TransactionsReportsResultsResponse>}*/
  resultsReports = new HttpResponse({ items: [], count: 0 });

  /** @type {HttpResponse<ResponseReportsDashboard>}*/
  dashboardReports = new HttpResponse({});

  /** @type {HttpResponse<Array<TransactionsReportsGame>}*/
  gamesReports = new HttpResponse([]);

  /** @type {HttpResponse<Array<TransactionsReportsGame>}*/
  gameLinesReports = new HttpResponse([]);

  /** @type {HttpResponse<Array<TransactionsReportsGame>}*/
  gamesReports = new HttpResponse([]);

  /** @type {HttpResponse<Array<ReportsFinancialsResult>>}*/
  financialsReports = new HttpResponse({ items: [], count: 0 });

  /** @type {HttpResponse<Array<ReportChartResult>>}*/
  chartReports = new HttpResponse({});

  /** @type {HttpResponse<Array<TopBetsResult>>}*/
  topBetsReports = new HttpResponse([]);

  /** @type {HttpResponse<Array<TopBetsResult>>}*/
  topBetsLoseReport = new HttpResponse([]);

  /** @type {HttpResponse<PartnerActivityResult>}*/
  partnerActivityReport = new HttpResponse({});

  /** @type {HttpResponse<number>}*/
  usersCountReport = new HttpResponse(0);

  playersReportByGameLine = new HttpResponse([]);

  partnerComparison = new HttpResponse({ items: [], count: 0 });

  partnerComparisonByGame = new HttpResponse({ items: [], count: 0 });

  financialInvoiceReportInfo = new HttpResponse(null);

  transactionsCurrencyReports = new HttpResponse({ items: [], count: 0 });

  gameComparisonReports = new HttpResponse({ items: [], count: 0 });

  constructor() {
    makeObservable(this, {
      reports: observable,
      resultsReports: observable,
      dashboardReports: observable,
      gamesReports: observable,
      gameLinesReports: observable,
      financialsReports: observable,
      chartReports: observable,
      topBetsReports: observable,
      partnerActivityReport: observable,
      topBetsLoseReport: observable,
      usersCountReport: observable,
      playersReportByGameLine: observable,
      partnerComparison: observable,
      partnerComparisonByGame: observable,
      financialInvoiceReportInfo: observable,
      transactionsCurrencyReports: observable,
      gameComparisonReports: observable,
      setReports: action.bound,
      setResultsReports: action.bound,
      setDashboardReports: action.bound,
      setGamesReports: action.bound,
      setGameLinesReports: action.bound,
      setFinancialsReports: action.bound,
      setChartReports: action.bound,
      setTopBetsReports: action.bound,
      setLoseTopBetsReports: action.bound,
      setPartnerActivityReports: action.bound,
      setUsersCountReport: action.bound,
      setPlayersReportByGameLine: action.bound,
      setPartnerComparison: action.bound,
      setPartnerComparisonByGame: action.bound,
      setFinancialInvoiceReportInfo: action.bound,
      setTransactionsCurrencyReports: action.bound,
      setGameComparisonReports: action.bound,
      getTransactionsReports: flow,
      getTransactionsResultsReports: flow,
      getTransactionsDashboardReports: flow,
      getTransactionsReportsGames: flow,
      getTransactionsReportsGameLines: flow,
      getTransactionsFinancialsReports: flow,
      getTransactionsChartReports: flow,
      getTransactionsTopBetsReports: flow,
      getTransactionsTopBetsLoseReports: flow,
      getTransactionsPartnerActivityReports: flow,
      getTransactionsUsersCountReport: flow,
      getPlayersReportByGameLine: flow,
      getPartnerComparisonReport: flow,
      getPartnerComparisonByGameReport: flow,
      getFinancialInvoiceReportInfo: flow,
      getTransactionsCurrencyReports: flow,
      getGameComparisonReports: flow,
    });
  }

  /**
   * @name setReports
   * @param {HttpResponse<TransactionsReportsResponse>} reports
   * @return {void}
   */
  setReports = (reports) => {
    this.reports = reports;
  };

  /**
   * @name setResultsReports
   * @param {HttpResponse<TransactionsReportsResultsResponse>} resultsReports
   * @return {void}
   */
  setResultsReports = (resultsReports) => {
    this.resultsReports = resultsReports;
  };

  /**
   * @name setDashboardReports
   * @param {HttpResponse<ResponseReportsDashboard>} dashboardReports
   * @return {void}
   */
  setDashboardReports = (dashboardReports) => {
    this.dashboardReports = dashboardReports;
  };

  /**
   * @name setGamesReports
   * @param {HttpResponse<Array<TransactionsReportsGame>>} data
   * @return {void}
   */
  setGamesReports = (data) => {
    this.gamesReports = data;
  };

  /**
   * @name setGameLinesReports
   * @param {HttpResponse<Array<TransactionsReportsGame>>} data
   * @return {void}
   */
  setGameLinesReports = (data) => {
    this.gameLinesReports = data;
  };

  /**
   * @name setFinancialsReports
   * @param {HttpResponse<ResponseReportsFinancials>} financialsReports
   * @return {void}
   */
  setFinancialsReports = (financialsReports) => {
    this.financialsReports = financialsReports;
  };

  /**
   * @name setChartReports
   * @param {HttpResponse<Array<ReportChartResult>>} chartReports
   * @return {void}
   */
  setChartReports = (chartReports) => {
    this.chartReports = chartReports;
  };

  /**
   * @name setTopBetsReports
   * @param {HttpResponse<Array<TopBetsResult>>} topBetsReports
   * @return {void}
   */
  setTopBetsReports = (topBetsReports) => {
    this.topBetsReports = topBetsReports;
  };

  /**
   * @name setLoseTopBetsReports
   * @param {HttpResponse<Array<TopBetsResult>>} topBetsLoseReport
   * @return {void}
   */
  setLoseTopBetsReports = (topBetsLoseReport) => {
    this.topBetsLoseReport = topBetsLoseReport;
  };

  /**
   * @name setPartnerActivityReports
   * @param {HttpResponse<PartnerActivityResult>} partnerActivityReport
   * @return {void}
   */
  setPartnerActivityReports = (partnerActivityReport) => {
    this.partnerActivityReport = partnerActivityReport;
  };

  setUsersCountReport = (usersCountReport) => {
    this.usersCountReport = usersCountReport;
  };

  /**
   * @name setPlayersReportByGameLine
   * @param { HttpResponse<PlayerTransactionsReportsByGameLineResponse> } playersReportByGameLine
   * @return {void}
   */
  setPlayersReportByGameLine = (playersReportByGameLine) => {
    this.playersReportByGameLine = playersReportByGameLine;
  }

  setPartnerComparison = (partnerComparison) => {
    this.partnerComparison = partnerComparison;
  }

  setPartnerComparisonByGame = (partnerComparisonByGame) => {
    this.partnerComparisonByGame = partnerComparisonByGame;
  }

  setFinancialInvoiceReportInfo = (data) => {
    this.financialInvoiceReportInfo = data;
  }

  setTransactionsCurrencyReports = (data) => {
    this.transactionsCurrencyReports = data;
  }

  setGameComparisonReports = (data) => {
    this.gameComparisonReports = data;
  }

  /**
   * @name getTransactionsReports
   * @param {TransactionsReportsParams} params
   * @return {Generator<Promise<TransactionsReportsResponse>, void, *>}
   */
  getTransactionsReports = function* (params) {
    const { concat, ...restParams } = params;
    this.setReports(this.reports.fetching());
    const report = yield transactionsService.getTransactionsReports(restParams);
    const data = concat ? this.reports.concat(report) : report;
    this.setReports(this.reports.fetched(data));
  };

  /**
   * @name getTransactionsResultsReports
   * @param {TransactionsReportsResultsParams} params
   * @return {Generator<Promise<TransactionsReportsResultsResponse>, void, *>}
   */
  getTransactionsResultsReports = function* (params) {
    this.setResultsReports(this.resultsReports.fetching());
    const data = yield transactionsService.getTransactionsReportsResults(params);
    if (data?.items.length) {
      data.items.sort((a, b) => new Date(b.requestDate) - new Date(a.requestDate));
    }
    this.setResultsReports(this.resultsReports.fetched(data));
  };

  /**
   * @name getTransactionsDashboardReports
   * @param {TransactionsReportsDashboardParams} params
   * @param {ApiOption=} option
   * @return {Generator<Promise<ResponseReportsDashboard>, void, *>}
   */
  getTransactionsDashboardReports = function* (params, apiOption) {
    this.setDashboardReports(this.dashboardReports.fetching());
    const data = yield transactionsService.getTransactionsReportsDashboard(params, apiOption);
    this.setDashboardReports(this.dashboardReports.fetched(data));
  };

  /**
   * @name getTransactionsReportsGameLines
   * @param {TransactionsReportsDashboardParams} params
   * @param {ApiOption=} option
   * @return {Generator<Promise<Array<TransactionsReportsGame>>, void, *>}
   */
  getTransactionsReportsGameLines = function* (params, apiOption) {
    this.setGameLinesReports(this.gameLinesReports.fetching());
    const data = yield transactionsService.getTransactionsReportsGameLines(params, apiOption);
    this.setGameLinesReports(this.gameLinesReports.fetched(data));
  };

  /**
   * @name getTransactionsReportsGames
   * @param {TransactionsReportsDashboardParams} params
   * @param {ApiOption=} option
   * @return {Generator<Promise<Array<TransactionsReportsGame>>, void, *>}
   */
  getTransactionsReportsGames = function* (params, apiOption) {
    this.setGamesReports(this.gamesReports.fetching());
    const data = yield transactionsService.getTransactionsReportsGames(params, apiOption);
    this.setGamesReports(this.gamesReports.fetched(data));
  };

  /**
   * @name getTransactionsFinancialsReports
   * @param {TransactionsReportsFinancialsParams} params
   * @param {ApiOption=} option
   * @return {Generator<Promise<ResponseReportsFinancials>, void, *>}
   */
  getTransactionsFinancialsReports = function* (params, apiOption) {
    const { concat, ...restParams } = params;
    this.setFinancialsReports(this.financialsReports.fetching());
    const report = yield transactionsService.getTransactionsReportsFinancials(restParams, apiOption);
    const data = concat ? this.financialsReports.concat(report) : report;
    this.setFinancialsReports(this.financialsReports.fetched(data));
  };

  /**
   * @name exportFinancialReport
   * @param {TransactionsReportsFinancialsParams} params
   * @param {ApiOption=} option
   * @return {Promise<{ok}>}
   */
  exportFinancialReport = async (params, apiOption) => {
    const res = await transactionsService.exportFinancialReport(params, apiOption);
    if (!res.ok) {
      throw new Error('Something went wrong while exporting.');
    }
    try {
      downloadFileFromHttpResponse(res, 'financial-report.xlsx');
    } catch (error) {
      console.error(error);
    }
    return res;
  };

  /**
   * @name generateFinancialInvoiceReport
   * @param {GenerateFinancialInvoiceReportData} data
   * @param {ApiOption} option
   * @returns {Promise<void>}
   */
  generateFinancialInvoiceReport = (data, apiOption) => {
    return transactionsService.generateFinancialInvoiceReport(data, apiOption);
  };

  /**
   * @name getFinancialInvoiceReportInfo
   * @return {Generator<Promise<GenerateFinancialInvoiceReportData>, void, *>}
   */
  getFinancialInvoiceReportInfo = function* () {
    this.setFinancialInvoiceReportInfo(this.financialInvoiceReportInfo.fetching());
    const data = yield transactionsService.getFinancialInvoiceReportInfo();
    this.setFinancialInvoiceReportInfo(this.financialInvoiceReportInfo.fetched(data));
  };

  /**
   * @name getTransactionsChartReports
   * @param {TransactionsReportsChartParams} params
   * @return {Generator<Promise<Array<ReportChartResult>>, void, *>}
   */
  getTransactionsChartReports = function* (params) {
    this.setChartReports(this.chartReports.fetching());
    const data = yield transactionsService.getTransactionsReportsChart(params);
    this.setChartReports(this.chartReports.fetched(data));
  };

  /**
   * @name getTransactionsTopBetsReports
   * @param {TransactionsReportsTopBetsParams} params
   * @param {ApiOption=} option
   * @return {Generator<Promise<Array<TopBetsResult>>, void, *>}
   */
  getTransactionsTopBetsReports = function* (params, option) {
    this.setTopBetsReports(this.topBetsReports.fetching());
    const data = yield transactionsService.getTransactionsReportsTopBets(params, option);
    this.setTopBetsReports(this.topBetsReports.fetched(data));
  };

  /**
   * @name getTransactionsTopBetsLoseReports
   * @param {TransactionsReportsTopBetsParams} params
   * * @param {ApiOption=} option
   * @return {Generator<Promise<Array<TopBetsResult>>, void, *>}
   */
  getTransactionsTopBetsLoseReports = function* (params, option) {
    this.setLoseTopBetsReports(this.topBetsLoseReport.fetching());
    const data = yield transactionsService.getTransactionsReportsTopBets({ ...params, reportLoseBets: true }, option);
    this.setLoseTopBetsReports(this.topBetsLoseReport.fetched(data));
  };

  /**
   * @name getTransactionsPartnerActivityReports
   * @param {TransactionsReportsPartnerActivityParams} params
   * @param {ApiOption=} option
   * @return {Generator<Promise<PartnerActivityResult>, void, *>}
   */
  getTransactionsPartnerActivityReports = function* (params, apiOption) {
    this.setPartnerActivityReports(this.partnerActivityReport.fetching());
    const data = yield transactionsService.getTransactionsReportsPartnerActivity(params, apiOption);
    this.setPartnerActivityReports(this.partnerActivityReport.fetched(data));
  };

  /**
   * @name getTransactionsUsersCountReport
   * @param {TransactionsReportsDashboardParams} params
   * @param {ApiOption=} option
   * @return {Generator<Promise<number>, void, *>}
   */
  getTransactionsUsersCountReport = function* (params, apiOption) {
    this.setUsersCountReport(this.usersCountReport.fetching());
    const data = yield transactionsService.getTransactionsReportsUsersCount(params, apiOption);
    this.setUsersCountReport(this.usersCountReport.fetched(data));
  };

  /**
   * @name getPlayersReportByGameLine
   * @param { PlayerTransactionsReportsByGameLineParams } params
   * @return {Generator<Promise<PlayerTransactionsReportsByGameLineResponse>, void, *>}
   */
  getPlayersReportByGameLine = function* (params) {
    this.setPlayersReportByGameLine(this.playersReportByGameLine.fetching());
    const data = yield transactionsService.getPlayersGameLineTransactionsReport(params);
    this.setPlayersReportByGameLine(this.playersReportByGameLine.fetched(data));
  };

  getPartnerComparisonReport = function* (params) {
    this.setPartnerComparison(this.partnerComparison.fetching());
    const data = yield transactionsService.getPartnerComparisonReport(params);
    this.setPartnerComparison(this.partnerComparison.fetched(data));
  }

  getPartnerComparisonByGameReport = function* (params) {
    this.setPartnerComparisonByGame(this.partnerComparisonByGame.fetching());
    const data = yield transactionsService.getPartnerComparisonByGameReport(params);
    this.setPartnerComparisonByGame(this.partnerComparisonByGame.fetched(data));
  }


  getTransactionsCurrencyReports = function* (params, apiOption) {
    this.setTransactionsCurrencyReports(this.transactionsCurrencyReports.fetching());
    const report = yield transactionsService.getTransactionsCurrencyReport(params, apiOption);
    this.setTransactionsCurrencyReports(this.transactionsCurrencyReports.fetched(report));
  };

  getGameComparisonReports = function* (params, apiOption) {
    this.setGameComparisonReports(this.gameComparisonReports.fetching());
    const report = yield transactionsService.getGameComparisonReport(params);
    this.setGameComparisonReports(this.gameComparisonReports.fetched(report));
  };

  resetPartnerComparisonByGameReport() {
    this.setPartnerComparisonByGame(this.partnerComparisonByGame.fetched({ items: [], count: 0 }));
  }

  /**
   * @name resetTransactionsChartReports
   * @returns {void}
   */
  resetTransactionsChartReports() {
    this.setTopBetsReports(this.topBetsReports.fetching(true));
  }
}

export const transactionsStore = new TransactionsStore();
