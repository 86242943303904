import React from 'react';
import { Tooltip as CoreTooltip } from '@geneui/components';

export const Tooltip = ({ ...props }) => {
  return (
    <CoreTooltip
      {...props}
    />
  );
};
