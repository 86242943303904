import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { combineQuery, parseQuery } from '../core/helpers/queryHelper';

export const useSharedParams = () => {
  const { search } = useLocation();

  const searchData = useMemo(() => {
    const { platformIds, partnerIds } = parseQuery(search);

    return combineQuery({
      platformIds,
      partnerIds,
    });

  }, [search]);

  return {
    search: searchData,
  };
};