import React from 'react';
import styled from 'styled-components';

import { TranslationKeys } from '../../../../../core/constants/translationKeys';
import useTranslation from '../../../../../hooks/useTranslation';
import { Icon, Tooltip } from '../..';

const TitleActionsWr = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
  & .refresh-btn {
    cursor: pointer;
    color: #1473e6;
  }
`;

const TableTitleActions = ({
  titleActions,
  onRefreshClick
}) => {

  const translate = useTranslation();

  return (
    <TitleActionsWr>
      { titleActions }
      { !!onRefreshClick && (
        <Tooltip title={translate(TranslationKeys.REFRESH)}>
          <Icon
            type="bc-icon-refresh"
            className="refresh-btn"
            onClick={onRefreshClick}
          />
        </Tooltip>
      )}
    </TitleActionsWr>
  );
};

export default TableTitleActions;
