import React, { Suspense, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Toaster } from '@geneui/components';
import styled, { css } from 'styled-components';
import { observer } from 'mobx-react-lite';

import Navigation from '../components/navigation/navigation';
import DefaultRoutes from '../../routing/defaultRoutes/DefaultRoutes';
import Header from '../components/header';
import CreatePlatformsModal from '../components/createPlatformsModal';
import CreatePartnerModal from '../components/createPartnerModal';
import { useMobileSize } from '../../hooks/useMobileSize';
import MobileNavigationWrapper from '../components/mobileNavigationWrapper';
import ResetPasswordModal from '../modals/resetPassword';
import { modalStore } from '../../module/popUpModule';
import UserSettings from '../components/userSettings';
import { partnerStore } from '../../module';
import { PERMISSIONS } from '../../core/constants/permissions';
import { hasPermission } from '../../core/helpers/permissionsHelper';

const MainContent = styled.section`
  grid-area: main;
  position: relative;
  overflow-x: hidden;
  ${props => props.isMobile && css`
    margin-bottom: 53px;
  `}
`;

const App = observer(({
  ...props
}) => {

  const {
    resetPasswordModal: { isVisible: resetPasswordModalVisible },
    settingsModal: { isVisible: settingsModalVisible }, setSettingsModal,
    createPlatformModal: { isVisible: createPlatformModalVisible }
  } = modalStore;

  useEffect(() => {
    if (hasPermission([PERMISSIONS.ViewPartnerSettings])) {
      partnerStore.getAllPartners();
    }
  }, []);

  const { isMobile } = useMobileSize();

  return (
    <>
      <Header/>
      <Suspense fallback={<p>Loading...</p>}>
        <MainContent isMobile={isMobile} id="main-scroll">
          {
            isMobile && (
              <Navigation/>
            )
          }
          <Toaster/>
          <DefaultRoutes {...props}/>
        </MainContent>
        { isMobile && <MobileNavigationWrapper/> }
        { createPlatformModalVisible && <CreatePlatformsModal/> }
        <CreatePartnerModal/>
        { resetPasswordModalVisible && <ResetPasswordModal /> }
        { settingsModalVisible && <UserSettings closeModal={setSettingsModal} /> }
        
      </Suspense>
    </>
  );
});

export default withRouter(App);
