import React from 'react';
import { BusyLoader } from '@geneui/components';

export const Loader = ({
  ...props
}) => {
  return (
    <BusyLoader
      {...props}
    />
  );
};
