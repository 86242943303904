import React from 'react';

const SCROLL_INSURANCE = 500;
let lastScrollTopForDown = 0;

export const useInfiniteScroll = (scrollInsurance = SCROLL_INSURANCE) => {

  const onInfiniteScroll = (event, callback, conditions)=> {
    const { scrollTop, scrollHeight, offsetHeight } = event.target;
    if (scrollHeight - offsetHeight - scrollTop < scrollInsurance) {
      // scrolling last ${scrollInsurance} pixels
      if (lastScrollTopForDown < scrollTop) {
        // scrolling down
        if (conditions.every(condition => condition)) {
          // if all conditions true
          callback();
        }
      }
    }
    lastScrollTopForDown = scrollTop;
  };

  return { onInfiniteScroll };

};
