export const AllMarketTypes = {
  Red_2: 0,
  Black_2: 1,
  Number_2: 2,
  Hi_2: 3,
  Red_3: 4,
  Black_3: 5,
  Number_3: 6,
  Hi_3: 7,
  Lo_3: 8,
  Red_4: 9,
  Black_4: 10,
  Number_4: 11,
  Hi_4: 12,
  Lo_4: 13,
  Red_5: 14,
  Black_5: 15,
  Number_5: 16,
  Hi_5: 17,
  Lo_5: 18,
  Red_6: 19,
  Black_6: 20,
  Number_6: 21,
  Hi_6: 22,
  Lo_6: 23,
  Red_7: 24,
  Black_7: 25,
  Number_7: 26,
  Hi_7: 27,
  Lo_7: 28,
  Red_8: 29,
  Black_8: 30,
  Number_8: 31,
  Hi_8: 32,
  Lo_8: 33,
  Red_9: 34,
  Black_9: 35,
  Number_9: 36,
  Hi_9: 37,
  Lo_9: 38,
  Red_J: 39,
  Black_J: 40,
  J: 41,
  Hi_J: 42,
  Lo_J: 43,
  Red_Q: 44,
  Black_Q: 45,
  Q: 46,
  Hi_Q: 47,
  Lo_Q: 48,
  Red_K: 49,
  Black_K: 50,
  K: 51,
  Hi_K: 52,
  Lo_K: 53,
  Red_A: 54,
  Black_A: 55,
  A: 56,
  Lo_A: 57,
  Red: 58,
  Black: 59,
  Numbers_2_to_9: 60,
  Joker: 61,
  J_Q_K_A: 62,
  J_Q: 63,
  K_A: 64,
};

export const AllMarketTypesReverse = {
  0: 'Red_2',
  1: 'Black_2',
  2: 'Number_2',
  3: 'Hi_2',
  4: 'Red_3',
  5: 'Black_3',
  6: 'Number_3',
  7: 'Hi_3',
  8: 'Lo_3',
  9: 'Red_4',
  10: 'Black_4',
  11: 'Number_4',
  12: 'Hi_4',
  13: 'Lo_4',
  14: 'Red_5',
  15: 'Black_5',
  16: 'Number_5',
  17: 'Hi_5',
  18: 'Lo_5',
  19: 'Red_6',
  20: 'Black_6',
  21: 'Number_6',
  22: 'Hi_6',
  23: 'Lo_6',
  24: 'Red_7',
  25: 'Black_7',
  26: 'Number_7',
  27: 'Hi_7',
  28: 'Lo_7',
  29: 'Red_8',
  30: 'Black_8',
  31: 'Number_8',
  32: 'Hi_8',
  33: 'Lo_8',
  34: 'Red_9',
  35: 'Black_9',
  36: 'Number_9',
  37: 'Hi_9',
  38: 'Lo_9',
  39: 'Red_J',
  40: 'Black_J',
  41: 'J',
  42: 'Hi_J',
  43: 'Lo_J',
  44: 'Red_Q',
  45: 'Black_Q',
  46: 'Q',
  47: 'Hi_Q',
  48: 'Lo_Q',
  49: 'Red_K',
  50: 'Black_K',
  51: 'K',
  52: 'Hi_K',
  53: 'Lo_K',
  54: 'Red_A',
  55: 'Black_A',
  56: 'A',
  57: 'Lo_A',
  58: 'Red',
  59: 'Black',
  60: 'Numbers_2_to_9',
  61: 'Joker',
  62: 'J_Q_K_A',
  63: 'J_Q',
  64: 'K_A'
};
