import React, { useCallback, useState } from 'react';
import { Col, Paper, Row, Title } from '@geneui/components';
import styled, { css } from 'styled-components';
import { observer } from 'mobx-react-lite';

import useTranslation from '../../../hooks/useTranslation';
import SizingContent from './modalContent';
import { settingsStore } from '../settings';
import { TranslationKeys } from '../../../core/constants/translationKeys';
import { useMobileSize } from '../../../hooks/useMobileSize';
import { translationStore } from '../../../module';
import { Switcher, Modal, ModalFooter } from '../shared';

const ModalHeader = styled.div`
  color: #727272;

  ${props => props.mobile && css`
    & .modal-body {
      padding-top: 0.5rem !important;
    }
  `}
`;

const UserSettings = observer(({ closeModal }) => {

  const translate = useTranslation();
  const { isMobile } = useMobileSize();
  const [viewMode, setViewMode] = useState(settingsStore.viewMode);
  const [aliasMode, setAliasMode] = useState(translationStore.aliasMode);

  const handleChange = useCallback(value => {
    setViewMode(value);
  }, []);

  const handleSave = useCallback(() => {
    settingsStore.setViewMode(viewMode);
    translationStore.setAliasMode(aliasMode);
    closeModal({ isVisible: false });
  }, [viewMode, aliasMode, closeModal]);

  const handleCancel = useCallback(() => {
    closeModal({ isVisible: false });
  }, []);

  return (
    <ModalHeader mobile={isMobile}>
      <Modal
        onCancel={handleCancel}
        title={translate(TranslationKeys.SETTINGS)}
        position={isMobile ? 'bottom' : 'top'}
        style={{ padding: isMobile ? 0 : 'auto' }}
        appearance={isMobile ? 'default' : 'compact'}
        visible
        width={860}
        closable={false}
        footer={(
          <ModalFooter
            onCancel={handleCancel}
            onSave={handleSave}
          />
        )}
      >
        {
          !isMobile && <Title
            text={translate(TranslationKeys.VIEW_MODE)}
            color="default"
            withLine
          />
        }
        <Paper
          padding={isMobile ? 0 : '12px 0'}
          alignItems="center"
          paperDirection="column"
        >
          <SizingContent
            viewMode={viewMode}
            handleChange={handleChange}
          />
        </Paper>
        {/* other settings below */}

        <>
          <Title
            text={translate(TranslationKeys.OTHER)}
            color="default"
            withLine
          />
          <Paper
            padding={isMobile ? 0 : '12px 0'}
          >
            <Row span={1}>
              <Col size={1}>
                <Switcher
                  checked={aliasMode}
                  label={translate(TranslationKeys.VIEW_ALIAS)}
                  onChange={({ target: { checked } }) => setAliasMode(checked)}
                />
              </Col>
            </Row>
          </Paper>
        </>
      </Modal>
    </ModalHeader>
  );
});

export default UserSettings;
