import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { TranslationKeys } from '../../../../../core/constants/translationKeys';
import { NotificationType } from '../../../../../core/enums/NotificationType';
import { notificationStore } from '../../../../../module';
import useTranslation from '../../../../../hooks/useTranslation';
import NewPartnerNotification from './new-partner-notification';

const Content = styled.section`
  height: auto;
  padding: 10px 14px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 600;
`;

const Header = styled.header`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const Title = styled.span`
  min-width: 78px;
  height: 25px;
  color: #FFFFFF;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  background-color: #7dc681;
`;

const DateContent = styled.span`
  color: rgba(60, 64, 67, 0.7);
`;

const DescriptionContent = styled.div`
  width: 100%;
  color: #FFFFFF;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
`;

const Description = styled.span`
  width: 100%;
  font-weight: bold;
  color: #3c4043;
`;

const NotificationDot = styled.span`
  margin: 8px 0 12px 5px;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: #1473e6;
  cursor: pointer;
`;

const NotificationDivider = styled.div`
  width: 330px;
  border-top: 1px solid #E6E6E6;
  margin: 10px 0 0 10px;
`;

const NotificationItem = ({
  notification: { id, isRead, notification: { date, description, type, data } },
  currentCount,
  showDivider,
}) => {

  const translate = useTranslation();

  const handleClick = useCallback(() => {
    if (!isRead) {
      notificationStore.readNotification(id).then(() => {
        notificationStore.getNotifications({ skip: 0, take: currentCount });
      });
    }
  }, [currentCount]);

  const notificationsContent = useMemo(() => {
    switch (type) {
      case NotificationType.NewPartner:
        return <NewPartnerNotification data={data} />
    
      default:
        return null;
    }
  }, []);

  return (
    <Content onClick={handleClick}>
      <Header>
        <Title>
          {translate(TranslationKeys.PARTNER)}
        </Title>
        <DateContent>
          {moment(moment.utc(date).toDate()).format('YYYY-MM-DD HH:mm:ss')}
        </DateContent>
      </Header>
      <DescriptionContent>
        <Description>
          {description}
        </Description>
        {!isRead && <NotificationDot/>}
      </DescriptionContent>

      { notificationsContent }

      {showDivider && (
        <NotificationDivider/>
      )}
    </Content>
  );
};

export default NotificationItem;
