import { httpClient, DEFAULT_HEADERS } from '../httpClient/httpClient';
import { ApiVersion } from '../../core/enums/ApiVersion';
import {
  getUserFreeBetsUrl,
  addUserFreeBetsUrl,
  exportUserFreeBetsReportUrl,
  getUserFreeBetStatusHistoryUrl,
  getUserFreeBetReportDetailsUrl,
} from '../../core/constants/endpoints';
import { abortFetch } from '../../core/helpers/abortController';
import { ABORT_API_KEYS } from '../../core/constants/abortApiKeys';
import { combineQuery } from '../../core/helpers/queryHelper';

class UserFreeBetsService {
  /**
   * @name getUserFreeBets
   * @param {UserFreeBetsQuery} params
   * @param {ApiOption=} option
   * @returns {Promise<Array<any>>}
   */
  async getUserFreeBets(params, option = { version: ApiVersion.V1 }) {
    const signal = abortFetch(ABORT_API_KEYS.GetUserFreeBets);
    return await httpClient.get(getUserFreeBetsUrl(option), {
      params,
      signal,
    });
  }

  /**
   * @name exportUserFreeBetsReport
   * @param {UserFreeBetsQuery=} params
   * @param {ApiOption=} option
   * @return Excel file
   */
  async exportUserFreeBetsReport(params, option = { version: ApiVersion.V1 }) {
    // axios does not support ReadableStream, so I use Fetch API to get excel file Stream. 
    return await fetch(`${exportUserFreeBetsReportUrl(option)}${combineQuery(params)}`, {
      credentials: 'include',
      headers: {
        ...DEFAULT_HEADERS
      }
    });
  }

  /**
   * @name getUserFreeBetStatusHistory
   * @param {id} number
   * @param {ApiOption=} option
   * @returns {Promise<Array<UserFreeBetStatusHistoryItem>>}
   */
  async getUserFreeBetStatusHistory(id, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getUserFreeBetStatusHistoryUrl(id, option));
  }

  /**
   * @name getUserFreeBetReportDetails
   * @param {userFreeBetId} number
   * @param {ApiOption=} option
   * @returns {Promise<Array<UserFreeBetReportDetailsItem>>}
   */
  async getUserFreeBetReportDetails(userFreeBetId, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getUserFreeBetReportDetailsUrl(userFreeBetId, option));
  }

  /**
   * @name addUserFreeBets
   * @param {UserFreeBetsBody} data
   * @param {ApiOption=} option
   * @returns {Promise<void>}
   */
  async addUserFreeBets(data, option = { version: ApiVersion.V1 }) {
    return await httpClient.post(addUserFreeBetsUrl(option), data);
  }
}

export const userFreeBetsService = new UserFreeBetsService();
