import React from 'react';
import { Redirect } from 'react-router-dom';
import { translateText } from '../view/components/translation';
import { TranslationKeys } from '../core/constants/translationKeys';
import { PERMISSIONS } from '../core/constants/permissions';
import { lazyWithRetry } from '../core/helpers/lazyWithRetry';

const PlatformsRouters = lazyWithRetry(() => import('../view/pages/platform/platforms.route'));
const ReportsRoutes = lazyWithRetry(() => import('../view/pages/reports/reports.route'));
const TranslationTool = lazyWithRetry(() => import('../view/pages/translation-tool'));
const BonusRoutes = lazyWithRetry(() => import('../view/pages/bonus/bonus.route'));
const AdminRoutes = lazyWithRetry(() => import('../view/pages/admin/admin.route'));
const SliderManagerRoutes = lazyWithRetry(() => import('../view/pages/slider-manager/slider-manager.route'));
const PGTournamentRoutes = lazyWithRetry(() => import('../view/pages/pg-tournament/pg-tournament.route'));
const Dashboard = lazyWithRetry(() => import('../view/pages/dashboard'));
const PGLobbyRoutes = lazyWithRetry(() => import('../view/pages/pg-lobby/pg-lobby.route'));

const rootRoutes = [
  {
    path: '/dashboard',
    name: translateText(TranslationKeys.DASHBOARD),
    component: Dashboard,
    permissions: [PERMISSIONS.ViewDashboard]
  },
  {
    path: '/platform',
    name: translateText(TranslationKeys.PARTNER),
    component: PlatformsRouters,
  },
  {
    path: '/reports',
    name: translateText(TranslationKeys.REPORTS),
    component: ReportsRoutes,
  },
  {
    path: '/admin',
    name: translateText(TranslationKeys.ADMIN),
    component: AdminRoutes,
  },
  {
    path: '/bonus',
    name: translateText(TranslationKeys.BONUS),
    component: BonusRoutes,
  },
  {
    path: '/translation-tool',
    name: translateText(TranslationKeys.TRANSLATION_MANAGER),
    component: TranslationTool,
  },
  {
    path: '/slider-manager',
    name: translateText(TranslationKeys.SLIDER_MANAGER),
    component: SliderManagerRoutes,
  },
  {
    path: '/pg-tournament',
    name: translateText(TranslationKeys.SLIDER_MANAGER),
    component: PGTournamentRoutes,
  },
  {
    path: '/pg-lobby',
    name: translateText(TranslationKeys.PG_LOBBY),
    component: PGLobbyRoutes,
  },
  {
    path: '/',
    component: () => <Redirect to="/dashboard"/>,
    exact: true,
  },
];

export const defaultPath = '/dashboard';

export default rootRoutes;
