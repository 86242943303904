import React from 'react';
import { Paper } from '@geneui/components';
import styled from 'styled-components';

import { Section, CopyToClipboard } from '..';

const Pre = styled.pre`
  max-width: 300px;
  overflow: auto;
`;

export const JSONSection = ({
  json,
  title,
  hasCopy = true
}) => {
  return (
    <div>
      <Section text={title}>
        <Pre>{ json }</Pre>
        {
          hasCopy && (
            <Paper justifyContent="paper-end">
              <CopyToClipboard text={json} />
            </Paper>
          )
        }
      </Section>
    </div>
  );
};
