import React from 'react';
import styled from 'styled-components';

import { Search } from '../../shared';

const Content = styled.section`
  padding: 0 15px;
  box-sizing: border-box;
`;

const ContainerSearch = ({ onChange }) => {
  return (
    <Content>
      <Search
        onChange={onChange}
      />
    </Content>
  );
};

export default ContainerSearch;
