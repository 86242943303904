import React, { useCallback, useMemo, useRef, useState } from 'react';
import { MobileNavigation } from '@geneui/components';

import { TranslationKeys } from '../../../core/constants/translationKeys';
import useTranslation from '../../../hooks/useTranslation';
import MobileFilter from './mobileFilter';
import MobileProfile from './mobileProfile';
import MobilePopoverFooter from '../mobilePopoverFooter';
import Notifications from '../header/notifications';
import { hasPermission } from '../../../core/helpers/permissionsHelper';
import { PERMISSIONS } from '../../../core/constants/permissions';
import { observer } from 'mobx-react-lite';
import { modalStore } from '../../../module/popUpModule';
import CurrencyAndLanguageMobileFooter from '../mobilePopoverFooter/currencyAndLanguageMobileFooter';
import { Popover } from '../shared';
import { notificationStore } from '../../../module';
import { MAX_BADGE_COUNT } from '../../../core/constants/util';
import useOutSideClick from '../../../hooks/useOutSideClick';

const popoverContentKeys = {
  filter: 'filter',
  profile: 'profile',
  notifications: 'notifications',
};

export const footerTypes = {
   default: "default",
   currency: "currency",
   language: "language"
 };

const MobileNavigationWrapper = observer(() => {

  const { unReadNotificationsCount } = notificationStore;

  const ref = useRef();
  const translate = useTranslation();
  const [currentFooter, setCurrentFooter] = useState({ type: footerTypes.default, saveCallBack: null });

  useOutSideClick(ref, () => {
    closePopover();
  }, 'popover-content');

  const navList = useMemo(() => ([
    {
      title: translate(TranslationKeys.FILTERS),
      slug: 'filters',
      icon: 'bc-icon-filter-block',
      onClick: () => modalStore.setMobilePopover(popoverContentKeys.filter),
      disabled: !modalStore.filtersChildren,
      canSee: true,
    },
    {
      title: translate(TranslationKeys.NOTIFICATION),
      slug: 'notification',
      icon: 'bc-icon-send-push-notification-block',
      badge: {
        count: unReadNotificationsCount || null,
        maxCount: MAX_BADGE_COUNT,
        position: 'right'
      },
      canSee: hasPermission([PERMISSIONS.ViewNotifications]),
      onClick: () => modalStore.setMobilePopover(popoverContentKeys.notifications),
    },
    {
      title: translate(TranslationKeys.PROFILE),
      slug: 'profile',
      icon: 'bc-icon-user',
      onClick: () => modalStore.setMobilePopover(popoverContentKeys.profile),
      canSee: true,
    },
  ]), [modalStore.filtersChildren, unReadNotificationsCount]);
  const visibleNavList = useMemo(() => navList.filter(item => item.canSee), [navList]);

  const closePopover = useCallback(() => {
    handleSetCurrentFooter({ type: footerTypes.default, saveCallBack: null })
    modalStore.setMobilePopover(null);
  }, []);

  const handleNavigationItemClick = useCallback((item) => {
    if (item.onClick) {
      item.onClick();
    }
  }, []);

  const handleSetCurrentFooter = useCallback((current) => {
    setCurrentFooter(current);
  }, []);

  const handleOnBack = useCallback(() => {
    setCurrentFooter({ type: footerTypes.default, saveCallBack: null });
  }, []);

  const handleCurrencyAndLanguageSave = useCallback((...args) => {
    currentFooter.saveCallBack(args);

    // close popover then open it again
    const closePromise = Promise.resolve();
    closePromise
      .then(closePopover)
      .then(() => {
        modalStore.setMobilePopover(popoverContentKeys.profile);
      });
  }, [currentFooter, closePopover]);

  const footer = useMemo(() => {
    switch (currentFooter.type) {
      case footerTypes.default:
        return <MobilePopoverFooter onCancel={closePopover} cancelText={TranslationKeys.CANCEL} />;
      case footerTypes.currency:
      case footerTypes.language:
        return <CurrencyAndLanguageMobileFooter onCancel={closePopover} onSave={handleCurrencyAndLanguageSave} />;
      default:
        return <MobilePopoverFooter onCancel={closePopover} cancelText={TranslationKeys.CANCEL} />;
    }
  }, [currentFooter, closePopover, handleCurrencyAndLanguageSave]);

  const popoverContents = useMemo(() => ({
    [popoverContentKeys.filter]: <MobileFilter closePopover={closePopover} />,
    [popoverContentKeys.profile]: <MobileProfile handleOnBack={handleOnBack} handleSetCurrentFooter={handleSetCurrentFooter} closePopover={closePopover} />,
    [popoverContentKeys.notifications]: <Notifications />,
  }), []);

  const popoverFooter = useMemo(() => ({
    [popoverContentKeys.filter]: null,
    [popoverContentKeys.notifications]: <MobilePopoverFooter onCancel={closePopover} cancelText={TranslationKeys.CANCEL} />,
    [popoverContentKeys.profile]: footer,
  }), [footer]);

  return (
    <Popover
      align="end"
      contentRef={ref}
      isOpen={!!modalStore.mobilePopover}
      Content={popoverContents[modalStore.mobilePopover]}
      Footer={popoverFooter[modalStore.mobilePopover]}
    >
      <MobileNavigation
        list={visibleNavList}
        onChange={handleNavigationItemClick}
      />
    </Popover>
  );
});

export default MobileNavigationWrapper;
