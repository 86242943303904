import React from 'react';
import styled from 'styled-components';

import { TranslationKeys as T } from '../../../../core/constants/translationKeys';
import { noop }  from '../../../../core/constants/util';
import useTranslation from '../../../../hooks/useTranslation';
import { hexToRGBA } from '../../../../core/helpers/hexToRgba';
import { Icon, Modal, ModalFooter } from '..';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
`;

export const DescriptionContent = styled.span`
  border: none;
  padding: 0 20px;
  font-weight: bold;
  font-size: 12px;
`;

export const IconWrapper = styled.div`
  display: flex;
  min-width: 64px;
  height: 64px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
`;

export const DialogModal = ({
  icon,
  iconColor,
  title = "",
  message = "",
  onCancel,
  onConfirm = noop,
  actionLoading = false,
}) => {

  const translate = useTranslation();

  return (
    <Modal
      onCancel={onCancel}
      title={translate(title)}
      position="top"
      visible
      width={500}
      footer={
        <ModalFooter
          saveColor="confirm"
          saveText={T.CONFIRM}
          onSave={onConfirm}
          onCancel={onCancel}
          saveDisabled={actionLoading}
          actionLoading={actionLoading}
        />
      }
    >
      <ModalContent>
        <IconWrapper
          style={{ background: hexToRGBA(iconColor, .08) }}
        >
          <Icon
            type={icon}
            style={{ fontSize: "44px", color: iconColor }}
          />
        </IconWrapper>

        <DescriptionContent>
          {
            message
          }
        </DescriptionContent>
      </ModalContent>
    </Modal>
  );
};
