import React from 'react';
import styled from 'styled-components';

const TextWithDotWr = styled.span`
  display: flex;
  align-items: center;
`;

const TextDot = styled.span`
  display: block;
  width: 6px;
  height: 6px;
  margin-right: 10px;
  border-radius: 50%;
  background: ${props => props.color};
`;

export const TextWithDot = ({
  text,
  color = '#000',
}) => {
  return (
    <TextWithDotWr title={text}>
      <TextDot color={color}></TextDot>
      { text }
    </TextWithDotWr>
  );
};
