import { TranslationKeys as T } from '../constants/translationKeys';

export const AuthType = {
  Cookie: 0,
  Bearer: 1
};

export const AuthTypeNames = {
  [AuthType.Cookie]: T.COOKIE,
  [AuthType.Bearer]: T.BEARER,
};


export const AuthTypeDropdownData = [
  {
    id: AuthType.Cookie,
    name: AuthTypeNames[AuthType.Cookie]
  },
  {
    id: AuthType.Bearer,
    name: AuthTypeNames[AuthType.Bearer]
  },
];
