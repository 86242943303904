import { httpClient, httpClientV2 } from '../httpClient/httpClient';
import { ApiVersion } from '../../core/enums/ApiVersion';
import {
  addApplicationsUrl,
  getApplicationsUrl,
  editApplicationsUrl,
  addApplicationsUserUrl,
  getApplicationsUserUrl,
  getApplicationLanguagesdUrl,
  deleteApplicationUrl,
} from '../../core/constants/endpoints';

class ApplicationsService {

  /**
   * @name getApplications
   * @param {ApplicationQuery} params
   * @param {ApiOption=} option
   * @returns {Promise<ApplicationResponse>}
   */
  async getApplications(params = {}, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getApplicationsUrl(option), { params });
  }

  /**
   * @name addApplications
   * @param {ApplicationRequestBody} data
   * @param {ApiOption=} option
   * @returns {Promise<void>}
   */
  async addApplications(data = {}, option = { version: ApiVersion.V1 }) {
    return await httpClient.post(addApplicationsUrl(option), data);
  }

  /**
   * @name editApplications
   * @param {EditApplicationBody} data
   * @param option
   * @returns {Promise<void>}
   */
  async editApplications(data = {}, option = { version: ApiVersion.V1 }) {
    return await httpClient.put(editApplicationsUrl(option), data);
  }

  /**
   *@name addApplicationsUser
   * @param {ApplicationUserRequestBody} data
   * @param {ApiOption} option
   * @returns {Promise<void>}
   */
  async addApplicationsUser(data = {}, option = { version: ApiVersion.V1 }) {
    return await httpClient.post(addApplicationsUserUrl(option), data);
  }

  /**
   * @name getApplicationsUser
   * @param {{userId: number}} params
   * @param {ApiOption} option
   * @returns {Promise<ApplicationsUserResponse>}
   */
  async getApplicationsUser(params, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getApplicationsUserUrl(option), { params });
  }

  /**
   * @name getApplicationlanguages
   * @param {number} id
   * @param {ApiOption} option
   * @returns {Promise<ApplicationLanguagesResponse>}
   */
  async getApplicationLanguages(id, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getApplicationLanguagesdUrl(id, option));
  }

  /**
   * @name getApplicationLanguagesV2
   * @param {number} id
   * @param {ApiOption} option
   * @returns {Promise<ApplicationLanguagesResponse>}
   */
  async getApplicationLanguagesV2(id, option = { version: ApiVersion.V1 }) {
    return await httpClientV2.get(getApplicationLanguagesdUrl(id, option));
  }

  /**
   * @name deleteApplication
   * @param {number} id
   * @param {ApiOption} option
   * @returns {Promise<void>}
   */
  async deleteApplication(id, option = { version: ApiVersion.V1 }) {
    return await httpClient.delete(deleteApplicationUrl(id, option));
  }

}

export const applicationsService = new ApplicationsService();
