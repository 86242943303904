import React from 'react';
import { DatePickerInput as CoreDatePickerInput } from '@geneui/components';

import useTranslation from '../../../../hooks/useTranslation';
import { TranslationKeys as T } from '../../../../core/constants/translationKeys';

export const DatePickerInput = ({
  placeholder = T.DATE,
  labelAppearance = 'swap',
  ...props
}) => {

  const translate = useTranslation();

  const label = translate(placeholder);

  return (
    <CoreDatePickerInput
      label={label}
      placeholder={label}
      labelAppearance={labelAppearance}
      {...props}
    />
  );
};
