import { DEFAULT_HEADERS, httpClient } from '../httpClient/httpClient';
import { ApiVersion } from '../../core/enums/ApiVersion';
import {
  exportCurrenciesChartUrl,
  exportCurrenciesUrl,
  getCurrencyByCodeUrl,
  getCurrencyChart,
  getCurrencyUrl,
} from '../../core/constants/endpoints';
import { combineQuery } from '../../core/helpers/queryHelper';
import { abortFetch } from '../../core/helpers/abortController';
import { ABORT_API_KEYS } from '../../core/constants/abortApiKeys';

class CurrenciesService {
  /**
   * @name getCurrencies
   * @param {ApiOption=} option
   * @param {CurrenciesListParams=} params
   * @returns {Promise<Array<Currency>>}
   */
  async getCurrencies(params, option = { version: ApiVersion.V1 }) {
    const signal = abortFetch(ABORT_API_KEYS.GetCurrencies);
    return await httpClient.get(getCurrencyUrl(option), { params, signal });
  }

  /**
   * @name getCurrencyByCode
   * @param {string} code
   * @param {ApiOption=} option
   * @returns {Promise<Currency>}
   */
  async getCurrencyByCode(code, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getCurrencyByCodeUrl(code, option));
  }

  /**
   * @name getCurrencyChart
   * @param {CurrencyChartParams} params
   * @param {ApiOption=} option
   * @returns {Promise<CurrencyChartResponse>}
   */
  async getCurrencyChart(params, option = { version: ApiVersion.V1 }) {
    const signal = abortFetch(ABORT_API_KEYS.GetCurrencyChart);
    return await httpClient.get(getCurrencyChart(option), { params, signal });
  }

  /**
   * @name exportCurrencies
   * @param {ExportCurrenciesParams} params
   * @param {ApiOption} option
   * @return {Promise<File>}
   */
  async exportCurrencies(params, option = { version: ApiVersion.V1 }) {
    return await fetch(`${exportCurrenciesUrl(option)}${combineQuery(params)}`, {
      credentials: 'include',
      headers: {
        ...DEFAULT_HEADERS
      }
    });
  }

  /**
   * @name exportCurrenciesChart
   * @param {CurrencyChartParams} params
   * @param {ApiOption} option
   * @return {Promise<File>}
   */
  async exportCurrenciesChart(params, option = { version: ApiVersion.V1 }) {
    return await fetch(`${exportCurrenciesChartUrl(option)}${combineQuery(params)}`, {
      credentials: 'include',
      headers: {
        ...DEFAULT_HEADERS
      }
    });
  }

}

export const currenciesService = new CurrenciesService();
