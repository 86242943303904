import React from 'react';
import { Paper } from '@geneui/components';
import { observer } from 'mobx-react-lite';

import { SizingBox } from './styleModalContent';
import { items } from '../../../../core/constants/userSettings';
import useTranslation from '../../../../hooks/useTranslation';
import { useMobileSize } from '../../../../hooks/useMobileSize';
import { Radio } from '../../shared';

const SizingContent = observer(({ handleChange, viewMode }) => {

  const translate = useTranslation();
  const { isMobile } = useMobileSize();

  return (
    <Paper
      alignItems='center'
      paperDirection={isMobile ? 'column' : 'row'}
    >
      {
        items.map(size => (
          <Paper
            alignItems='center'
            justifyContent='center'
            paperDirection='column'
            padding={isMobile ? '0 0 20px 0' : 0}
            onClick={() => handleChange(size.value)}
          >
            <SizingBox className={size.value}>Aa</SizingBox>
            <Radio
              onChange={() => handleChange(size.value)}
              name={viewMode}
              label={translate(size.label)}
              value={size.value}
              checked={viewMode === size.value}
            />
          </Paper>
        ))
      }
    </Paper>
  );
});

export default SizingContent;
