import { ApiVersion } from '../../core/enums/ApiVersion';
import { httpClient } from '../httpClient/httpClient';
import {
  getAddTournamentsUrl,
  getCancelTournamentsIdUrl,
  getEditTournamentsPartnerStatusUrl,
  getEditTournamentsUrl,
  getTournamentByIdUrl,
  getTournamentsPartnerUrl,
  getTournamentsPlayersUrl,
  getTournamentsRangeIdUrl,
  getTournamentsUrl,
} from '../../core/constants/endpoints';
import { abortFetch } from '../../core/helpers/abortController';
import { ABORT_API_KEYS } from '../../core/constants/abortApiKeys';

class TournamentsService {
  /**
   * @name addTournament
   * @param {TournamentBody} data
   * @param {ApiOption=} option
   * @returns {Promise<void>}
   */
  async addTournament(data, option = { version: ApiVersion.V1 }) {
    return await httpClient.post(getAddTournamentsUrl(option), data);
  };

  /**
   * @name getTournaments
   * @param {GetTournamentsParams=} params
   * @param {ApiOption=} option
   * @returns {Promise<TournamentsResponse>}
   */
  async getTournaments(params = {}, option = { version: ApiVersion.V1 }) {
    const signal = abortFetch(ABORT_API_KEYS.GetTournaments);
    return await httpClient.get(getTournamentsUrl(option), { params, signal });
  };

  /**
   * @name editTournament
   * @param {TournamentEditBody} data
   * @param {ApiOption=} option
   * @returns {Promise<void>}
   */
  async editTournament(data, option = { version: ApiVersion.V1 }) {
    return await httpClient.put(getEditTournamentsUrl(option), data);
  };

  /**
   * @name getTournamentById
   * @param {number} id
   * @param {ApiOption=} option
   * @returns {Promise<TournamentDetailed>}
   */
  async getTournamentById(id, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getTournamentByIdUrl(option), {
      params: {
        id,
      },
    });
  };

  /**
   * @name getTournamentsRange
   * @param {number} rangeId
   * @param {TournamentsRangParams} params
   * @param {ApiOption=} option
   * @returns {Promise<TournamentRangeDetailed>}
   */
  async getTournamentsRange(rangeId, params, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getTournamentsRangeIdUrl(rangeId, option), { params });
  };

  /**
   * @name cancelTournament
   * @param {number} id
   * @param {ApiOption=} option
   * @returns {Promise<void>}
   */
  async cancelTournament(id, option = { version: ApiVersion.V1 }) {
    return await httpClient.post(getCancelTournamentsIdUrl(option), null, {
      params: {
        id,
      },
    });
  };

  /**
   * @name editTournamentPartnerStatus
   * @param {TournamentPartner} data
   * @param {ApiOption=} option
   * @returns {Promise<void>}
   */
  async editTournamentPartnerStatus(data, option = { version: ApiVersion.V1 }) {
    return await httpClient.put(getEditTournamentsPartnerStatusUrl(option), data);
  };

  /**
   * @name getTournamentPlayers
   * @param {TournamentsPlayersParams} params
   * @param {ApiOption=} option
   * @returns {Promise<TournamentPlayerResponse>}
   */
  async getTournamentPlayers(params, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getTournamentsPlayersUrl(option), { params });
  };

  /**
   * @name getTournamentsPartners
   * @param {TournamentsPartnerParams=} params
   * @param {ApiOption} option
   * @returns {Promise<TournamentPartnerResponse>}
   */
  async getTournamentsPartners(params, option = { version: ApiVersion.V1 }) {
    const signal = abortFetch(ABORT_API_KEYS.GetTournamentPartners);
    return await httpClient.get(getTournamentsPartnerUrl(option), { params, signal });
  };
}

export const tournamentsService = new TournamentsService();
