import React from 'react';
import { Button as CoreButton } from '@geneui/components';

export const Button = ({
  text = '',
  children,
  ...restProps
}) => {

  return (
    <CoreButton
        {...restProps}
    >
        { children || text }
    </CoreButton>
  );
};

