import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { authStore } from '../../module';

const IsAuthLayer = observer(({ children }) => {
  const { isAuth } = authStore;

  return (
    <>
      {isAuth ? children : <Redirect to={'/login'}/>}
    </>
  );
});

export default withRouter(IsAuthLayer);

