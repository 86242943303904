import { ApiVersion } from '../../core/enums/ApiVersion';
import { httpClient } from '../httpClient/httpClient';
import { getAllPermissionsUrl, getPermissionUserUrl } from '../../core/constants/endpoints';

class PermissionsService {
  /**
   * @name getPermissionsUserId
   * @param {number} userId
   * @param {ApiOption=} option
   * @return {Promise<PermissionsResponse>}
   */
  async getPermissionsUserId(userId, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getPermissionUserUrl(userId, option));
  }

  /**
   * @name getPermissionsByUserIdForEdit
   * @param {number} userId
   * @param {ApiOption=} option
   * @return {Promise<PermissionForEdit>}
   */
  async getPermissionsByUserIdForEdit(userId, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getPermissionUserUrl(userId, option));
  }

  /**
   * @name getAllPermissions
   * @param {ApiOption=} option
   * @return {Promise<PermissionForEdit>}
   */
  async getAllPermissions(option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getAllPermissionsUrl(option));
  }

}

export const permissionsService = new PermissionsService();
