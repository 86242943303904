import React, { useEffect, useMemo } from 'react';
import { Toaster, GeneUIProvider } from '@geneui/components';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import IsNotAuthLayer from '../routing/layers/isNotAuthLayer';
import IsAuthLayer from '../routing/layers/isAuthLayer';
import { CurrencyContext, TranslationContext } from '../contexts';
import App from './app/index';
import LoginPage from './pages/login/index';
import Splash from './containers/splash/index';
import defineGuards from '../routing/defineGuards/defineGuards';
import { currenciesStore, maintenanceStore, translationStore, userStore } from '../module';
import useTranslation from '../hooks/useTranslation';
import { TranslationKeys } from '../core/constants/translationKeys';
import Maintenance from './pages/maintenance';
import UserBlocked from './pages/userBlocked';
import { settingsStore } from './components/settings';
import { ViewModes } from '../core/constants/userSettings';

const AppWrapper = observer(() => {
  const { aliasMode } = translationStore;
  const { isUnderMaintenance } = maintenanceStore;
  const { backofficeTranslations: { data } } = translationStore;
  const { blockedUser: { isBlocked: isUserBlocked } } = userStore;
  const { current } = currenciesStore;
  const { viewMode: viewModeValue } = settingsStore;
  const translate = useTranslation();
  const isUserBlockedOrUnderMaintenance = isUnderMaintenance || isUserBlocked;

  useEffect(() => {
    settingsStore.setViewMode(viewModeValue || ViewModes.large);
    document.documentElement.setAttribute('class', viewModeValue);
  }, [viewModeValue]);

  const translationContextValue = useMemo(() => {
    return aliasMode ? TranslationKeys : data;
  }, [aliasMode, data]);

  return (
    <GeneUIProvider>
      <TranslationContext.Provider value={translationContextValue}>
        {
          isUserBlockedOrUnderMaintenance
            ? (
              <>
                {isUserBlocked && <UserBlocked/>}
                {isUnderMaintenance && <Maintenance/>}
              </>
            ) : (
              <DndProvider backend={HTML5Backend}>
                <BrowserRouter>
                  <CurrencyContext.Provider value={current}>
                    <div id={'holder-portal-container'}/>
                    <Splash>
                      <Switch>
                        <Route
                          render={(props) => defineGuards([IsNotAuthLayer], LoginPage, props)}
                          exact
                          path="/login"
                        />
                        <Route path="/*" render={(props) => defineGuards([IsAuthLayer], App, props)}/>
                        <Route path="*" render={() => <div>{translate(TranslationKeys.NO_PAGE)}</div>}/>
                      </Switch>
                    </Splash>
                    <Toaster/>
                  </CurrencyContext.Provider>
                </BrowserRouter>
              </DndProvider>
            )
        }
      </TranslationContext.Provider>
    </GeneUIProvider>
  );
});

export default AppWrapper;
