import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { dataToDropDownConverter } from '../../../core/helpers/dropdownDataConverter';
import { gamesStore, notifyService, platformStore } from '../../../module';
import { TranslationKeys } from '../../../core/constants/translationKeys';
import { Dropdown, TextInput, Modal, ModalFooter, Switcher } from '../shared';
import useTranslation from '../../../hooks/useTranslation';
import { toggleStore } from '../../../module/toggleModule';
import { modalStore } from '../../../module/popUpModule';
import { FormType } from '../../../core/enums/FormType';
import GameLinesDropdown from '../game-lines-dropdown';
import { useForm } from '../../../hooks/useForm';
import useQuery from '../../../hooks/useQuery';

const Fields = styled.div`
  display: grid;
  grid-row-gap: 20px;
`;

const CreatePlatformsModal = observer(() => {

  const history = useHistory();
  const { queryObject } = useQuery();
  const { games: { data: { items: games } } } = gamesStore;
  const [ gameLine, setGameLine ] = useState(null);
  const { handleChange, values, errors, isValid } = useForm({
    initialState: {
      name: '',
      gameIds: [],
      hasRealModeSwitcherByDefault: true,
    },
  });

  const translate = useTranslation();

  const onRefreshPage = useCallback(() => {
    let params = {};
    const { pathname } = history.location;
    const isInPlatformsPage = pathname.includes('platforms-settings');
    if (isInPlatformsPage) {
      params = { ...queryObject };
    }
    platformStore.getPlatforms(params);
    platformStore.getPlatformsPartners();
  }, [queryObject, history]);

  const handlePlatformCreate = useCallback(() => {
    /**@type {PlatformBody} */
    const data = {
      name: values.name,
      gameIds: values.gameIds.map(id => Number(id)),
    };
    toggleStore.setButtonLoading(true);
    platformStore.addPlatform(data).then(() => {
      modalStore.closeCreatePlatformModal();
      onRefreshPage();
      notifyService.success({
        message: TranslationKeys.SUCCESS_NEW_PLATFORM_ADDED_TITLE,
      });
    }).catch(err => {
      notifyService.error({
        message: err?.data?.errorMessage,
      });
    }).finally(() => {
      toggleStore.setButtonLoading(false);
    });
  }, [values, onRefreshPage]);

  const handlePlatformsModalClose = useCallback(() => {
    modalStore.closeCreatePlatformModal();
  }, []);

  const handlePlatformNameChange = useCallback((event) => {
    handleChange(event, 'name', FormType.Text);
  }, []);

  const handleGameIdChange = useCallback((event) => {
    handleChange(event, 'gameIds', FormType.Dropdown);
  }, []);

  const handleHasRealModeChange = useCallback((event) => {
    handleChange(event, 'hasRealModeSwitcherByDefault', FormType.Switcher);
  }, []);

  const gamesData = useMemo(() => {
    const filteredGames = gameLine ? games.filter(item => +item.category === +gameLine) : games;
    return dataToDropDownConverter(filteredGames);
  }, [games, gameLine]);

  return (
    <Modal
      visible
      onCancel={handlePlatformsModalClose}
      title={translate(TranslationKeys.ADD_PLATFORM)}
      closeOnClickOutside={false}
      width={500}
      footer={(
        <ModalFooter
          onCancel={handlePlatformsModalClose}
          onSave={handlePlatformCreate}
          saveDisabled={isValid}
          actionLoading={toggleStore.buttonLoading}
        />
      )}
    >
      <Fields>
        <TextInput
          required
          name="name"
          value={values.name}
          isValid={!errors.name}
          errorText={errors.name}
          onChange={handlePlatformNameChange}
          placeholder={translate(TranslationKeys.PLATFORM_NAME)}
        />
        <GameLinesDropdown
          hasAll
          value={gameLine}
          onChange={(event) => setGameLine(event?.value)}
        />
        <Dropdown
          isMultiSelect
          data={gamesData}
          hasSearch={false}
          labelAppearance="swap"
          value={values.gameIds}
          isValid={!errors.gameIds}
          errorText={errors.gameIds}
          onChange={handleGameIdChange}
          label={translate(TranslationKeys.GAMES)}
          placeholder={translate(TranslationKeys.GAMES)}
        />
        <Switcher
          labelPosition='top'
          onChange={handleHasRealModeChange}
          value={values.hasRealModeSwitcherByDefault}
          label={translate(TranslationKeys.HAS_REAL_MODE_SWITCHER)}
          defaultChecked={values.hasRealModeSwitcherByDefault}
        />
      </Fields>
    </Modal>
  );
});

export default CreatePlatformsModal;
