import React, { useState } from 'react';
import styled from 'styled-components';

import { copyToClipboard } from '../../../../core/helpers/copyToClipboard';
import { Button } from '..';

const CopyWr = styled.div`
  position: relative;
`;

const CopyNote = styled.span`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%) scale(0);
  padding: 4px 8px;
  border: 1px solid #ccc;
  border-radius: 6px;
  background: #fff;
  transition: transform 240ms;
  ${({ open }) => open && `
    transform: translateX(-50%) scale(1);
  `}
`;

export const CopyToClipboard = ({ text = '' }) => {

  const [showCopyNote, setShowCopyNote] = useState(false);

  const handleCopyClick = () => {
    copyToClipboard(text);
    setShowCopyNote(true);
    setTimeout(() => {
      setShowCopyNote(false);
    }, 1000);
  };

  return (
    <CopyWr>
      <Button
        appearance="greyscale"
        icon="bc-icon-copy-mirror"
        onClick={handleCopyClick}
      />
      <CopyNote open={showCopyNote}>Copied!</CopyNote>
    </CopyWr>
  );
};
