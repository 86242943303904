import { action, computed, flow, makeObservable, observable } from 'mobx';

import { HttpResponse } from '../../core/classes/HttpResponse';
import { userService } from './userService';

class UserStore {
  /** @type {HttpResponse<UserResponse>}*/
  users = new HttpResponse({ items: [], count: 0 });

  /** @type {HttpResponse<UserInfoResponse>}*/
  userInfo = new HttpResponse({});

  /** @type {HttpResponse<UserDetailedResponse>}*/
  userDetailed = new HttpResponse({});

  /** @type {HttpResponse<CustomizationConfigResponse>}*/
  customizationConfig = new HttpResponse({});

  /** @type {BlockedUser}*/
  blockedUser = {
    isBlocked: false,
    _userName: ''
  };

  constructor() {
    makeObservable(this, {
      users: observable,
      userInfo: observable,
      userDetailed: observable,
      customizationConfig: observable,
      blockedUser: observable,
      setUsers: action.bound,
      setUserInfo: action.bound,
      setUserDetailed: action.bound,
      setCustomizationConfig: action.bound,
      setBlockedUser: action.bound,
      getUsers: flow.bound,
      getUserInfo: action.bound,
      getUserDetailed: action.bound,
      getCustomizationConfig: action.bound,
      blockedUsername: computed,
      quickDateRangeFiltersMap: computed,
    });
  }

  /**
   * @name blockedUsername
   * @return {string}
   */
  get blockedUsername() {
    return this.userInfo.userName || this.blockedUser._userName;
  }

  /**
   * @name quickDateRangeFiltersMap
   * @return {Record<string, boolean>}
   */
  get quickDateRangeFiltersMap() {
    const { data } = this.customizationConfig.data;
    const { quickDateRangeFilters = [] } = data || {};
    return quickDateRangeFilters.reduce((acc, curr) => {
      return { ...acc, [curr]: true };
    }, {});
  }

  /**
   * @name setBlockedUser
   * @param {BlockedUser} data
   * @returns {void}
   */
  setBlockedUser = (data) => {
    this.blockedUser = data;
  };

  /**
   * @name setUser
   * @param {HttpResponse<UserResponse>} users
   * @returns {void}
   */
  setUsers = (users) => {
    this.users = users;
  };

  /**
   * @name setUserInfo
   * @param {HttpResponse<UserInfoResponse>} userInfo
   * @returns {void}
   */
  setUserInfo = (userInfo) => {
    this.userInfo = userInfo;
  };

  /**
   * @name setUserDetailed
   * @param {HttpResponse<UserDetailedResponse>} data
   * @returns {void}
   */
  setUserDetailed = (data) => {
    this.userDetailed = data;
  };

  /**
   * @name setCustomizationConfig
   * @param {HttpResponse<CustomizationConfigResponse>} config
   * @returns {void}
   */
   setCustomizationConfig = (config) => {
    this.customizationConfig = config;
  };

  /**
   * @name getUserInfo
   * @returns {Promise<UserInfoResponse>}
   */
  getUserInfo = async () => {
    this.setUserInfo(this.userInfo.fetching());
    const userInfo = await userService.getUsersInfo();
    this.setUserInfo(this.userInfo.fetched(userInfo));
    return userInfo;
  };

  /**
   * @name getUserDetailed
   * @param {number} userId
   * @returns {Promise<UserDetailedResponse>}
   */
   getUserDetailed = async (userId) => {
    this.setUserDetailed(this.userDetailed.fetching());
    const data = await userService.getUserDetailed(userId);
    this.setUserDetailed(this.userDetailed.fetched(data));
    return data;
  };

  /**
   * @name getCustomizationConfig
   * @returns {Promise<CustomizationConfigResponse>}
   */
   getCustomizationConfig = async () => {
    this.setCustomizationConfig(this.customizationConfig.fetching());
    const data = await userService.getCustomizationConfig();
    this.setCustomizationConfig(this.customizationConfig.fetched(data));
    return data;
  };

  /**
   * @name editCustomizationConfig
   * @param {EditCustomizationConfigPayload} config
   * @returns {Promise<void>}
   */
  editCustomizationConfig = (config) => {
    const { data: oldData } = this.customizationConfig.data;
    return userService.editCustomizationConfig({
      data: {
        ...oldData,
        ...config
      }
    });
  };

  /**
   * @name addUser
   * @param {AddUserBody} data
   * @returns {Promise<AddUserResponse>}
   */
  addUser = (data) => {
    return userService.addUser(data);
  };

  /**
   * @name editUser
   * @param {UserUpdateBody} data
   * @returns {Promise<void>}
   */
  editUser = (data) => {
    return userService.editUser(data);
  };

  /**
   * @name changeUserBlockState
   * @param {number} id
   * @param {BlockUserBody} params
   * @returns {Promise<void>}
   */
  changeUserBlockState = (id, params) => {
    return userService.editIfBlock(id, params);
  };

  /**
   * @name editUserInfo
   * @param {EditUserInfoBody} data
   * @returns {Promise<EditUserInfoResponse>}
   */
  editUserInfo = (data) => {
    return userService.editUserInfo(data);
  };

  /**
   * @name changePassword
   * @param {ChangePasswordBody} data
   * @returns {Promise<void>}
   */
  changePassword = (data) => {
    return userService.changePassword(data);
  };

  /**
   * @name sendResetPasswordEmail
   * @param {ResetPasswordEmailQuery} params
   * @returns {Promise<void>}
   */
  sendResetPasswordEmail = (params) => {
    return userService.sendResetPasswordEmail(params);
  };

  /**
   * @name resetPassword
   * @param {ResetPasswordBody} data
   * @returns {Promise<void>}
   */
  resetPassword = (data) => {
    return userService.resetPassword(data);
  };

  /**
   *
   * @param {number} id
   * @param {EmailConfirmationBody} data
   * @returns {Promise<void>}
   */
  confirmEmail = (id, data) => {
    return userService.confirmEmail(id, data);
  };

  /**
   * @name getUser
   * @param {UserQuery} params
   * @returns {Generator<*, void, *>}
   */
  getUsers = function* (params) {
    this.setUsers(this.users.fetching(params));
    const user = yield userService.getUsers(params);
    this.setUsers(this.users.fetched(user));
  };
}

export const userStore = new UserStore();
