import React, { useCallback, useRef, useState } from 'react';

import { Icon, Popover } from '../shared';
import useTranslation from '../../../hooks/useTranslation';
import CreateButtonContainer from './createButtonContainer';
import useOutSideClick from '../../../hooks/useOutSideClick';
import { TranslationKeys } from '../../../core/constants/translationKeys';

import './headerCreate.css';

const HeaderCreateButton = () => {
  const ref = useRef();
  const translation = useTranslation();
  const [open, setOpen] = useState(false);

  const handleOpenToggle = useCallback(() => {
    setOpen(prev => !prev);
  }, []);

  const createButtonIcon = open ? 'bc-icon-close' : 'bc-icon-plus';
  const createButtonContent = open ? 'openToggle' : 'closedToggle';

  useOutSideClick(ref, () => {
    setOpen(false);
  }, 'ignore-create');

  return (
    <Popover
      isOpen={open}
      align="center"
      extendTargetWidth={false}
      contentRef={ref}
      Content={(
        <CreateButtonContainer setOpen={setOpen}/>
      )}
      toggleHandler={handleOpenToggle}
    >
      <button
        className={`${createButtonContent} create-button-styles ignore-create`}
      >
        <div className="create-button-content">
          <div>
            <Icon type={`${createButtonIcon}`} />
          </div>
          <div className='create-btn-name'>
            {translation(TranslationKeys.CREATE)}
          </div>
        </div>
      </button>
    </Popover>
  );
};

export default HeaderCreateButton;
