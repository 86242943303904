import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import useTranslation from '../../../../hooks/useTranslation';
import { FormType } from '../../../../core/enums/FormType';
import { TranslationKeys } from '../../../../core/constants/translationKeys';
import { Checkbox, Dropdown, NumberInput, Switcher, TextInput } from '../../shared';
import { AuthTypeDropdownData } from '../../../../core/enums/auth-type';
import { dataToDropDownConverter } from '../../../../core/helpers/dropdownDataConverter';
import { TimeZoneLimits } from '../../../../core/constants/util';

const InputContent = styled.section`
  display: grid;
  grid-row-gap: 10px;
`;

const AddPartnerModal = ({
  values,
  setValues,
  platformData,
  currencyData,
  handleChange,
}) => {
  const translate = useTranslation();

  const handlePlatformChange = useCallback((event) => {
    return handleChange(event, 'platformId', FormType.Dropdown);
  }, [handleChange]);

  const handleNameChange = useCallback((event) => {
    return handleChange(event, 'name', FormType.Text);
  }, [handleChange]);

  const handleCurrencyChange = useCallback((event) => {
    return handleChange(event, 'currencyCode', FormType.Dropdown);
  }, [handleChange]);

  const handleIdChange = useCallback((event) => {
    return handleChange(event, 'partnerId', FormType.Text);
  }, [handleChange]);

  const handleChangeIsTest = useCallback((event) => {
    handleChange(event, 'isTest', FormType.Checkbox);
  }, [handleChange]);

  const handleAuthTypeChange = useCallback((event) => {
    handleChange(event, 'authType', FormType.Dropdown);
  }, [handleChange]);

  const authTypeDropdownData = useMemo(() => {
    return dataToDropDownConverter(AuthTypeDropdownData, translate);
  }, [translate]);

  const handleHasRealModeChange = useCallback((event) => {
    handleChange(event, 'hasRealModeSwitcher', FormType.Switcher);
  }, [handleChange]);

  const handleHasTournamentChange = useCallback((event) => {
    handleChange(event, 'hasTournament', FormType.Switcher);
  }, [handleChange]);

  const handleTimeZoneChange = useCallback((event) => {
    const { value } = event.target;
    if (+value < TimeZoneLimits.Min || +value > TimeZoneLimits.Max) return;
    setValues({
      ...values,
      timeZone: value
    });
  }, [values, setValues]);

  return (
    <InputContent>
      <Dropdown
        data={platformData}
        label={translate(TranslationKeys.PLATFORM)}
        placeholder={translate(TranslationKeys.PLATFORM)}
        labelAppearance="swap"
        onChange={handlePlatformChange}
        isValid
        value={values.platformId}
      />
      <TextInput
        label={translate(TranslationKeys.PARTNER_NAME)}
        placeholder={translate(TranslationKeys.PARTNER_NAME)}
        value={values.name}
        onChange={handleNameChange}
      />
      <TextInput
        label={translate(TranslationKeys.PARTNER_EXTERNAL_ID)}
        name="partnerId"
        min={1}
        placeholder={translate(TranslationKeys.PARTNER_EXTERNAL_ID)}
        value={values.partnerId}
        onChange={handleIdChange}
      />
      <Dropdown
        data={currencyData}
        label={translate(TranslationKeys.CURRENCY_CODE)}
        placeholder={translate(TranslationKeys.CURRENCY_CODE)}
        labelAppearance="swap"
        onChange={handleCurrencyChange}
        isValid
        value={values.currencyCode}
      />
      <Dropdown
        data={authTypeDropdownData}
        label={translate(TranslationKeys.AUTH_TYPE)}
        placeholder={translate(TranslationKeys.AUTH_TYPE)}
        labelAppearance="swap"
        onChange={handleAuthTypeChange}
        isValid
        value={values.authType}
      />
      <NumberInput
        showNumberIcon={false}
        value={values.timeZone}
        onChange={handleTimeZoneChange}
        label={translate(TranslationKeys.TIME_ZONE)}
        placeholder={translate(TranslationKeys.TIME_ZONE)}
      />
      <Checkbox
        label={translate(TranslationKeys.IS_TEST_PARTNER)}
        onChange={handleChangeIsTest}
      />
      <Switcher
        disabled={!values.platformId}
        onChange={handleHasRealModeChange}
        value={values.hasRealModeSwitcher}
        label={translate(TranslationKeys.HAS_REAL_MODE_SWITCHER)}
        checked={values.hasRealModeSwitcher}
      />
      <Switcher
        disabled={!values.platformId}
        onChange={handleHasTournamentChange}
        value={values.hasTournament}
        label={translate(TranslationKeys.HAS_TOURNAMENT)}
        checked={values.hasTournament}
      />
    </InputContent>
  );
};

export default AddPartnerModal;
