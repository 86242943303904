export const routePathConstants = {
  reports: 'reports',
  bog: 'bog',
  arcade: 'arcade',
  tableGames: 'table-games',
  lottery: 'lottery',
  betshop: 'betshop',
  slot: 'slot',
  virtual: 'virtual',
  crypto: 'crypto-games'
};
