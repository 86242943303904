import { ApiVersion } from '../../core/enums/ApiVersion';
import { httpClient } from '../httpClient/httpClient';
import { getNotificationUrl, getReadNotificationUrl, readAllNotificationsUrl } from '../../core/constants/endpoints';

class NotificationService {
  /**
   * @name getNotifications
   * @param {NotificationsParams} params
   * @param {ApiOption=} option
   * @returns {Promise<NotificationsResponse>}
   */
  async getNotifications(params, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getNotificationUrl(option), { params });
  }

  /**
   * @name readNotification
   * @param {number} userNotificationId
   * @param {ApiOption=} option
   * @returns {Promise<void>}
   */
  async readNotification(userNotificationId, option = { version: ApiVersion.V1 }) {
    return await httpClient.put(getReadNotificationUrl(userNotificationId, option));
  }

  /**
   * @name readAllNotifications
   * @param {ApiOption=} option
   * @return {Promise<void>}
   */
  async readAllNotifications(option = { version: ApiVersion.V1 }) {
    return await httpClient.put(readAllNotificationsUrl(option));
  }
}

export const notificationService = new NotificationService();
