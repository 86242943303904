import React, { useRef, useCallback, useState } from 'react';
import { CardList, Paper } from '@geneui/components';
import styled, { css } from 'styled-components';

import { tableDataFormatterMobile } from '../../../../core/helpers/tableDataFormatter';
import { TranslationKeys as T } from '../../../../core/constants/translationKeys';
import { DEFAULT_TAKE, noop } from '../../../../core/constants/util';
import { scrollToTop } from '../../../../core/helpers/scrollHelper';
import useTableColumns from '../../../../hooks/useTableColumns';
import useTranslation from '../../../../hooks/useTranslation';
import { currenciesStore } from '../../../../module';
import { Icon } from '../icon';

const CardListWrapper = styled.div`
  width: 100%;
  padding: 1.6rem;
  overflow: auto;
  max-height: ${(props) => props.maxHeight || 'calc(100vh - 222px)'};
`;

const CardListTitle = styled.div`
  padding: 1.2rem 1.6rem;
  border-bottom: 1px solid rgba(var(--background-sc-rgb), 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${props => !props.hasTitle && css`
    display: none;
  `}

  .refresh-btn {
    color: var(--hero);
  }
`;

export const MobileCardList = ({
  name,
  rows,
  columns,
  maxHeight,
  onRefreshClick,
  hasTitle = true,
  getPopupProps = noop,
  ...restProps
}) => {

  const scrollRef = useRef(null);
  const { current: currentCurrency } = currenciesStore;
  const { cols } = useTableColumns(columns, currentCurrency, true);

  const [ cardListKey, setCardListKey ] = useState(false);

  const translate = useTranslation();

  const handleRefreshClick = useCallback(() => {
    setCardListKey((prev) => !prev);
    scrollToTop(scrollRef.current);
    onRefreshClick();
  }, [onRefreshClick]);

  return (
    <Paper border cornerRadius="top-radius" paperDirection="column" className="mobile-card-list-paper">
      <CardListTitle hasTitle={hasTitle}>
        <span>
          {name}
        </span>
        {
          !!onRefreshClick && (
            <Icon
              type="bc-icon-refresh"
              className="refresh-btn"
              onClick={handleRefreshClick}
            />
          )
        }
      </CardListTitle>
      <CardListWrapper ref={scrollRef} maxHeight={maxHeight}>
        <CardList
          border
          shadow={false}
          columns={cols}
          key={`${cardListKey}`}
          scrollElement={scrollRef}
          rowsPerPage={DEFAULT_TAKE}
          rows={tableDataFormatterMobile(rows)}
          getPopupProps={(row) => ({
            leftAction: {
              text: translate(T.BACK),
              iconType: 'bc-icon-arrow-left',
            },
            ...getPopupProps(row)
          })}
          resultText={`${translate(T.RESULT)}:`}
          {...restProps}
        />
      </CardListWrapper>
    </Paper>
  );
};
