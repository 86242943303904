import React from 'react';

import { TextInput } from '../../../text-input';

export const PGTransferListSearch = ({
  onChange
}) => {
  return (
    <div className="pg-transfer-list-search">
      <TextInput
        placeholder="Search"
        icon="bc-icon-search"
        onChange={(event) => onChange(event.target.value)}
      />
    </div>
  );
};
