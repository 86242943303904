import React, { useCallback } from 'react';
import { ExtendedInput } from '@geneui/components';

import { noop } from '../../../../core/constants/util';

export const TextInput = ({
  value,
  onChange = noop,
  onInputValidationRegEx,
  labelAppearance = 'swap',
  ...props
}) => {

  const handleChange = useCallback((event) => {
    const { value = '' } = event.target;

    // onInput RegEx validation
    if (onInputValidationRegEx && !onInputValidationRegEx?.test(value)) return;

    onChange(event);
  }, [onInputValidationRegEx, onChange]);

  return (
    <ExtendedInput
      value={value}
      onChange={handleChange}
      labelAppearance={labelAppearance}
      type="text"
      {...props}
    />
  );
};
