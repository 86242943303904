import React, { useCallback, useEffect, useState } from 'react';

export const useMobileSize = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const windowResizeHandler = useCallback(() => {
    setWindowSize(window.innerWidth);
  }, [setWindowSize]);

  useEffect(() => {
    window.addEventListener('resize', windowResizeHandler);

    return () => {
      window.removeEventListener('resize', windowResizeHandler);
    };
  }, [windowResizeHandler]);

  return {
    windowSize,
    isMobile: windowSize < 768,
  };
};
