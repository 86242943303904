import { ApiVersion } from '../../core/enums/ApiVersion';
import { httpClient } from '../httpClient/httpClient';
import {
  addPromoTemplateUrl,
  editPromoTemplateUrl,
  getPromoTemplatesUrl,
  cancelPromoTemplateUrl,
  getPromoTemplateByIdUrl,
  getPromoPlayersReportUrl
} from '../../core/constants/endpoints';
import { abortFetch } from '../../core/helpers/abortController';
import { ABORT_API_KEYS } from '../../core/constants/abortApiKeys';

class PromoService {
  /**
   * @name getPromoTemplates
   * @param {GetPromoTemplatesParams=} params
   * @param {ApiOption=} option
   * @return {Promise<PromoTemplates>}
   */
  async getPromoTemplates(params = {}, option = { version: ApiVersion.V1 }) {
    const signal = abortFetch(ABORT_API_KEYS.GetPromoTemplates);
    return await httpClient.get(getPromoTemplatesUrl(option), { params, signal });
  }

  /**
   * @name getPromoTemplateById
   * @param {number} id
   * @param {ApiOption=} option
   * @return {Promise<AddEditPromoTemplate>}
   */
  async getPromoTemplateById(id, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getPromoTemplateByIdUrl(id, option));
  }

  /**
   * @name getPromoPlayersReport
   * @param {object} params
   * @param {ApiOption=} option
   * @return {Promise<AddEditPromoTemplate>}
   */
  async getPromoPlayersReport(params, option = { version: ApiVersion.V1 }) {
    const signal = abortFetch(ABORT_API_KEYS.GetPromoPlayersReports);
    return await httpClient.get(getPromoPlayersReportUrl(option), { params, signal });
  }

  /**
   * @name addPromoTemplate
   * @param {AddEditPromoTemplate} data
   * @param {ApiOption=} option
   * @return {Promise<any>}
   */
  async addPromoTemplate(data, option = { version: ApiVersion.V1 }) {
    return await httpClient.post(addPromoTemplateUrl(option), data);
  }

  /**
   * @name editPromoTemplate
   * @param {AddEditPromoTemplate} data 
   * @param {ApiOption} option
   * @return {Promise<any>}
   */
  async editPromoTemplate(data = {}, option = { version: ApiVersion.V1 }) {
    return await httpClient.put(editPromoTemplateUrl(option), data);
  }

  /**
   * @name cancelPromoTemplate
   * @param {number} id
   * @param {ApiOption=} option
   * @return {Promise<any>}
   */
  async cancelPromoTemplate(id, option = { version: ApiVersion.V1 }) {
    return await httpClient.put(cancelPromoTemplateUrl(id, option));
  }
}

export const promoService = new PromoService();
