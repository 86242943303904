export const deepCopy = (obj) => {
  const copyObj = Array.isArray(obj) ? [] : {};
  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key]) {
      copyObj[key] = deepCopy(obj[key]);
    } else {
      copyObj[key] = obj[key];
    }
  }
  return copyObj;
};