import { v4 as createId } from 'uuid';
import { AllMarketTypes, AllMarketTypesReverse } from '../../view/pages/bonus/gameMaster/gameMasterAddEditClone/currencyBlock/util';
import { TournamentBuildType } from '../../core/enums/TournamentBuildType';
import { deepCopy } from '../../core/helpers/deepCopy';

export class TournamentBuilder {
  constructor() {
    this._id = createId();
    this.defaultCurrencyCode = '';
    this.name = '';
    this.pointType = -1;

    /** @type {string} */
    this.startDate = '';
    /** @type {string} */
    this.endDate = '';
    /** @type {string} */
    this.webDeletedDate = '';
    this.partners = [];
    this.gameIds = [];
    this._partnerIds = [];
    this._gameIds = [];

    /** @type {{_list: Array<string>, default: BuilderCurrencyData|null}}*/
    this._currencyMap = {
      default: {
        value: 'USD',
        id: 'USD',
        label: 'USD',
      },
      _list: [],
    };

    /** @type {Array<BuilderRange>} */
    this.ranges = [];
    /** @type {Object<string, BuilderRangeLimit>}*/
    this._rangeLimitsMap = {};
    /** @type {Object<string, BuilderRange>}*/
    this._rangeMap = {};
    this._activeCurrency = '';
    this._activeRange = '';
    /** @type {Object<string, Currency>}*/
    this._currencyListMap = {};
    /**@type {Array<PartnerItem>}*/
    this._partners = [];
    this._platformIds = [];
    this.tournamentId = undefined;
    this._once = false;
  }

  /**
   * @name copy
   * @static
   * @param {TournamentDetailed} response
   * @param {Array<PlatformPartnersResponseItem>} platformsPartners
   * @return {TournamentBuilder}
   */
  static copy(response, platformsPartners, cloneMode) {
    const data = new TournamentBuilder();
    data.webDeletedDate = response.webDeletedDate;
    data.defaultCurrencyCode = response.defaultCurrencyCode;
    data.startDate = response.startDate;
    data.endDate = response.endDate;
    data.status = response.status;
    data.name = cloneMode ? `${response.name} clone` : response.name;
    data.pointType = String(response.pointType);
    data._gameIds = response.gameIds;
    data.tournamentId = response.tournamentId;
    data._partnerIds = response.partners.filter(({ isEnabled }) => isEnabled).map(({ partnerId }) => partnerId);
    data._activeCurrency = response.defaultCurrencyCode;
    data._activeRange = String(response.ranges[0].rangeId);
    data._currencyMap._list = response.currencyCodes;
    data._currencyMap.default = {
      id: response.defaultCurrencyCode,
      value: response.defaultCurrencyCode,
      label: response.defaultCurrencyCode,
    };
    response.currencyCodes.forEach(code => {
      data._currencyMap[code] = {
        id: code,
        value: code,
        label: code,
      };
    });
    const _partnerIdsMap = data._partnerIds.reduce((map, id) => {
      map[id] = true;
      return map;
    }, {});
    platformsPartners.forEach(({ id, partners }) => {
      for (let { id: partnerId } of partners) {
        if (_partnerIdsMap[partnerId]) {
          data._platformIds.push(id);
          break;
        }
      }
    }, []);
    response.ranges.forEach((responseRange) => {
      const range = data._createRange();
      range._id = String(responseRange.rangeId);
      range.rangeId = responseRange.rangeId;
      range.winingCount = responseRange.winingCount;

      responseRange.rangeLimits.forEach((responseRangeLimit) => {
        const rangeLimit = data._createRangeLimit(responseRangeLimit.currencyCode, range._id);
        rangeLimit.id = responseRangeLimit.id;
        data._cloneRangeLimit(responseRangeLimit, rangeLimit);

        range.rangeLimits.push(rangeLimit);
      });
      responseRange.selections.forEach((responseSelection) => {
        const selection = data._createSelection();
        data._cloneSelection(responseSelection, selection);
        selection.rangeId = responseSelection.rangeId;
        selection.selectionId = responseSelection.selectionId;
        selection._gameBetTypes = responseSelection.gameBetTypes.map(({ betType }) => AllMarketTypes[betType]);

        responseSelection.selectionPrizes.forEach((responseSelectionPrize) => {
          const { currencyCode } = responseSelectionPrize;
          const selectionPrize = data._createSelectionPrize(currencyCode);
          data._cloneSelectionPrize(responseSelectionPrize, selectionPrize);
          selectionPrize.id = responseSelectionPrize.id;
          selectionPrize.selectionId = responseSelectionPrize.selectionId;

          selection._selectionPrizeMap[currencyCode] = selectionPrize;
          selection.selectionPrizes.push(selectionPrize);
        });

        responseSelection.stages.forEach((responseStage) => {
          const stage = data._createStage();
          data._cloneStage(responseStage, stage);
          stage.stageId = responseStage.stageId;

          responseStage.stagePrizes.forEach((responseStagePrize) => {
            const { currencyCode } = responseStagePrize;
            const stagePrize = data._createStagePrize(currencyCode);
            data._cloneStagePrize(responseStagePrize, stagePrize);
            stagePrize.id = responseStagePrize.id;
            stagePrize.stageId = responseStagePrize.stageId;

            stage.stagePrizes.push(stagePrize);
            stage._stagePrizeMap[currencyCode] = stagePrize;
          });

          selection.stages.push(stage);
          selection._stagesMap[stage._id] = stage;
        });

        range.selections.push(selection);
        range._selectionMap[selection._id] = selection;
      });

      data.ranges.push(range);
      data._rangeMap[range._id] = range;
    });
    return data;
  }

  /**
   * @name clone
   * @returns {TournamentBuilder}
   */
  clone() {
    const clone = { ...this };
    Object.setPrototypeOf(clone, TournamentBuilder.prototype);
    return clone;
  };

  /** private methods  start*/

  /**
   * @name _checkDefaultCurrency
   * @param {string} currency
   * @returns {boolean}
   * @private
   */
  _checkDefaultCurrency(currency) {
    return currency === this._currencyMap?.default?.value;
  }

  /**
   * @name _createRange
   * @returns {BuilderRange}
   * @private
   */
  _createRange() {
    const range = {
      rangeLimits: [],
      selections: [],
      winingCount: 0,
      _id: createId(),
      _selectionMap: {},
    };
    if (!this._activeRange) {
      this._activeRange = String(range._id);
    }
    this._rangeMap[range._id] = range;
    return range;
  }

  /**
   * @name _createSelection
   * @returns {BuilderSelection}
   * @private
   */
  _createSelection() {
    return {
      displayName: '',
      priority: 0,
      gameBetTypes: [],
      selectionPrizes: [],
      stages: [],
      _gameBetTypes: [],
      _id: createId(),
      _selectionPrizeMap: {},
      _stagesMap: {},
    };
  }

  /**
   * @name _createSelectionPrize
   * @param {string} currencyCode
   * @returns {BuilderSelectionPrize}
   * @private
   */
  _createSelectionPrize(currencyCode) {
    return {
      currencyCode,
      prizeFund: '',
    };
  }

  /**
   * @name _createStage
   * @returns {BuilderStage}
   * @private
   */
  _createStage() {
    return {
      count: 0,
      winningCount: 0,
      stagePrizes: [],
      _id: createId(),
      _stagePrizeMap: {},
    };
  }

  /**
   * @name _createStagePrize
   * @param {string} currencyCode
   * @returns {BuilderStagePrize}
   * @private
   */
  _createStagePrize(currencyCode) {
    return {
      currencyCode,
      prizeFund: '',
    };
  }

  /**
   * @name _addRangeData
   * @param {BuilderCurrencyData} currencyData
   * @returns {void}
   * @private
   */
  _addRangeData(currencyData) {
    const { value: currency } = currencyData;
    const { default: { value: defaultCurrency } } = this._currencyMap;
    const isDefault = defaultCurrency === currency;

    if (isDefault) {
      const rangeData = this._createRange();
      const rangeLimit = this._createRangeLimit(currency, rangeData._id);
      const selection = this._createSelection();
      const selectionPrize = this._createSelectionPrize(currency);
      const stage = this._createStage();
      const stagePrize = this._createStagePrize(currency);

      rangeData._selectionMap[selection._id] = selection;

      selection._selectionPrizeMap[currency] = selectionPrize;
      selection._stagesMap[stage._id] = stage;
      stage._stagePrizeMap[currency] = stagePrize;

      stage.stagePrizes.push(stagePrize);
      selection.selectionPrizes.push(selectionPrize);
      selection.stages.push(stage);
      rangeData.rangeLimits.push(rangeLimit);
      rangeData.selections.push(selection);
      this.ranges.push(rangeData);
    } else {
      this.ranges.forEach(({ _id, rangeLimits, selections }) => {
        /**@type {BuilderRangeLimit} */
        const defaultRangeLimit = rangeLimits[0];
        const rangeLimit = this._createRangeLimit(currency, _id);

        this._convertRangeLimitAmounts({ defaultRangeLimit, rangeLimit });

        rangeLimits.push(rangeLimit);

        selections.forEach((selection) => {
          const defaultSelectionPrize = selection.selectionPrizes[0];
          const selectionPrize = this._createSelectionPrize(currency);

          selection.selectionPrizes.push(selectionPrize);
          selection._selectionPrizeMap[currency] = selectionPrize;

          this._convertSelectionPrizeFund({ defaultSelectionPrize, selectionPrize });

          selection.stages.forEach((stage) => {
            const defaultStagePrize = stage.stagePrizes[0];
            const stagePrize = this._createStagePrize(currency);

            this._convertStagePrizeFund({ defaultStagePrize, stagePrize });

            stage._stagePrizeMap[currency] = stagePrize;
            stage.stagePrizes.push(stagePrize);
          });
        });
      });
    }
  }

  /**
   * @name _createRangeLimit
   * @param {string} currencyCode
   * @param {string} rangeId
   * @returns {BuilderRangeLimit}
   * @private
   */
  _createRangeLimit(currencyCode, rangeId) {
    const limit = {
      currencyCode,
      minAmount: '',
      maxAmount: '',
      overalPrizeFund: 0,
      totalPrizeFund: '',
    };
    this._rangeLimitsMap[`${rangeId}_${currencyCode}`] = limit;
    return limit;
  }

  /**
   * @name _checkCurrency
   * @param {BuilderCurrencyData} currencyData
   * @returns {boolean}
   * @private
   */
  _checkCurrency(currencyData) {
    const { id, value } = currencyData;
    if (!this._activeCurrency) {
      this._activeCurrency = value;
    }
    if (!this.defaultCurrencyCode) {
      this.defaultCurrencyCode = value;
    }

    if (this._currencyMap[id]) {
      return false;
    }

    this._currencyMap[id] = currencyData;
    if (!this._currencyMap.default) {
      this._currencyMap.default = currencyData;
    }
    return true;
  }

  /**
   * @name _cloneRangeLimit
   * @param {BuilderRangeLimit} oldValue
   * @param {BuilderRangeLimit} newValue
   * @returns {void}
   * @private
   */
  _cloneRangeLimit(oldValue, newValue) {
    newValue.totalPrizeFund = oldValue.totalPrizeFund;
    newValue.overalPrizeFund = 0;
    newValue.maxAmount = oldValue.maxAmount;
    newValue.minAmount = oldValue.minAmount;
    newValue.currencyCode = oldValue.currencyCode;
  }

  /**
   * @name _cloneSelection
   * @param {BuilderSelection} oldSelection
   * @param {BuilderSelection} newSelection
   * @returns {void}
   * @private
   */
  _cloneSelection(oldSelection, newSelection) {
    newSelection.priority = oldSelection.priority;
    newSelection.displayName = oldSelection.displayName;
    newSelection._gameBetTypes = oldSelection._gameBetTypes;
  }

  /**
   * @name _cloneSelectionPrize
   * @param {BuilderSelectionPrize} oldSelectionPrize
   * @param {BuilderSelectionPrize} newSelectionPrize
   * @returns {void}
   * @private
   */
  _cloneSelectionPrize(oldSelectionPrize, newSelectionPrize) {
    newSelectionPrize.prizeFund = oldSelectionPrize.prizeFund;
    newSelectionPrize.currencyCode = oldSelectionPrize.currencyCode;
  }

  /**
   * @name _cloneStage
   * @param {BuilderStage} oldStage
   * @param {BuilderStage} newStage
   * @returns {void}
   * @private
   */
  _cloneStage(oldStage, newStage) {
    newStage.count = oldStage.count;
    newStage.winningCount = oldStage.winningCount;
  }

  /**
   * @name _cloneStagePrize
   * @param {BuilderStagePrize} oldStagePrize
   * @param {BuilderStagePrize} newStagePrize
   * @returns {void}
   * @private
   */
  _cloneStagePrize(oldStagePrize, newStagePrize) {
    newStagePrize.prizeFund = oldStagePrize.prizeFund;
  }

  /**
   * @name _convertCurrency
   * @param {number} value
   * @param {string} currency
   * @returns {number}
   * @private
   */
  _convertCurrency({ value, currency }) {
    const { rate: defaultRate } = this._currencyListMap[this._currencyMap.default.value];
    const { rate: currentRate, precision } = this._currencyListMap[currency];
    const number = Number((value * defaultRate / currentRate).toFixed(precision));
    let result = number;
    if (number === 0 || Number.isNaN(number) || result === Infinity || result === -Infinity) {
      result = '';
    }
    return result;
  }

  /**
   * @name _checkAndConvertStageBonusAmounts
   * @param {BuilderStage} stage
   * @param {string} currency
   * @param {number} value
   * @private
   */
  _checkAndConvertStageBonusAmounts({ stage, currency, value }) {
    if (this._checkDefaultCurrency(currency)) {
      stage.stagePrizes.forEach((stagePrize) => {
        const { currencyCode } = stagePrize;
        if (currencyCode !== currency) {
          stagePrize.prizeFund = this._convertCurrency({ value, currency: currencyCode });
        }
      });
    }
  }

  /**
   * @name _checkAndConvertRangeLimitsMinAmounts
   * @param {string} rangeId
   * @param {string} currency
   * @param {number} value
   * @private
   */
  _checkAndConvertRangeLimitsMinAmounts({ rangeId, currency, value }) {
    if (this._checkDefaultCurrency(currency)) {
      const range = this.getRange(rangeId);
      range.rangeLimits.forEach((rangeLimit) => {
        const { currencyCode } = rangeLimit;
        if (currencyCode !== currency) {
          rangeLimit.minAmount = this._convertCurrency({ value, currency: currencyCode });
        }
      });
    }
  }

  /**
   * @name _checkAndConvertRangeLimitsMaxAmounts
   * @param {string} rangeId
   * @param {string} currency
   * @param {number} value
   * @private
   */
  _checkAndConvertRangeLimitsMaxAmounts({ rangeId, currency, value }) {
    if (this._checkDefaultCurrency(currency)) {
      const range = this.getRange(rangeId);
      range.rangeLimits.forEach((rangeLimit) => {
        const { currencyCode } = rangeLimit;
        if (currencyCode !== currency) {
          rangeLimit.maxAmount = this._convertCurrency({ value, currency: currencyCode });
        }
      });
    }
  }

  /**
   * @name _checkAndConvertSelectionPrizeFund
   * @param {BuilderSelection} selection
   * @param {string} currency
   * @param {number} value
   * @returns {void}
   * @private
   */
  _checkAndConvertSelectionPrizeFund({ selection, currency, value }) {
    if (this._checkDefaultCurrency(currency)) {
      selection.selectionPrizes.forEach((selectionPrize) => {
        const { currencyCode } = selectionPrize;
        if (currencyCode !== currency) {
          selectionPrize.prizeFund = this._convertCurrency({ value, currency: currencyCode });
        }
      });
    }
  }

  /**
   * @name _checkAndConvertRangeOverallPrizeFund
   * @param {string} rangeId
   * @param {string} currency
   * @param {number} value
   * @private
   */
  _checkAndConvertRangeTotalPrizeFund({ rangeId, currency, value }) {
    if (this._checkDefaultCurrency(currency)) {
      const range = this.getRange(rangeId);
      range.rangeLimits.forEach((rangeLimit) => {
        const { currencyCode } = rangeLimit;
        if (currencyCode !== currency) {
          rangeLimit.totalPrizeFund = this._convertCurrency({ value, currency: currencyCode });
        }
      });
    }
  }

  /**
   * @name _convertRangeLimitAmounts
   * @param {BuilderRangeLimit} defaultRangeLimit
   * @param {BuilderRangeLimit} rangeLimit
   * @returns {void}
   * @private
   */
  _convertRangeLimitAmounts({ defaultRangeLimit, rangeLimit }) {
    rangeLimit.maxAmount = this._convertCurrency({
      value: defaultRangeLimit.maxAmount,
      currency: rangeLimit.currencyCode,
    });
    rangeLimit.minAmount = this._convertCurrency({
      value: defaultRangeLimit.minAmount,
      currency: rangeLimit.currencyCode,
    });
    rangeLimit.totalPrizeFund = this._convertCurrency({
      value: defaultRangeLimit.totalPrizeFund,
      currency: rangeLimit.currencyCode,
    });
  }

  /**
   * @name _convertSelectionPrizeFund
   * @param {BuilderSelectionPrize} defaultSelectionPrize
   * @param {BuilderSelectionPrize} selectionPrize
   * @returns {void}
   * @private
   */
  _convertSelectionPrizeFund({ defaultSelectionPrize, selectionPrize }) {
    selectionPrize.prizeFund = this._convertCurrency({
      value: defaultSelectionPrize.prizeFund,
      currency: selectionPrize.currencyCode,
    });
  }

  /**
   * @name _convertStagePrizeFund
   * @param {BuilderStagePrize} defaultStagePrize
   * @param {BuilderStagePrize} stagePrize
   * @returns {void}
   * @private
   */
  _convertStagePrizeFund({ defaultStagePrize, stagePrize }) {
    stagePrize.prizeFund = this._convertCurrency({
      value: defaultStagePrize.prizeFund,
      currency: stagePrize.currencyCode,
    });
  }

  /** private methods  end*/

  /** setups start*/

  /**
   * @name setup
   * @param {Object} data
   * @param {Array<Currency>} data.currencies
   * @param {Array<PlatformPartnersResponseItem>} data.platformsPartners
   * @returns {TournamentBuilder}
   */
  setup({ currencies, platformsPartners }) {
    this._currencyListMap = currencies.reduce((map, currency) => {
      map[currency.currencyCode] = currency;
      return map;
    }, {});
    this._partners = platformsPartners.reduce((acc, { partners }) => {
      return acc.concat(partners);
    }, []);
    this._partnerIdsMap = this._partners.reduce((map, { id }) => {
      map[id] = true;
      return map;
    }, {});
    return this.clone();
  }

  /** setups end*/

  /** getters start */

  /**
   * @name getRange
   * @param {string} rangeId
   * @returns {BuilderRange}
   */
  getRange(rangeId) {
    return this._rangeMap[rangeId];
  }

  /**
   * @name getSelection
   * @param {string} rangeId
   * @param {string} selectionId
   * @returns {BuilderSelection}
   */
  getSelection({ rangeId, selectionId }) {
    return this.getRange(rangeId)._selectionMap[selectionId];
  }

  /**
   * @name getSelectionPrize
   * @param {string} rangeId
   * @param {string} selectionId
   * @param {string} currency
   * @returns {BuilderSelectionPrize}
   */
  getSelectionPrize({ rangeId, selectionId, currency }) {
    return this.getSelection({ rangeId, selectionId })._selectionPrizeMap[currency];
  }

  /**
   * @name getStage
   * @param {string} rangeId
   * @param {string} selectionId
   * @param {string} stageId
   * @returns BuilderStage
   */
  getStage({ rangeId, selectionId, stageId }) {
    return this.getSelection({ rangeId, selectionId })._stagesMap[stageId];
  }

  /**
   * @name getStagePrize
   * @param {string} rangeId
   * @param {string} selectionId
   * @param {string} stageId
   * @param {string} currency
   * @returns BuilderStagePrize
   */
  getStagePrize({ rangeId, selectionId, stageId, currency }) {
    return this.getStage({ rangeId, selectionId, stageId })._stagePrizeMap[currency];
  }

  /**
   * @name getActiveRangeTabs
   * @returns {boolean}
   */
  getActiveRangeTabs() {
    return !!(
      this.defaultCurrencyCode
      // && this._partnerIds.length
      // && this._gameIds.length
      // && this.name
      // && this.startDate
      // && this.endDate
      // && this.webDeletedDate
      // && this.pointType !== -1
    );
  };

  /**
   * @name getTotalBonus
   * @returns {number}
   */
  getTotalBonus() {
    const { _activeCurrency: currency } = this;
    const currentCurrency = this._currencyListMap[currency];
    if (!currency || !currentCurrency) {
      return 0;
    }
    const { precision } = currentCurrency;
    let sum = 0;
    this.ranges.forEach((range) => {
      const totalPrizeFund = this._rangeLimitsMap[`${range._id}_${currency}`]?.totalPrizeFund || 0;
      sum += Number(totalPrizeFund);
      range.selections.forEach((selection) => {
        sum += Number(selection._selectionPrizeMap[currency]?.prizeFund) || 0;
        selection.stages.forEach((stage) => {
          sum += Number(stage._stagePrizeMap[currency]?.prizeFund) || 0;
        });
      });
    });
    return Number(sum.toFixed(precision));
  }

  /** getters end */

  /** initialFilter setters start */

  /**
   * @name setPartners
   * @param {Array<{value: string}>}list
   * @returns {TournamentBuilder}
   */
  setPartners(list) {
    this._partnerIds = list.map(({ value }) => value);
    return this.clone();
  };

  /**
   * @name setPlatforms
   * @param {Array<{value: string}>} list
   * @returns {TournamentBuilder}
   */
  setPlatforms(list) {
    this._platformIds = list.map(({ value }) => value);
    return this.clone();
  };

  /**
   * @name setGameId
   * @param {Array<{value: string}>}list
   * @return {TournamentBuilder}
   */
  setGameId(list) {
    this._gameIds = list.map(({ value }) => value);
    return this.clone();
  };

  /**
   * @name setActiveCurrency
   * @param {string} currencyCode
   * @return {TournamentBuilder}
   */
  setActiveCurrency(currencyCode) {
    this._activeCurrency = currencyCode;
    return this.clone();
  };

  /**
   * @name setActiveRange
   * @param {string} rangeId
   * @return {TournamentBuilder}
   */
  setActiveRange(rangeId) {
    this._activeRange = rangeId;
    return this.clone();
  };

  /**
   * @name setName
   * @param {string} name
   * @returns {TournamentBuilder}
   */
  setName(name) {
    this.name = name;
    return this.clone();
  }

  /**
   * @name setPointType
   * @param {string} type
   * @returns {TournamentBuilder}
   */
  setPointType(type) {
    this.pointType = type;
    return this.clone();
  }

  /**
   * @name setStartDate
   * @param {string} date
   * @returns void
   */
  setStartDate(date) {
    this.startDate = date;
  }

  /**
   * @name setEndDate
   * @param {string} date
   * @returns {void}
   */
  setEndDate(date) {
    this.endDate = date;
  }

  /**
   * @name setWebDeletedDate
   * @param {string} date
   */
  setWebDeletedDate(date) {
    this.webDeletedDate = date;
  }

  /** stage setters start */

  /**
   * @name setStepCount
   * @param {string} rangeId
   * @param {string} selectionId
   * @param {string} stageId
   * @param {string} currency
   * @param {string} count
   * @returns {TournamentBuilder}
   */
  setStageCount({ rangeId, selectionId, stageId, currency, count }) {
    this.getStage({ rangeId, selectionId, stageId }).count = Number(count);
    return this.clone();
  }

  /**
   * @name setStageBonus
   * @param {string} rangeId
   * @param {string} selectionId
   * @param {string} stageId
   * @param {string} currency
   * @param {string} bonus
   * @returns {TournamentBuilder}
   */
  setStageBonus({ rangeId, selectionId, stageId, currency, bonus }) {
    const stage = this.getStage({ rangeId, selectionId, stageId });
    const value = bonus;
    this._checkAndConvertStageBonusAmounts({ stage, currency, value: Number(bonus) });
    stage._stagePrizeMap[currency].prizeFund = value;
    return this.clone();
  }

  /** stage setters end */

  /**
   * @name setSelectionPrizeFund
   * @param {string} rangeId
   * @param {string} selectionId
   * @param {string} bonus
   * @param {string} currency
   * @return {TournamentBuilder}
   */
  setSelectionPrizeFund({ rangeId, selectionId, bonus, currency }) {
    const value = bonus;
    const selection = this.getSelection({ rangeId, selectionId });
    this._checkAndConvertSelectionPrizeFund({ selection, currency, value: Number(bonus) });
    selection._selectionPrizeMap[currency].prizeFund = value;
    return this.clone();
  }

  /**
   *
   * @param {string} rangeId
   * @param {string} selectionId
   * @param {Array<{label: string, value: number}>} values
   * @returns {TournamentBuilder}
   */
  setSelectionBetTypes({ rangeId, selectionId, values }) {
    const selection = this.getSelection({ rangeId, selectionId });
    selection._gameBetTypes = values.map(({ value }) => value);
    return this.clone();
  }

  /**
   * @name setRangeOverallPrizeFund
   * @param {string} rangeId
   * @param {string} value
   * @returns {TournamentBuilder}
   */
  setRangeTotalPrizeFund({ rangeId, value }) {
    const amount = Number(value);
    const currency = this._activeCurrency;
    const rangeLimit = this._rangeLimitsMap[`${rangeId}_${currency}`];
    this._checkAndConvertRangeTotalPrizeFund({ rangeId, currency, value: amount });
    rangeLimit.totalPrizeFund = value;
    return this.clone();
  }

  /** initialFilter setters end */

  togglePartnerEnabled(id) {
    const index = this.partners.findIndex(({ partnerId }) => partnerId === id);
    if (index !== -1) {
      this.partners[index].isEnabled = !this.partners[index].isEnabled;
    }
  }

  /**
   * @name setRangeLimitMinAmount
   * @param {string} value
   * @param {string} rangeId
   * @param {string} currency
   * @returns {TournamentBuilder}
   */
  setRangeLimitMinAmount(value, { rangeId, currency }) {
    const amount = Number(value);
    this._checkAndConvertRangeLimitsMinAmounts({ rangeId, value: amount, currency });
    this._rangeLimitsMap[`${rangeId}_${currency}`].minAmount = value;
    return this.clone();
  };

  /**
   * @name setRangeLimitMaxAmount
   * @param {string} value
   * @param {string} rangeId
   * @param {string} currency
   * @returns {TournamentBuilder}
   */
  setRangeLimitMaxAmount(value, { rangeId, currency }) {
    const amount = Number(value);
    this._checkAndConvertRangeLimitsMaxAmounts({ rangeId, currency, value: amount });
    this._rangeLimitsMap[`${rangeId}_${currency}`].maxAmount = value;
    return this.clone();
  };

  /** adding actions start */

  /**
   * @name addCurrency
   * @param {BuilderCurrencyData} currencyData
   * @returns {TournamentBuilder}
   */
  addCurrency(currencyData) {
    if (this._checkCurrency(currencyData)) {
      this._addRangeData(currencyData);
    }
    return this.clone();
  };

  /**
   * @name addCurrencyList
   * @param {Array<BuilderCurrencyData>} currencyList
   * @returns {TournamentBuilder}
   */
  addCurrencyList(currencyList) {
    this._currencyMap._list = [];
    const currencyListMap = currencyList.reduce((map, item) => {
      const { value } = item;
      if (this._checkDefaultCurrency(value)) {
        this._currencyMap._list.unshift(value);
      } else {
        this._currencyMap._list.push(value);
      }
      map[value] = item;
      return map;
    }, {});

    this._currencyMap._list.forEach((code) => {
      if (!currencyListMap[code]) {
        this.deleteCurrency(code);
      }
    });

    currencyList.forEach((item) => {
      const { value } = item;

      if (!this._currencyMap[value]) {
        this.addCurrency(item);
      }
    });
    if (!this.ranges.find(({ _id }) => _id === this._activeRange)) {
      this._activeRange = this.ranges[0]._id;
    }
    return this.clone();
  }

  resetCurrency() {
    this._activeCurrency = '';
    this.defaultCurrencyCode = '';
    this._currencyMap = {
      default: null,
      _list: [],
    };
    this.ranges = [];
    return this.clone();
  }

  /**
   * @name addRange
   * @param {string} rangeId
   * @returns {TournamentBuilder}
   */
  addRange({ rangeId } = {}) {
    /** @type {BuilderRange} */
    const defaultRange = this._rangeMap[rangeId] || this.ranges[0];
    const range = this._createRange();

    defaultRange.rangeLimits.forEach((oldRangeLimit) => {
      const { currencyCode } = oldRangeLimit;
      const rangeLimit = this._createRangeLimit(currencyCode, range._id);
      this._cloneRangeLimit(oldRangeLimit, rangeLimit);
      range.rangeLimits.push(rangeLimit);
    });

    defaultRange.selections.forEach((defaultSelection) => {
      const selection = this._createSelection();
      this._cloneSelection(defaultSelection, selection);
      defaultSelection.selectionPrizes.forEach((defaultSelectionPrize) => {
        const { currencyCode } = defaultSelectionPrize;

        const selectionPrize = this._createSelectionPrize(currencyCode);
        this._cloneSelectionPrize(defaultSelectionPrize, selectionPrize);

        selection._selectionPrizeMap[currencyCode] = selectionPrize;
        selection.selectionPrizes.push(selectionPrize);
      });

      defaultSelection.stages.forEach((defaultStage) => {
        const stage = this._createStage();

        this._cloneStage(defaultStage, stage);
        defaultStage.stagePrizes.forEach((defaultStagePrize) => {
          const { currencyCode } = defaultStagePrize;

          const stagePrize = this._createStagePrize(currencyCode);
          this._cloneStagePrize(defaultStagePrize, stagePrize);

          stage._stagePrizeMap[currencyCode] = stagePrize;
          stage.stagePrizes.push(stagePrize);
        });

        selection._stagesMap[stage._id] = stage;
        selection.stages.push(stage);
      });

      range._selectionMap[selection._id] = selection;
      range.selections.push(selection);
    });
    this.ranges.push(range);
    return this.clone();
  }

  /**
   * @name addSelection
   * @param rangeId
   * @returns {TournamentBuilder}
   */
  addSelection(rangeId) {
    const range = this.getRange(rangeId);
    const selection = this._createSelection();
    this.getRange(rangeId)._selectionMap[selection._id] = selection;

    this._currencyMap._list.forEach(currencyCode => {
      const selectionPrize = this._createSelectionPrize(currencyCode);
      selection.selectionPrizes.push(selectionPrize);
      selection._selectionPrizeMap[currencyCode] = selectionPrize;
    });

    const currentStage = range.selections.length ? range.selections[0].stages : [undefined];

    currentStage.forEach(() => {
      const stage = this._createStage();

      this._currencyMap._list.forEach(currencyCode => {
        const stagePrize = this._createStagePrize(currencyCode);
        stage.stagePrizes.push(stagePrize);
        stage._stagePrizeMap[currencyCode] = stagePrize;
      });

      selection._stagesMap[stage._id] = stage;
      selection.stages.push(stage);
    });

    this.getRange(rangeId).selections.push(selection);

    return this.clone();
  }

  /**
   * @name addStage
   * @param {string} rangeId
   * @returns {TournamentBuilder}
   */
  addStage(rangeId) {
    this.getRange(rangeId).selections.forEach((selection) => {
      const stage = this._createStage();
      this._currencyMap._list.forEach((currencyCode) => {
        const stagePrize = this._createStagePrize(currencyCode);
        stage._stagePrizeMap[currencyCode] = stagePrize;
        stage.stagePrizes.push(stagePrize);
      });
      selection._stagesMap[stage._id] = stage;
      selection.stages.push(stage);
    });
    return this.clone();
  }

  /** adding actions end */

  /** delete actions start*/

  /**
   * @name deleteRange
   * @param {string} rangeId
   * @returns {TournamentBuilder}
   */
  deleteRange(rangeId) {
    delete this._rangeMap[rangeId];
    this.ranges = this.ranges.filter(({ _id }) => _id !== rangeId);
    this._activeRange = this.ranges[0]._id;
    this._currencyMap._list.forEach((currencyCode) => {
      delete this._rangeLimitsMap[`${rangeId}_${currencyCode}`];
    });
    return this.clone();
  }

  /**
   * @name deleteSelection
   * @param {string} rangeId
   * @param {string} selectionId
   * @returns {TournamentBuilder}
   */
  deleteSelection({ rangeId, selectionId }) {
    const range = this.getRange(rangeId);
    delete range._selectionMap[selectionId];
    range.selections = range.selections.filter(({ _id }) => _id !== selectionId);
    return this.clone();
  }

  /**
   * @name deleteStage
   * @param {string} rangeId
   * @param {string} selectionId
   * @param {string} stageId
   * @returns {TournamentBuilder}
   */
  deleteStage({ rangeId, selectionId, stageId }) {
    const selection = this.getSelection({ rangeId, selectionId });
    delete selection._stagesMap[stageId];
    selection.stages = selection.stages.filter(({ _id }) => _id !== stageId);
    return this.clone();
  }

  /**
   * @name deleteStateWithIndex
   * @param {string} rangeId
   * @param {string} stageIndex
   * @returns {TournamentBuilder}
   */
  deleteStateWithIndex({ rangeId, stageIndex }) {
    const range = this.getRange(rangeId);
    range.selections.forEach((selection) => {
      const stage = selection.stages[stageIndex];
      this.deleteStage({ rangeId, selectionId: selection._id, stageId: stage._id });
    });
    return this.clone();
  }

  /**
   * @name deleteCurrency
   * @param {string} currencyCode
   * @returns {TournamentBuilder}
   */
  deleteCurrency(currencyCode) {
    if (this._currencyMap.default.value === currencyCode) {
      const [, newDefaultCurrency] = this._currencyMap._list;
      this.defaultCurrencyCode = newDefaultCurrency;
      this._currencyMap.default = {
        value: newDefaultCurrency,
        id: newDefaultCurrency,
        label: newDefaultCurrency,
      };
    }

    this._currencyMap._list = this._currencyMap._list.filter((code) => code !== currencyCode);

    if (currencyCode === this._activeCurrency) {
      this._activeCurrency = this._currencyMap.default.value;
    }

    this.ranges.forEach((range) => {
      delete this._rangeLimitsMap[`${range._id}_${currencyCode}`];
      range.rangeLimits = range.rangeLimits
        .filter(({ currencyCode: rangeLimitCurrencyCode }) => currencyCode !== rangeLimitCurrencyCode);

      range.selections.forEach((selection) => {
        delete selection._selectionPrizeMap[currencyCode];
        selection.selectionPrizes = selection.selectionPrizes
          .filter(({ currencyCode: selectionPrizeCurrencyCode }) => currencyCode !== selectionPrizeCurrencyCode);

        selection.stages.forEach((stage) => {
          delete stage._stagePrizeMap[currencyCode];
          stage.stagePrizes = stage.stagePrizes
            .filter(({ currencyCode: stageCurrencyCode }) => currencyCode !== stageCurrencyCode);
        });
      });
    });
    delete this._currencyMap[currencyCode];
    return this.clone();
  }

  /** delete actions end*/

  /**
   * @name execute
   * @param {TournamentBuildType} type
   * @returns {TournamentBody|TournamentEditBody}
   */
  execute(type = TournamentBuildType.Add) {
    const data = deepCopy(this.clone());
    data.startDate = this.startDate;
    data.endDate = this.endDate;
    data.webDeletedDate = this.webDeletedDate;

    data.gameIds = data._gameIds;
    const partnerMap = data._partnerIds.reduce((map, id) => {
      map[id] = true;
      return map;
    }, {});
    data.pointType = Number(data.pointType);
    data.partners = data._partners.map(({ id }) => {
      return {
        partnerId: id,
        tournamentId: type === TournamentBuildType.Add ? undefined : this.tournamentId,
        isEnabled: partnerMap[id] || false,
      };
    });
    data.ranges.forEach((range) => {
      range.rangeLimits.forEach((rangeLimit) => {
        rangeLimit.minAmount = Number(rangeLimit.minAmount);
        rangeLimit.maxAmount = Number(rangeLimit.maxAmount);
        rangeLimit.overalPrizeFund = Number(rangeLimit.overalPrizeFund);
        rangeLimit.totalPrizeFund = Number(rangeLimit.totalPrizeFund);
      });
      range.selections.forEach((selection) => {
        selection.selectionPrizes.forEach((selectionPrize) => {
          selectionPrize.prizeFund = Number(selectionPrize.prizeFund);
          data._rangeLimitsMap[`${range._id}_${selectionPrize.currencyCode}`].overalPrizeFund += Number(selectionPrize.prizeFund);
        });
        selection.stages.forEach((stage) => {
          stage.stagePrizes.forEach((stagePrize) => {
            stagePrize.prizeFund = Number(stagePrize.prizeFund);
            data._rangeLimitsMap[`${range._id}_${stagePrize.currencyCode}`].overalPrizeFund += Number(stagePrize.prizeFund);
          });
          delete stage._id;
          delete stage._stagePrizeMap;
          delete stage.winningCount;
        });
        selection.gameBetTypes = selection._gameBetTypes.map((betType) => {
          return {
            gameId: 334, // FIXME hard code
            betType: AllMarketTypesReverse[betType],
          };
        });
        selection.displayName = selection.gameBetTypes.map(item => item.betType).join(',');
        delete selection._id;
        delete selection._stagesMap;
        delete selection._selectionPrizeMap;
        delete selection._gameBetTypes;
      });
      range.rangeLimits.forEach((rangeLimit) => {
        const nativeAmount = Number(data._rangeLimitsMap[`${range._id}_${rangeLimit.currencyCode}`].overalPrizeFund);
        const { precision } = data._currencyListMap[rangeLimit.currencyCode];
        rangeLimit.overalPrizeFund = Number(nativeAmount.toFixed(precision));
      });

      if (type === TournamentBuildType.Add) {
        delete range.winingCount;
      }

      if (type === TournamentBuildType.Edit) {
        data.currencyCodes = [...data._currencyMap._list];
      }

      delete range._id;
      delete range._selectionMap;
    });
    delete data._partnerIds;
    delete data._id;
    delete data._partners;
    delete data._gameIds;
    delete data._currencyMap;
    delete data._rangeLimitsMap;
    delete data._rangeMap;
    delete data._activeCurrency;
    delete data._activeRange;
    delete data._currencyListMap;
    delete data._platformIds;
    delete data._partnerIdsMap;
    delete data._once;

    return data;
  }
}
