import React from 'react';
import { Switcher as CoreSwitcher } from '@geneui/components';

export const Switcher = ({ ...props }) => {
  return (
    <CoreSwitcher
      {...props}
    />
  );
};
