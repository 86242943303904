import { action, makeObservable, observable } from 'mobx';

import { DashboardViewModes } from '../../core/enums/dashboard-view-mode';

class ToggleStore {

  buttonLoading = false;

  dashboardViewMode = DashboardViewModes.Widget;

  dashboardListViewSelectedGameLine = null;

  constructor() {
    makeObservable(this, {
      buttonLoading: observable,
      dashboardViewMode: observable,
      dashboardListViewSelectedGameLine: observable,
      setButtonLoading: action.bound,
      setDashboardViewMode: action.bound,
      setDashboardListViewSelectedGameLine: action.bound,
    });
  }

  /**
   * @name setButtonLoading
   * @param {boolean} state
   */
  setButtonLoading = (state) => {
    this.buttonLoading = state;
  }

  /**
   * @name setDashboardViewMode
   * @param {DashboardViewModes} data
   */
  setDashboardViewMode = (data) => {
    this.dashboardViewMode = data;
  }

  /**
   * @name setDashboardListViewSelectedGameLine
   * @param {number | null} value
   */
  setDashboardListViewSelectedGameLine = (value) => {
    this.dashboardListViewSelectedGameLine = value;
  }
}

export const toggleStore = new ToggleStore();
