import React from 'react';
import { Radio as CoreRadio } from '@geneui/components';

export const Radio = ({
  ...props
}) => {
  return (
    <CoreRadio
      {...props}
    />
  );
};
