import React, { useMemo } from 'react';
import styled from 'styled-components';

import { TranslationKeys as T } from '../../../../core/constants/translationKeys';
import ContainerTitle from '../../transferCheckbox/containerTitle';
import useTranslation from '../../../../hooks/useTranslation';
import { Section, EditActions } from '..';

const SectionWr = styled.section`
  padding: ${({ padding }) => padding};
  & .game-types-kv .kv-value {
    min-height: 32px;
    display: flex;
    align-items: center;
  }
`;

export const EditableSection = ({
  text,
  onSave,
  loading,
  onCancel,
  children,
  setEditMode,
  saveDisabled,
  editMode = false,
  hasTitle = true,
  padding = '1.6rem',
  editDisabled = false
}) => {

  const translate = useTranslation();

  const sectionHeader = useMemo(() => (
    <ContainerTitle
      noPadding
      text={translate(T.GENERAL_INFO)}
    />
  ), [translate]);

  const sectionActions = useMemo(() => (
    <EditActions
      onSave={onSave}
      loading={loading}
      onCancel={onCancel}
      editMode={editMode}
      saveDisabled={saveDisabled}
      editDisabled={editDisabled}
      onEdit={() => setEditMode(true)}
    />
  ), [editDisabled, onSave, loading, onCancel, editMode, saveDisabled, setEditMode]);

  return (
    <SectionWr padding={padding}>
      <Section
        text={hasTitle ? text || sectionHeader : null}
        actions={sectionActions}
      >
        {children}
      </Section>
    </SectionWr>
  );
};
