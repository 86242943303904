import styled from 'styled-components';

export const SizingBox = styled.div`
  display: flex;
  padding: 25px;
  border: 1px solid rgba(60, 64, 67, 0.3);
  width: 100px;
  height: 60px;
  border-radius: 10px;
  margin: 10px 40px;
  align-items: center;
  justify-content: center;

  &.view-small {
    font-size: 12px;
  }

  &.view-normal {
    font-size: 14px;
  }

  &.view-big {
    font-size: 18px;
  }

  &:hover {
    border-color: #1673E6;
    color: #1673E6;
  }
`;
