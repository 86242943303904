import { action, flow, makeObservable, observable } from 'mobx';

import { HttpResponse } from '../../core/classes/HttpResponse';
import { tournamentsService } from './tournamentsService';
import { TournamentBuilder } from './TournamentBuilder';

class TournamentsStore {
  /** @type {HttpResponse<TournamentsResponse>}*/
  tournaments = new HttpResponse({ count: 0, items: [] });

  /** @type {HttpResponse<TournamentDetailed>}*/
  tournament = new HttpResponse(null);

  /** @type {HttpResponse<TournamentRangeDetailed>}*/
  tournamentRange = new HttpResponse(null);

  /** @type {HttpResponse<TournamentPlayerResponse>} */
  tournamentPlayers = new HttpResponse({ count: 0, items: [] });

  /** @type {HttpResponse<TournamentPartnerResponse>}*/
  tournamentPartners = new HttpResponse({ count: 0, items: [] });

  /** @type {TournamentBuilder} */
  tournamentBody = new TournamentBuilder();

  constructor() {
    makeObservable(this, {
      tournaments: observable,
      tournament: observable,
      tournamentRange: observable,
      tournamentPlayers: observable,
      tournamentPartners: observable,
      tournamentBody: observable.deep,
      setTournaments: action.bound,
      setTournament: action.bound,
      setTournamentRange: action.bound,
      setTournamentPlayers: action.bound,
      setTournamentPartners: action.bound,
      setTournamentBody: action.bound,
      getTournaments: flow,
      getTournamentRange: flow,
      getTournamentsPlayers: flow,
      getTournamentsPartners: flow,
    });
  }

  /**
   * @name setTournamentBody
   * @param {TournamentBuilder} data
   */
  setTournamentBody(data) {
    this.tournamentBody = data;
  }

  /**
   * @name setTournaments
   * @param {HttpResponse<TournamentsResponse>} tournaments
   * @returns {void}
   */
  setTournaments = (tournaments) => {
    this.tournaments = tournaments;
  };

  /**
   * @name setTournament
   * @param {HttpResponse<TournamentDetailed>} tournament
   * @returns {void}
   */
  setTournament = (tournament) => {
    this.tournament = tournament;
  };

  /**
   * @name setTournamentRange
   * @param {HttpResponse<TournamentRangeDetailed>} tournamentRange
   * @returns {void}
   */
  setTournamentRange = (tournamentRange) => {
    this.tournamentRange = tournamentRange;
  };

  /**
   * @name setTournamentPlayers
   * @param {HttpResponse<TournamentPlayerResponse>} tournamentPlayers
   * @returns {void}
   */
  setTournamentPlayers = (tournamentPlayers) => {
    this.tournamentPlayers = tournamentPlayers;
  };

  /**
   * @name setTournamentPartners
   * @param {HttpResponse<TournamentPartnerResponse>} tournamentPartners
   * @returns {void}
   */
  setTournamentPartners = (tournamentPartners) => {
    this.tournamentPartners = tournamentPartners;
  };

  /**
   * @name addTournament
   * @param {TournamentBody} data
   * @return {Promise<void>}
   */
  addTournament = (data) => {
    return tournamentsService.addTournament(data);
  };

  /**
   * @name cancelTournament
   * @param {number} id
   * @returns {Promise<void>}
   */
  cancelTournament = (id) => {
    return tournamentsService.cancelTournament(id);
  };

  /**
   * @name editTournament
   * @param {TournamentEditBody} data
   * @returns {Promise<void>}
   */
  editTournament = (data) => {
    return tournamentsService.editTournament(data);
  };

  /**
   * @name editTournamentsPartnersStatus
   * @param {TournamentPartner} data
   * @return {Promise<void>}
   */
  editTournamentsPartnersStatus(data) {
    return tournamentsService.editTournamentPartnerStatus(data);
  }

  /**
   * @name getTournament
   * @param {GetTournamentsParams=} params
   * @returns {Generator<Promise<TournamentsResponse>, void, *>}
   */
  getTournaments = function* (params) {
    this.setTournaments(this.tournaments.fetching());
    const tournaments = yield tournamentsService.getTournaments(params);
    this.setTournaments(this.tournaments.fetched(tournaments));
  };

  /**
   * @name getTournamentById
   * @param {number} id
   * @returns {Promise<TournamentDetailed>}
   */
  getTournamentById = async function (id) {
    this.setTournament(this.tournament.fetching());
    const tournament = await tournamentsService.getTournamentById(id);
    this.setTournament(this.tournament.fetched(tournament));
    return tournament;
  };

  /**
   * @name getTournamentRange
   * @param {number} rangeId
   * @param {TournamentsRangParams} params
   * @returns {Generator<Promise<TournamentRangeDetailed>, void, *>}
   */
  getTournamentRange = function* (rangeId, params) {
    this.setTournamentRange((this.tournamentRange.fetching()));
    const ranges = yield tournamentsService.getTournamentsRange(rangeId, params);
    this.setTournamentRange(this.tournamentRange.fetched(ranges));
  };

  /**
   * @name getTournamentsPlayers
   * @param {TournamentsPlayersParams} params
   * @returns {Generator<Promise<TournamentPlayerResponse>, void, *>}
   */
  getTournamentsPlayers = function* (params) {
    this.setTournamentPlayers(this.tournamentPlayers.fetching());
    const players = yield tournamentsService.getTournamentPlayers(params);
    this.setTournamentPlayers(this.tournamentPlayers.fetched(players));
  };

  /**
   * @name getTournamentsPartners
   * @param {TournamentsPartnerParams=} params
   * @returns {Generator<*, void>}
   */
  getTournamentsPartners = function* (params = {}) {
    this.setTournamentPartners(this.tournamentPartners.fetching());
    const partners = yield tournamentsService.getTournamentsPartners(params);
    this.setTournamentPartners(this.tournamentPartners.fetched(partners));
  };

  /**
   * @name resetTournament
   * @returns {void}
   */
  resetTournament() {
    this.setTournament(this.tournament.fetching(true));
  }

  /**
   * @name resetTournamentBody
   * @returns {void}
   */
  resetTournamentBody() {
    this.setTournamentBody(new TournamentBuilder());
  }
}

export const tournamentsStore = new TournamentsStore();
