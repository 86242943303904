import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { combineQuery, parseQuery } from '../core/helpers/queryHelper';
import { deepEqual } from '../core/helpers/deepEqual';
import { DEFAULT_TAKE } from '../core/constants/util';

const useQuery = () => {
  const location = useLocation();
  const [queryState, setQueryState] = useState({
    skip: 0,
    take: DEFAULT_TAKE,
    ...parseQuery(location.search),
  });
  const ref = useRef({ oldQuery: queryState });

  useEffect(() => {
    if (!location.search) {
      const query = { skip: 0, take: DEFAULT_TAKE };
      ref.current.oldQuery = query;
      setQueryState(query);
    } else {
      const query = parseQuery(location.search);
      const newQuery = {
        skip: query.skip || '0',
        take: query.take || `${DEFAULT_TAKE}`,
        ...query
      };
      if (!deepEqual(ref.current.oldQuery, newQuery)) {
        ref.current.oldQuery = newQuery;
        setQueryState(newQuery);
      }
    }
  }, [location, ref]);

  return {
    query: combineQuery(queryState),
    queryObject: queryState,
  };
};

export default useQuery;