import React from 'react';
import { Paper } from '@geneui/components';
import styled from 'styled-components';

import { Icon } from '../shared';
import { TranslationKeys } from '../../../core/constants/translationKeys';
import useTranslation from '../../../hooks/useTranslation';

const FooterContent = styled.div`
  display: flex;
  align-items: center;
`;

const CloseText = styled.span`
  margin-left: 10px;
  font-weight: 600;
  line-height: 1.36;
`;

const MobilePopoverFooter = ({
  onCancel,
  cancelText = TranslationKeys.CLOSE
}) => {

  const translate = useTranslation();

  return (
    <Paper padding="12px">
      <FooterContent onClick={onCancel}>
        <Icon type="bc-icon-close" />
        <CloseText>{translate(cancelText)}</CloseText>
      </FooterContent>
    </Paper>
  );
};

export default MobilePopoverFooter;
