import React, { Suspense } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import rootRoutes from '../router';
import NestedRoutes from '../nestedRoutes';
import NotFound from '../../view/pages/not-found';
import PermissionGuard from '../../view/containers/permissionGuard';
import { Loader } from '../../view/components/shared';

const DefaultRoutes = props => {

  return (
    <Switch>
      {rootRoutes.map(route => {
        if (route.innerRoutes?.length) {
          return (
            <Route
              key={route.path}
              path={`${route.path}`}
            >
              <NestedRoutes
                routes={route.innerRoutes}
                {...props}
              />
            </Route>
          );
        }
        return (
          <Route
            exact={route.exact}
            key={route.path}
            path={route.path}
          >
            <Suspense fallback={(
              <Loader isBusy type="spinner"/>
            )}>
              <PermissionGuard requiredPermissions={route.permissions} isPage>
                <route.component {...props} />
              </PermissionGuard>
            </Suspense>
          </Route>
        );
      })}
      <Route component={NotFound}/>
    </Switch>
  );
};

export default withRouter(DefaultRoutes);
