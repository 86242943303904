import React from 'react';
import { Overlay as CoreOverlay, Paper } from '@geneui/components';

import { noop } from '../../../../core/constants/util';
import useTranslation from '../../../../hooks/useTranslation';

import './style.css';

export const Overlay = ({
  title,
  isOpen,
  children,
  description,
  className = '',
  onClose = noop,
  noPadding = false,
  withBackDrop = true,
  ...restProps
}) => {

  const translate = useTranslation();

  return (
    isOpen ? (
      <CoreOverlay
        position="right"
        onClose={onClose}
        title={translate(title)}
        description={description}
        withBackDrop={withBackDrop}
        className={`${className} ${noPadding ? 'no-padding' : ''} bo-overlay`}
        {...restProps}
      >
        <Paper
          justifyContent="paper-end"
          isTransparent={false}
          padding={0}
        >
          {children}
        </Paper>
      </CoreOverlay>
    ) : null
  );
};
