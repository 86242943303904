import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { Icon } from '..';

import './style.css';

export const Details = ({ title, subs, setIsOpen, isOpen }) => {

  const [open, setOpen] = useState(false);
  const controlled = !!setIsOpen;
  const noChildren = !subs.length;

  const handleToggle = useCallback(() => {
    if (noChildren) return;
    controlled ? setIsOpen() : setOpen(o => !o);
  }, [subs]);

  const isDetailsOpen = controlled ? isOpen : open;

  return (
    <section className="details_content">
      <header onClick={handleToggle} className="details_header details_element">
        <div className="details_circle max">
          {
            !noChildren &&
            <Icon className="details_circle_icon" type={open ? 'bc-icon-minus' : 'bc-icon-plus'}/>
          }
        </div>
        {title}
      </header>
      {isDetailsOpen && !noChildren && (
        <div className="details_main">
          {subs.map(({ id, title }) => (
            <div key={id} className="details_item details_element">
              <div className="details_circle min"/>
              {title}
            </div>
          ))}
        </div>
      )}
    </section>
  );
};

Details.propTypes = {
  title: PropTypes.string,
  subs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string,
  })),
};
