import { DEFAULT_HEADERS, httpClient, httpClientV2 } from '../httpClient/httpClient';
import {
  getTranslationsUrl,
  addTranslationsUrl,
  editTranslationsUrl,
  deleteTranslationsUrl,
  importTranslationsUrl,
  exportTranslationsUrl,
  getTranslationListUrl,
  addRichTranslationsUrl,
  editRichTranslationsUrl,
  getTranslationsDetailedUrl,
  exportSelectedTranslationsUrl,
  getTranslationLabelByAliasUrl,
  deleteAllTranslationsUrl,
} from '../../core/constants/endpoints';
import { ApiVersion } from '../../core/enums/ApiVersion';
import { combineQuery } from '../../core/helpers/queryHelper';

class TranslationService {

  /**
   * @name getTranslations
   * @param {GetTranslationsParams} params
   * @param {number} applicationId
   * @param {ApiOption=} option
   * @return {Promise<Object.<string, string>>}
   */
  async getTranslations(params, applicationId, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getTranslationsUrl(option), {
      params,
      headers: {
        applicationId
      },
    });
  }

  /**
   * @name getTranslationsv2
   * @param {GetTranslationsParams} params
   * @param {number} applicationId
   * @param {ApiOption=} option
   * @return {Promise<Object.<string, string>>}
   */
  async getTranslationsv2(params, applicationId, option = { version: ApiVersion.V1 }) {
    return await httpClientV2.get(getTranslationsUrl(option), {
      params,
      headers: {
        applicationId
      },
    });
  }

  /**
   * @name getTranslationList
   * @param {GetTranslationsParams} params
   * @param {number} applicationId
   * @param {ApiOption=} option
   * @return {Promise<Object.<string, GetTranslationListItem>>}
   */
  async getTranslationList(params, applicationId, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getTranslationListUrl(option), {
      params,
      headers: {
        applicationId
      },
    });
  }

  /**
   * @name addTranslations
   * @param {AddTranslationsData} data
   * @param {number} applicationId
   * @param {ApiOption=} option
   * @return {Promise<void>}
   */
  async addTranslations(data, applicationId, option = { version: ApiVersion.V1 }) {
    return await httpClient.post(addTranslationsUrl(option), data, {
      headers: {
        applicationId
      },
    });
  }

  /**
   * @name addRichTranslations
   * @param {AddRichTranslationsData} data
   * @param {number} applicationId
   * @param {ApiOption=} option
   * @return {Promise<void>}
   */
  async addRichTranslations(data, applicationId, option = { version: ApiVersion.V1 }) {
    return await httpClient.post(addRichTranslationsUrl(option), data, {
      headers: {
        applicationId
      },
    });
  }

  /**
   * @name editTranslations
   * @param {TranslationDetailed} data
   * @param {number} applicationId
   * @param {ApiOption=} option
   * @return {Promise<void>}
   */
  async editTranslations(data, applicationId, option = { version: ApiVersion.V1 }) {
    return await httpClient.put(editTranslationsUrl(option), data, {
      headers: {
        applicationId
      },
    });
  }

  /**
   * @name editRichTranslations
   * @param {EditRichTranslationsData} data
   * @param {number} applicationId
   * @param {ApiOption=} option
   * @return {Promise<void>}
   */
  async editRichTranslations(data, applicationId, option = { version: ApiVersion.V1 }) {
    return await httpClient.put(editRichTranslationsUrl(option), data, {
      headers: {
        applicationId
      },
    });
  }

  /**
   * @name deleteTranslations
   * @param {{alias: string}} data
   * @param {{partnerId: number}} params
   * @param {number} applicationId
   * @param {ApiOption=} option
   * @return {Promise<void>}
   */
  async deleteTranslations(data, params, applicationId, option = { version: ApiVersion.V1 }) {
    return await httpClient.delete(deleteTranslationsUrl(option), {
      data,
      params,
      headers: {
        applicationId
      },
    });
  }

  /**
   * @name deleteAllTranslations
   * @param {{ partnerId: string; deletePartnerTranslations: boolean }} data
   * @param {number} applicationId
   * @param {ApiOption=} option
   * @return {Promise<void>}
   */
  async deleteAllTranslations(data, applicationId, option = { version: ApiVersion.V1 }) {
    return await httpClient.delete(deleteAllTranslationsUrl(option), {
      data,
      headers: {
        applicationId
      },
    });
  }

  /**
   * @name getTranslationsDetailed
   * @param {string} alias
   * @param {{partnerId: number}} params
   * @param {number} applicationId
   * @param {ApiOption=} option
   * @return {Promise<TranslationDetailed>}
   */
  async getTranslationDetailed(alias, params, applicationId, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getTranslationsDetailedUrl(alias, option), {
      params,
      headers: {
        applicationId
      },
    });
  }

  /**
   * @name getTranslationLabelByAlias
   * @param {string} alias
   * @param {TranslationLabelByAliasQuery} params
   * @param {number} applicationId
   * @param {ApiOption=} option
   * @return {Promise<TranslationLabelByAlias>}
   */
  async getTranslationLabelByAlias(alias, params, applicationId, option = { version: ApiVersion.V1 }) {
    return await httpClientV2.get(getTranslationLabelByAliasUrl(alias, option), {
      params,
      headers: {
        applicationId
      },
    });
  }

  /**
   * @name exportTranslations
   * @param {ExportTranslationQuery} params
   * @param {number} applicationId
   * @param {ApiOption=} option
   * @return {Promise<File>}
   */
  async exportTranslations(params, applicationId, option = { version: ApiVersion.V1 }) {
    // axios does not support ReadableStream, so I use Fetch API to get excel file Stream. 
    return await fetch(`${exportTranslationsUrl(option)}${combineQuery(params)}`, {
      credentials: 'include',
      headers: {
        applicationId,
        ...DEFAULT_HEADERS
      }
    });
  }

  /**
   * @name exportSelectedTranslations
   * @param {ExportSelectedTranslationQuery} params
   * @param {number} applicationId
   * @param {ApiOption=} option
   * @return {Promise<File>}
   */
  async exportSelectedTranslations(params, applicationId, option = { version: ApiVersion.V1 }) {
    // axios does not support ReadableStream, so I use Fetch API to get excel file Stream. 
    return await fetch(`${exportSelectedTranslationsUrl(option)}${combineQuery(params)}`, {
      credentials: 'include',
      headers: {
        applicationId,
        ...DEFAULT_HEADERS
      }
    });
  }

  /**
   * @name importTranslations
   * @param {FormData} data
   * @param {number} applicationId
   * @param {ApiOption=} option
   * @return {Promise<void>}
   */
  async importTranslations(data, applicationId, option = { version: ApiVersion.V1 }) {
    return await httpClient.put(importTranslationsUrl(option), data, {
      headers: {
        applicationId
      },
    });
  }

}

export const translationService = new TranslationService();
