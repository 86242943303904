import { action, observable, makeObservable, flow, computed } from 'mobx';

import { HttpResponse } from '../../core/classes/HttpResponse';
import { notificationService } from './notificationService';

class NotificationStore {
  /**@type {HttpResponse<NotificationsResponse>}*/
  notifications = new HttpResponse({ items: [], count: 0, unreadNotificationsCount: 0 });

  /**
   * @name unReadNotificationsCount
   * @return {number}
   */
  get unReadNotificationsCount() {
    return this.notifications.data.unreadNotificationsCount;
  }

  constructor() {
    makeObservable(this, {
      notifications: observable,
      unReadNotificationsCount: computed,
      setNotifications: action.bound,
      getNotifications: flow.bound,
    });
  }

  /**
   * @name setNotifications
   * @param {HttpResponse<NotificationsResponse>} notifications
   * @returns {void}
   */
  setNotifications = (notifications) => {
    this.notifications = notifications;
  };

  /**
   * @name getApiNotifications
   * @param {NotificationsParams} params
   * @returns {Generator<Promise<NotificationsResponse>, void, *>}
   */
  getNotifications = function* (params) {
    const { concat, ...restParams } = params;
    this.setNotifications(this.notifications.fetching());
    const newData = yield notificationService.getNotifications(restParams);
    const data = concat ? this.notifications.concat(newData) : newData;
    this.setNotifications(this.notifications.fetched(data));
  };

  /**
   * @name readNotification
   * @returns {Promise<void>}
   */
  readNotification = (notificationId) => {
    return notificationService.readNotification(notificationId);
  };

  /**
   * @name readAllNotifications
   * @return {Promise<void>}
   */
  readAllNotifications = () => {
    return notificationService.readAllNotifications();
  };

}

export const notificationStore = new NotificationStore();
