import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { CheckboxGroup, Paper } from '@geneui/components';

import { getFilteredData } from '../../../../../core/helpers/createPartnerHelpers';
import useTranslation from '../../../../../hooks/useTranslation';
import ContainerSearch from '../../containerSearch';
import { ListContent } from '../category';
import { TranslationKeys } from '../../../../../core/constants/translationKeys';
import ContainerTitle from '../../../transferCheckbox/containerTitle';
import { Divider } from '../../../shared';

const Games = observer(({
  setSelectedGames,
  selectedGames,
  filteredGameByCategory,
}) => {
  const translate = useTranslation();
  const [searchData, setSearchData] = useState(filteredGameByCategory);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    setSearchData([...filteredGameByCategory]);
  }, [filteredGameByCategory]);

  const handleGameChange = useCallback((gameKey) => {
    const filteredData = filteredGameByCategory.filter((game) => gameKey.includes(game.value));
    setSelectedGames([...filteredData]);
  }, [filteredGameByCategory, setSelectedGames]);

  const handleGameSearch = useCallback((event) => {
    setSearchData([...getFilteredData(event, filteredGameByCategory)]);
    setSearchValue(event.target.value);
  }, [filteredGameByCategory, searchValue]);

  const searchedGameValue = useMemo(() => {
    return selectedGames.map(game => game.value);
  }, [selectedGames]);

  const titleRightValue = `${selectedGames.length}/${filteredGameByCategory.length}`;

  return (
    <Paper
      padding={0}
      paperDirection="column"
      shadow
      border
      isTransparent
      cornerRadius="full-radius"
      withLine
    >
      <ContainerTitle text={translate(TranslationKeys.GAMES)} value={titleRightValue}/>
      <Divider
        type="horizontal"
        size="379"
      />
      <ContainerSearch onChange={handleGameSearch}/>
      <Divider
        type="horizontal"
        size="379"
      />
      <ListContent>
        <CheckboxGroup
          className="category_checkbox"
          showSelectAll={(searchValue && searchData.length < 1) && !searchValue || (searchData.length >  1) && !searchValue}
          data={searchData}
          onChange={handleGameChange}
          value={searchedGameValue}
        />
      </ListContent>
    </Paper>
  );
});

export default Games;
