/**
 * @name scrollToBottom
 * @param {HTMLElement} elem
 * @returns void
 */
export const scrollToTop = (elem) => {
  if (elem) {
    elem.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
};
/**
 * @name scrollToBottom
 * @param {HTMLElement} elem
 * @returns void
 */
export const scrollToBottom = (elem) => {
  if (elem) {
    elem.scrollTo({
      top: elem.scrollHeight,
      behavior: 'smooth'
    });
  }
};

/**
 * @name isScrolledIntoView
 * @param {HTMLElement} elem
 * @returns boolean
 */
export const isScrolledIntoView = (elem) => {
  if (!elem) return false;
  const rect = elem.getBoundingClientRect();
  const { top, bottom } = rect;
  return top < window.innerHeight && bottom >= 0;
};
