import React from 'react';
import ReactDOM from 'react-dom';
import SwiperCore, { Navigation, Pagination } from 'swiper';

import * as serviceWorker from './serviceWorker';
import AppWrapper from './view/index';

import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
import './index.css';

SwiperCore.use([Pagination, Navigation]);

ReactDOM.render(<AppWrapper/>, document.getElementById('root'));

serviceWorker.unregister();
