import { action, flow, makeObservable, observable } from 'mobx';

import { downloadFileFromHttpResponse } from '../../core/helpers/fileDownloader';
import { HttpResponse } from '../../core/classes/HttpResponse';
import { translationService } from './translationService';

class TranslationStore {
  
  /** @type {boolean}*/
  aliasMode = false;
  
  /** @type {HttpResponse<Array<Translation>>}*/
  translations = new HttpResponse([]);
  
  /** @type {HttpResponse<Object.<string, string>>}*/
  backofficeTranslations = new HttpResponse({});

  /** @type {HttpResponse<TranslationDetailed>}*/
  translationDetailed = new HttpResponse({});

  /** @type {HttpResponse<TranslationLabelByAlias>}*/
  labelByAlias = new HttpResponse({});

  constructor() {
    makeObservable(this, {
      aliasMode: observable,
      translations: observable,
      backofficeTranslations: observable,
      labelByAlias: observable,
      translationDetailed: observable,
      setAliasMode: action.bound,
      setTranslations: action.bound,
      resetTranslations: action.bound,
      setBackofficeTranslations: action.bound,
      setTranslationDetailed: action.bound,
      setLabelByAlias: action.bound,
      resetLabelByAlias: action.bound,
      getTranslations: flow,
      getBackofficeTranslations: flow,
      getTranslationDetailed: flow,
      getTranslationLabelByAlias: flow,
    });
  }

  /**
   * @name setAliasMode
   * @param {boolean} data
   * @return {void}
   */
   setAliasMode = (data) => {
    this.aliasMode = data;
  };

  /**
   * @name setTranslations
   * @param {HttpResponse<Array<Translation>>} data
   * @return {void}
   */
  setTranslations = (data) => {
    this.translations = data;
  };

  /**
   * @name resetTranslations
   * @return {void}
   */
  resetTranslations = (data) => {
    this.translations = this.translations.fetched([]);
  };

  /**
   * @name setBackofficeTranslations
   * @param {HttpResponse<Object.<string, string>>} data
   * @return {void}
   */
  setBackofficeTranslations = (data) => {
    this.backofficeTranslations = data;
  };

  /**
   * @name setTranslationDetailed
   * @param {HttpResponse<TranslationDetailed>} data
   * @return {void}
   */
   setTranslationDetailed = (data) => {
    this.translationDetailed = data;
  };

  /**
   * @name setLabelByAlias
   * @param {HttpResponse<TranslationLabelByAlias>} data
   * @return {void}
   */
   setLabelByAlias = (data) => {
    this.labelByAlias = data;
  };

  /**
   * @name resetLabelByAlias
   * @return {void}
   */
  resetLabelByAlias = () => {
    this.labelByAlias = this.labelByAlias.fetched({});
  };

  /**
   * @name resetTranslationDetailed
   * @return {void}
   */
   resetTranslationDetailed = () => {
    this.setTranslationDetailed(this.translationDetailed.fetching(true));
  };

  /**
   * @name getTranslations
   * @param {GetTranslationsParams} params
   * @param {number} applicationId
   * @return {Generator<Promise<Object.<string, GetTranslationListItem>>, void, *>}
   */
  getTranslations = function* (params, applicationId) {
    this.setTranslations(this.translations.fetching());
    const res = yield translationService.getTranslationList(params, applicationId);
    const translations = Object.keys(res)
      .map(key => ({ alias: key, label: res[key].label, isDefault: res[key].isDefault }))
      .sort((a, b) => a.alias > b.alias ? 1 : -1);
    this.setTranslations(this.translations.fetched(translations));
  };

  /**
   * @name getBackofficeTranslations
   * @param {GetTranslationsParams} params
   * @param {number} applicationId
   * @return {Generator<Promise<Object.<string, string>>, void, *>}
   */
  getBackofficeTranslations = function* (params, applicationId) {
    this.setBackofficeTranslations(this.backofficeTranslations.fetching());
    const res = yield translationService.getTranslationsv2(params, applicationId);
    if (!res?.data?.errorMessage) {
      this.setBackofficeTranslations(this.backofficeTranslations.fetched(res));
    }
  };

  /**
   * @name getTranslationDetailed
   * @param {string} alias
   * @param {{partnerId: number}} params
   * @param {number} applicationId
   * @return {Generator<Promise<TranslationDetailed>, void, *>}
   */
  getTranslationDetailed = function* (alias, params, applicationId) {
    this.setTranslationDetailed(this.translationDetailed.fetching());
    const res = yield translationService.getTranslationDetailed(alias, params, applicationId);
    this.setTranslationDetailed(this.translationDetailed.fetched(res));
  };

  /**
   * @name getTranslationDetailed
   * @param {string} alias
   * @param {TranslationLabelByAliasQuery} params
   * @param {number} applicationId
   * @return {Generator<Promise<TranslationLabelByAlias>, void, *>}
   */
  getTranslationLabelByAlias = function* (alias, params, applicationId) {
    this.setLabelByAlias(this.labelByAlias.fetching());
    const res = yield translationService.getTranslationLabelByAlias(alias, params, applicationId);
    this.setLabelByAlias(this.labelByAlias.fetched(res));
  };

  /**
   * @name addTranslations
   * @param {AddTranslationsData} data
   * @param {number} applicationId
   * @returns {Promise<void>}
   */
  addTranslations = (data, applicationId) => {
    return translationService.addTranslations(data, applicationId);
  };

  /**
   * @name addTranslations
   * @param {AddRichTranslationsData} data
   * @param {number} applicationId
   * @returns {Promise<void>}
   */
  addRichTranslations = (data, applicationId) => {
    return translationService.addRichTranslations(data, applicationId);
  };

  /**
   * @name editTranslations
   * @param {TranslationDetailed} data 
   * @param {number} applicationId
   * @returns {Promise<void>}
   */
  editTranslations = (data, applicationId) => {
    return translationService.editTranslations(data, applicationId);
  };

  /**
   * @name editRichTranslations
   * @param {EditRichTranslationsData} data
   * @param {number} applicationId
   * @returns {Promise<void>}
   */
  editRichTranslations = (data, applicationId) => {
    return translationService.editRichTranslations(data, applicationId);
  };

  /**
   * @name deleteTranslations
   * @param {{alias: string}} data
   * @param {{partnerId: number}} params
   * @param {number} applicationId
   * @returns {Promise<void>}
   */
  deleteTranslations = (data, params, applicationId) => {
    return translationService.deleteTranslations(data, params, applicationId);
  };

  /**
   * @name deleteAllTranslations
   * @param {{ partnerId: string; deletePartnerTranslations: boolean }} data
   * @param {number} applicationId
   * @returns {Promise<void>}
   */
  deleteAllTranslations = (data, applicationId) => {
    return translationService.deleteAllTranslations(data, applicationId);
  };

  /**
   * @name importTranslations
   * @param {FormData} formData
   * @param {number} applicationId
   * @returns {Promise<void>}
   */
  importTranslations = (formData, applicationId) => {
    return translationService.importTranslations(formData, applicationId);
  };

  /**
   * @name exportTranslations
   * @param {ExportTranslationQuery} params
   * @param {number} applicationId
   * @returns {Promise<File>}
   */
  exportTranslations = async (params, applicationId) => {
    const res = await translationService.exportTranslations(params, applicationId);
    if (!res.ok) {
      throw new Error('Something went wrong while exporting.');
    }
    try {
      downloadFileFromHttpResponse(res, 'translations.xlsx');
    } catch (error) {
      console.error(error);
    }
    return res;
  };

  /**
   * @name exportTranslations
   * @param {ExportSelectedTranslationQuery} params
   * @param {number} applicationId
   * @returns {Promise<File>}
   */
  exportSelectedTranslations = async (params, applicationId) => {
    const res = await translationService.exportSelectedTranslations(params, applicationId);
    if (!res.ok) {
      throw new Error('Something went wrong while exporting.');
    }
    try {
      downloadFileFromHttpResponse(res, 'translations.xlsx');
    } catch (error) {
      console.error(error);
    }
    return res;
  };

}

export const translationStore = new TranslationStore();
