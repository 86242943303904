import { action, makeObservable, observable } from 'mobx';

import { ModalState } from './ModalState';

class ModalStore {
  /** @type {ModalState}*/
  partnerModal = new ModalState({ isVisible: false, data: null });

  /**@type {ModalState}*/
  createPlatformModal = new ModalState({ isVisible: false });

  /** @type {ModalState} */
  editGamesModal = new ModalState({ isVisible: false });

  /** @type {ModalState} */
  resetPasswordModal = new ModalState({ isVisible: false });

  /** @type {ModalState} */
  settingsModal = new ModalState({ isVisible: false });

  filtersChildren = null;

  mobilePopover = null;

  constructor() {
    makeObservable(this, {
      partnerModal: observable,
      createPlatformModal: observable,
      editGamesModal: observable,
      resetPasswordModal: observable,
      settingsModal: observable,
      filtersChildren: observable,
      mobilePopover: observable,
      setPartnerModal: action.bound,
      setCreatePlatformModal: action.bound,
      setEditGamesModal: action.bound,
      setResetPasswordModal: action.bound,
      setSettingsModal: action.bound,
      setFiltersChildren: action.bound,
      setMobilePopover: action.bound,
    });
  };

  /**
   * @name setMobilePopover
   * @param state
   * @returns {boolean}
   */
  setMobilePopover = (state) => {
    this.mobilePopover = state;
  }

  /**
   * @name setFiltersChildren
   * @param {node} children
   * @returns {node}
   */
  setFiltersChildren = (children) => {
    this.filtersChildren = children;
  }

  /**
   * @name setPartnerModal
   * @param {ModalState} partnerModal
   * @returns {void}
   */
  setPartnerModal = (partnerModal) => {
    this.partnerModal = partnerModal;
  };

  /**
   * @name setGamesModal
   * @param {ModalState} modal
   * @returns {void}
   */
  setCreatePlatformModal = (modal) => {
    this.createPlatformModal = modal;
  };

  /**
   * @name setEditGamesModal
   * @param {ModalState} modal
   * @returns {void}
   */
  setEditGamesModal(modal) {
    this.editGamesModal = modal;
  }

  /**
   * @name setResetPasswordModal
   * @param {ModalState} modal
   * @returns {void}
   */
  setResetPasswordModal(modal) {
    this.resetPasswordModal = modal;
  }

  /**
   * @name setSettingsModal
   * @param {ModalState} modal
   * @returns {void}
   */
  setSettingsModal(modal) {
    this.settingsModal = modal;
  }

  /**
   * @name openPartnerModal
   * @param {any} data additianal data
   * @returns {void}
   */
  openPartnerModal = (data) => {
    this.setPartnerModal(this.partnerModal.open(data));
  };

  /**
   * @name closePartnerModal
   * @returns {void}
   */
  closePartnerModal = () => {
    this.setPartnerModal(this.partnerModal.close());
  };

  /**
   * @name openCreatePlatformModal
   * @returns {void}
   */
  openCreatePlatformModal() {
    this.setCreatePlatformModal(this.createPlatformModal.open());
  }

  /**
   * @name closeCreatePlatformModal
   * @returns {void}
   */
  closeCreatePlatformModal() {
    this.setCreatePlatformModal(this.createPlatformModal.close());
  }

  /**
   * @name openEditGamesModal
   * @returns {void}
   */
  openEditGamesModal() {
    this.setEditGamesModal(this.editGamesModal.open());
  }

  /**
   * @name closeEditGamesModal
   * @returns {void}
   */
  closeEditGamesModal() {
    this.setEditGamesModal(this.editGamesModal.close());
  }

}

export const modalStore = new ModalStore();
