import { httpClient } from '../httpClient/httpClient';
import { ApiVersion } from '../../core/enums/ApiVersion';
import {
  getGamesUrl,
  getAllGamesUrl,
  editGameInfoUrl,
  getGameLinesUrl,
  getGamesInfoUrl,
  getGamesConfigsUrl,
  editGamesFeatureUrl,
  editGamesConfigsUrl,
  editGamesPartnersUrl,
  getGamesPlatformsUrl,
  getGameTypesByGameUrl,
  getGamesPlatformFeatureUrl,
  getGamesFeaturePartnersUrl,
} from '../../core/constants/endpoints';

class GamesService {

  /**
   * @name getGames
   * @param {GamesParams=} params
   * @param {ApiOption=} option
   * @returns {Promise<GameResponse>}
   */
  async getGames(params = {}, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getGamesUrl(option), { params });
  }

  /**
   * @name getAllGames
   * @param {GamesParams=} params
   * @param {ApiOption=} option
   * @returns {Promise<GameResponse>}
   */
  async getAllGames(params = {}, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getAllGamesUrl(option), { params });
  }

  /**
   * @name addGames
   * @param {GameData} data
   * @param {ApiOption=} option
   * @returns {Promise<AddGameResponse>}
   */
  async addGames(data, option = { version: ApiVersion.V1 }) {
    return await httpClient.post(getGamesUrl(option), data);
  }

  /**
   * @name editGames
   * @param {GameData} data
   * @param {ApiOption=} option
   * @returns {Promise<void>}
   */
  async editGames(data, option = { version: ApiVersion.V1 }) {
    return await httpClient.put(getGamesUrl(option), data);
  }

  /**
   * @name getGamesPlatforms
   * @param {number} id
   * @param {GamesParams=} params
   * @param {ApiOption=} option
   * @return {Promise<GamePlatformsResponse>}
   */
  async getGamesPlatforms(id, params = {}, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getGamesPlatformsUrl(id, option), { params });
  }

  /**
   * @name getGameTypesByGame
   * @param {number} id
   * @param {ApiOption=} option
   * @return {Promise<Array<GameTypeByGame>>}
   */
  async getGameTypesByGame(id, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getGameTypesByGameUrl(id, option));
  }

  /**
   * @name getGameLines
   * @param {GetGameLinesParams} params
   * @param {ApiOption=} option
   * @return {Promise<Array<number>>}
   */
  async getGameLines(params, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getGameLinesUrl(option), { params });
  }

  /**
   * @name getGamesInfo
   * @param {number} id
   * @param {ApiOption} option
   * @param {GetGameInfoResponse} params
   * @return {Promise<any>}
   */
  async getGamesInfo(id, params = {}, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getGamesInfoUrl(id, option), params);
  }

  /**
   * @name getGamesConfig
   * @param {number} id
   * @param {ApiOption} option
   * @return {Promise<any>}
   */
  async getGamesConfig(id, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getGamesConfigsUrl(id, option));
  }

  /**
   * @name getGamesPlatformFeature
   * @param {ApiOption} option
   * @param {GetPlatformFeatureParams} params
   * @return {Promise<GetPlatformFeatureResponse>}
   */
  async getGamesPlatformFeature(params, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getGamesPlatformFeatureUrl(option), { params });
  }

  /**
   * @name getGamesFeaturePartners
   * @param {number} id
   * @param {GetGamesFeaturePartnersParams} params
   * @param {ApiOption} option
   * @return {Promise<void>}
   */
  async getGamesFeaturePartners(id, params, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getGamesFeaturePartnersUrl(id, option), { params })
  }

  /**
   * @name editGamePartners
   * @param {EditGamePartnersBody} body
   * @param {number} id
   * @param {ApiOption=} option
   * @returns {Promise<void>}
   */
  async editGamePartners(id, body, option = { version: ApiVersion.V1 }) {
    return await httpClient.put(editGamesPartnersUrl(id, option), body);
  }

  /**
   * @name editGameConfigs
   * @param {number} id
   * @param {ConfigBody} body
   * @param {ApiOption} option
   * @returns {Promise<void>}
   */
  async editGameConfigs(id, body, option = { version: ApiVersion.V1 }) {
    return await httpClient.put(editGamesConfigsUrl(id, option), body);
  }

  /**
   * @name editGameFeature
   * @param {EditGameFeatureBody} body
   * @param {number} id
   * @param {ApiOption} option
   * @returns {Promise<void>}
   */
  async editGameFeature(id, body, option = { version: ApiVersion.V1 }) {
    return await httpClient.put(editGamesFeatureUrl(id, option), body);
  }

  /**
   * @name editGameInfo
   * @param {ApiOption} option
   * @return {Promise<void>}
   */
  async editGameInfo(body, option = { version: ApiVersion.V1 }) {
    return await httpClient.put(editGameInfoUrl(option), body);
  }
}

export const gamesService = new GamesService();
