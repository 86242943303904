import { REGEX_CONSTANTS } from "../constants/RegEx";

export const getDispositionFileName = (res) => {
  const disposition = res.headers.get('Content-Disposition');
  if (disposition) {
    const matches = REGEX_CONSTANTS.ContentDispositionFileName.exec(disposition);
    if (matches !== null) {
      return matches[2];
    }
  }
  return null;
}

/**
 * @name downloadFileFromHttpResponse
 * @param {Response} res
 * @param {string} name
 * @returns {void}
 */
export const downloadFileFromHttpResponse = async (res, name) => {
  let fileName = name;
  const dispositionFileName = getDispositionFileName(res);
  if (dispositionFileName) {
    fileName = decodeURIComponent(dispositionFileName);
  }
  const blob = await res.blob();
  fileDownloader(blob, fileName);
};

/**
 * @name fileDownloader
 * @param {Blob} blob
 * @param {string} name
 * @returns {void}
 */
export const fileDownloader = (blob, name) => {
  const blobUrl = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = blobUrl;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
  // clean up element
  link.parentNode.removeChild(link);
  // clean up Url
  window.URL.revokeObjectURL(blobUrl);
};
