import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { Time } from '@geneui/components';
import styled, { css } from 'styled-components';

import UserProfile from './profile';
import PartnerFilter from './partnerFilter';
import CurrencyFilter from './currencyFilter';
import HeaderCreateButton from '../headerCreate';
import { notificationStore } from '../../../module';
import DashboardNavigation from '../navigation';
import { useMobileSize } from '../../../hooks/useMobileSize';
import PermissionGuard from '../../containers/permissionGuard';
import { useSharedParams } from '../../../hooks/useSharedParams';
import { PERMISSIONS } from '../../../core/constants/permissions';
import { hasPermission } from '../../../core/helpers/permissionsHelper';
import NotificationsPopover from './notifications/notificationsPopover';
import { Divider } from '../shared';

import logo from '../../../assets/icons/favicon.png';

const HeaderContent = styled.header`
  display: grid;
  width: 100%;
  background-color: #3d3d5f;
  align-items: center;
  color: white;
  padding: ${props => props.mobile ? '10px' : '10px 20px'};
  grid-area: head;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;

  & .divider_style {
    background: rgba(255, 255, 255, 0.1);
  }
`;

const LeftSide = styled.div`
  display: flex;
  align-items: center;
`;

const RightSide = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  ${props => props.hide && css`
    display: none;
  `}
`;

const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${props => props.marginRight};
  width: ${props => props.width};

  & .logo_icon {
    font-size: ${props => props.iconFont};
  }

  & .badge-holder .badge-count {
    top: 0;
    right: 0;
    z-index: 2;
  }

  & .user-profile-text {
    color: ${props => props.profileColor || '#fff'};
  }

  & .user-profile-c {
    height: ${props => props.profileHeight || '36px'};
  }
`;

const Logo = styled.img`
  min-width: ${props => props.mobile ? '22px' : '30px'};
  height: ${props => props.mobile ? '22px' : '30px'};
`;

const Header = observer(() => {

  const { getNotifications, unReadNotificationsCount } = notificationStore;

  const { isMobile } = useMobileSize();
  const { search: sharedQuery } = useSharedParams();

  useEffect(() => {
    if (hasPermission([PERMISSIONS.ViewNotifications])) {
      getNotifications({ skip: 0, take: 20 });
    }
  }, [getNotifications]);

  return (
    <HeaderContent mobile={isMobile}>
      <HeaderContainer mobile={isMobile}>
        <LeftSide>
          <Item
            iconFont="30px"
            marginRight={isMobile ? '8px' : '15px'}
          >
            <Link to={{
              pathname: "/dashboard",
              search: sharedQuery,
            }}>
              <Logo mobile={isMobile} src={logo} alt="logo" />
            </Link>
          </Item>
          <Divider
            className="divider_style"
            type="vertical"
          />
          <Item>
            <DashboardNavigation/>
          </Item>
        </LeftSide>
        <RightSide hide={isMobile}>
          <PermissionGuard
            checkAll={false}
            requiredPermissions={[
              PERMISSIONS.AddPartner,
              PERMISSIONS.AddPlatform,
              PERMISSIONS.UpdateFreebet,
              PERMISSIONS.UpdateBonusIsland,
              PERMISSIONS.UpdateTournament
            ]}
          >
            <Item>
              <HeaderCreateButton/>
            </Item>
            <Divider
              className="divider_style"
              type="vertical"
            />
          </PermissionGuard>
          <Item>
            <PartnerFilter/>
          </Item>
          <Divider
            className="divider_style"
            type="vertical"
          />
          <Item>
            <CurrencyFilter/>
          </Item>
          <Divider
            className="divider_style"
            type="vertical"
          />
          <Item width="120px">
            <Time/>
          </Item>
          {
            <PermissionGuard requiredPermissions={[PERMISSIONS.ViewNotifications]}>
              <Divider
                className="divider_style"
                type="vertical"
              />
              <Item>
                <NotificationsPopover
                  unReadNotificationsCount={unReadNotificationsCount}
                />
              </Item>
            </PermissionGuard>
          }
          <Divider className="divider_style" />
          <Item>
            <UserProfile/>
          </Item>
        </RightSide>
        <RightSide hide={!isMobile}>
          <Item>
            <PartnerFilter/>
          </Item>
        </RightSide>
      </HeaderContainer>
    </HeaderContent>
  );
});

export default Header;
