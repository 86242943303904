export const REGEX_CONSTANTS = {
  Email: /^[\w-.]+@([\w-]+\.)+[\w-]{1,}$/g,
  TranslationAlias: /^[a-zA-Z0-9_]*$/,
  ContentDispositionFileName: /filename\*?=([^']*'')?([^;]*)/,
  NumberAndString: /^[a-zA-Z0-9\s]*$/,
  AtLeastOneNumber: /^(?=.*?[0-9]).*$/,
  AtLeastOneLowercase: /^(?=.*?[a-z]).*$/,
  AtLeastOneUppercase: /^(?=.*?[A-Z]).*$/,
  AtLeastOneSpecialSymbol: /^(?=.*?[#?!@$%^&*-]).*$/,
  Username: /^(?=.{6,30}$)(?![_.-])[a-zA-Z0-9._-](?!.+[_.-]$)[a-zA-Z0-9._-]+$/,
  Name: /^[a-zA-Z]*$/,
  WithoutSpace:  /^((?![\s]).)*$/,
  
  // number regexps
  AllNumbers: /^-?[0-9]*\.?[0-9]*$/,
  Integer: /^-?[0-9]*$/,
  Positive: /^[0-9]*\.?[0-9]*$/,
  PositiveAndInteger: /^[0-9]*$/,
  StartingWithZero: /^0[0-9].*$/,
  StartingWithDot: /^\.[0-9]*$/
};
