import React from 'react';
import { Title } from '@geneui/components';
import styled, { css } from 'styled-components';

const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${props => !props.noPadding && css`
    padding: 10px 15px 0;
  `}
  margin: ${props => props.margin}
`;

const RightSection = styled.span``;

const ContainerTitle = ({ text, value, noPadding, margin = 0 }) => {
  return (
    <Content noPadding={noPadding} margin={margin}>
      <Title
        className="title-text"
        text={text}
      />
      <RightSection>
        {value}
      </RightSection>
    </Content>
  );
};

export default ContainerTitle;
