export class ModalState {
  constructor({ isVisible }) {
    this.isVisible = isVisible;
  }

  /**
   * @name open
   * @param {any} data additianal data
   * @return {ModalState}
   */
  open(data) {
    this.isVisible = true;
    this.data = data;
    return this.clone();
  }

  /**
   * @name close
   * @return {ModalState}
   */
  close() {
    this.isVisible = false;
    this.data = null;
    return this.clone();
  }

  /**
   * @name toggle
   * @return {ModalState}
   */
  toggle() {
    this.isVisible = !this.isVisible;
    return this.clone();
  }

  /**
   * @name clone
   * @return {ModalState}
   */
  clone() {
    const clone = { ...this };
    Object.setPrototypeOf(clone, ModalState.prototype);
    return clone;
  };
}
