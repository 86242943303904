import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Menu } from '@geneui/components';
import { observer } from 'mobx-react-lite';

import { authStore, currenciesStore, notifyService, translationStore, userStore } from '../../../module';
import { applicationsStore } from '../../../module/applicationsModule';
import { TranslationKeys } from '../../../core/constants/translationKeys';
import { BACKOFFICE_APPLICATION_ID } from '../../../core/constants/util';
import { modalStore } from '../../../module/popUpModule';
import { footerTypes } from './index';
import useTranslation from '../../../hooks/useTranslation';

import './style.css';
import { TextInput } from '../shared';

const CURRENCY_SEARCH = 'CURRENCY_SEARCH';

const MobileProfile = observer(({ closePopover, handleSetCurrentFooter, handleOnBack }) => {

  const { userInfo: { data: userInfo } } = userStore;
  const { setResetPasswordModal, setSettingsModal } = modalStore;
  const { backofficeLanguages: { data: languages } } = applicationsStore;
  const { currencies: { data: currencies }, current: currentCurrency } = currenciesStore;

  const translate = useTranslation();
  const [ selectedLanguage, setLanguage ] = useState(null);
  const [ currencySearch, setCurrencySearch ] = useState('');
  const [ selectedCurrency, setSelectedCurrency ] = useState(null);

  useEffect(() => {
    if (currentCurrency.currencyCode) {
      setSelectedCurrency(currentCurrency.currencyCode);
    }
  }, [currentCurrency]);

  const handleCurrencySearchChange = useCallback(({ target: { value } }) => {
    setCurrencySearch(value);
  }, []);

  const selectedLanguageName = useMemo(() => {
    return languages.find(item => selectedLanguage ? item.id === selectedLanguage : item.isDefault)?.name || '';
  }, [selectedLanguage, languages]);

  const languageList = useMemo(() =>
    (
      languages.map(item => ({
        ...item,
        checkMark: true,
        active: selectedLanguage ? selectedLanguage === item.id : item.isDefault,
        title: item.name,
        onClick: () => {
          setLanguage(item.id);
          handleSetCurrentFooter({type: footerTypes.language, saveCallBack: () => {
            translationStore.getBackofficeTranslations({ languageId: item.id }, BACKOFFICE_APPLICATION_ID);
          }});
        },
      }))
    ), [languages, selectedLanguage]);

  const filteredCurrencies = useMemo(() => {
    return currencies.filter(({ currencyCode }) => currencyCode.includes(currencySearch.toUpperCase()));
  }, [currencies, currencySearch]);

  const currenciesList = useMemo(() =>
    (
      [ CURRENCY_SEARCH, ...filteredCurrencies ].map(item => ({
        ...item,
        checkMark: true,
        description: null,
        title: item.currencyCode,
        component: (item === CURRENCY_SEARCH ? (
          <TextInput
            type="search"
            value={currencySearch}
            className="mobile-currency-search"
            onChange={handleCurrencySearchChange}
            placeholder={translate(TranslationKeys.SEARCH)}
          />
        ) : null),
        active: selectedCurrency === item.currencyCode,
        onClick: () => {
          setSelectedCurrency(item.currencyCode);
          handleSetCurrentFooter({type: footerTypes.currency, saveCallBack: () => currencyChangeHandler(item.currencyCode)});
        },
      }))

    ), [filteredCurrencies, selectedCurrency]);

  const currencyChangeHandler = useCallback((currencyCode) => {
    setSelectedCurrency(currencyCode);
    userStore.editUserInfo({
      id: userInfo.id,
      currencyCode,
    })
    .then(() => {
      const result = currencies.find((item) => item.currencyCode === currencyCode);
      currenciesStore.setCurrentCurrency(result);
    })
    .catch((err) => {
      notifyService.error({
        message: err?.data?.errorMessage,
      });
    });
  }, [currencies]);

  const options = useMemo(() => ([
    {
      title: translate(TranslationKeys.SETTINGS),
      onClick: () => {
        closePopover();
        setSettingsModal({ isVisible: true });
      },
    },
    {
      title: translate(TranslationKeys.CHANGE_PASSWORD),
      onClick: () => {
        closePopover();
        setResetPasswordModal({ isVisible: true });
      },
    },
    {
      title: `${translate(TranslationKeys.CURRENCY)}: ${currentCurrency.currencyCode}`,
      children: currenciesList,
    },
    {
      title: `${translate(TranslationKeys.LANGUAGE)}: ${selectedLanguageName}`, // TODO: for Vahe Hakobyan -> store selected language in store
      divider: { hasFullWidth: true },
      children: languageList,
    },
    {
      title: translate(TranslationKeys.LOG_OUT),
      onClick: () => {
        closePopover();
        authStore.signOut();
      },
    },
  ]), [languageList, translate, currenciesList, currentCurrency, selectedLanguageName]);

  return (
    <Menu
      data={options}
      onBack={handleOnBack}
    />
  );
});

export default MobileProfile;
