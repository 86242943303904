import React from 'react';
import styled from 'styled-components';

import useTranslation from '../../../../../hooks/useTranslation';

const NotificationContent = styled.div`
  color: rgba(60, 64, 67, 0.6);
  margin-bottom: 4px;
`;

const NotificationValue = styled.span`
  margin-left: 8px;
  color: #3c4043;
`;

const NotificationLabelValue = ({ label, value }) => {

  const translate = useTranslation();

  return (
    <NotificationContent>
      { translate(label) }:
      <NotificationValue>
        { value }
      </NotificationValue>
    </NotificationContent>
  );
};

export default NotificationLabelValue;
