import React, { useCallback, useState } from 'react';
import { Col, Row, Paper } from '@geneui/components';

import { modalStore } from '../../../module/popUpModule';
import { notifyService, userStore } from '../../../module';
import { useMobileSize } from '../../../hooks/useMobileSize';
import { TranslationKeys } from '../../../core/constants/translationKeys';
import useTranslation from '../../../hooks/useTranslation';
import { Modal, ModalFooter, PasswordInput } from '../../components/shared';

const ResetPasswordModal = () => {

  const { setResetPasswordModal } = modalStore;
  const { changePassword, userInfo: { data: { id: userId } } } = userStore;

  const { isMobile } = useMobileSize();
  const translate = useTranslation();
  const [data, setData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const colSize = isMobile ? 12 : 4;
  const isSaveDisabled = !data.currentPassword || !data.newPassword || !data.confirmPassword;

  const closeModal = useCallback(() => {
    setResetPasswordModal({ isVisible: false });
  }, []);

  const handleChange = useCallback(({ target }) => {
    const { name, value } = target;
    setData(prev => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const handleSave = useCallback(() => {
    if (isSaveDisabled) return;
    changePassword({...data, userId})
      .then(() => {
        notifyService.success({
          message: TranslationKeys.PASSWORD_RESET_SUCCESS,
        });
        closeModal();
      })
      .catch(err => {
        notifyService.error({
          message: err?.data?.errorMessage,
        });
      });
  }, [isSaveDisabled, data, userId]);

  return (
    <Modal
      visible
      width={860}
      closable={false}
      onCancel={closeModal}
      position={isMobile ? 'bottom' : 'top'}
      style={{ padding: isMobile ? 0 : 'auto' }}
      appearance={isMobile ? 'default' : 'compact'}
      title={translate(TranslationKeys.CHANGE_PASSWORD)}
      className='reset-password-modal-title'
      footer={(
        <ModalFooter
          onCancel={closeModal}
          onSave={handleSave}
          saveDisabled={isSaveDisabled}
        />
      )}
    >
      <Paper
        alignItems='center'
        paperDirection='column'
      >
        <Row>
          <Col size={colSize}>
            <PasswordInput
              inputSize="big"
              name="currentPassword"
              onChange={handleChange}
              value={data.currentPassword}
              cornerRadius="full-radius"
              placeholder={translate(TranslationKeys.CURRENT_PASSWORD)}
            />
          </Col>
          <Col size={colSize}>
            <PasswordInput
              inputSize="big"
              name="newPassword"
              onChange={handleChange}
              value={data.newPassword}
              cornerRadius="full-radius"
              placeholder={translate(TranslationKeys.NEW_PASSWORD)}
            />
          </Col>
          <Col size={colSize}>
            <PasswordInput
              inputSize="big"
              name="confirmPassword"
              onChange={handleChange}
              value={data.confirmPassword}
              cornerRadius="full-radius"
              placeholder={translate(TranslationKeys.CONFIRM_PASSWORD)}
            />
          </Col>
        </Row>
      </Paper>
    </Modal>
  );
};

export default ResetPasswordModal;
