import React from 'react';
import { observer } from 'mobx-react-lite';

import { TranslationKeys as T } from '../../../../../../core/constants/translationKeys';
import NotificationLabelValue from '../../notification-label-value';
import { gamesStore } from '../../../../../../module';

const NewPartnerNotification = observer(({ data }) => {

  const { gameId, partnerExternalId, partnerName, platformName } = data;
  const { gamesMap } = gamesStore;

  return (
    <>
      <NotificationLabelValue
        label={T.GAME_NAME}
        value={gamesMap[gameId]?.name || '-'}
      />
      <NotificationLabelValue
        label={T.GAME_ID}
        value={gameId}
      />
      <NotificationLabelValue
        label={T.PARTNER_EXTERNAL_ID}
        value={partnerExternalId}
      />
      <NotificationLabelValue
        label={T.PARTNER_NAME}
        value={partnerName}
      />
      <NotificationLabelValue
        label={T.PLATFORM_NAME}
        value={platformName}
      />
    </>
  );
});

export default NewPartnerNotification;
