import React, { useMemo } from 'react';
import { Paper, Scrollbar } from '@geneui/components';
import styled from 'styled-components';

import { TranslationKeys } from '../../../../../core/constants/translationKeys';
import { GameCategoryNames } from '../../../../../core/enums/GameCategory';
import ContainerTitle from '../../../transferCheckbox/containerTitle';
import useTranslation from '../../../../../hooks/useTranslation';
import { Divider, Details } from '../../../shared';

const Content = styled.section`
  padding: 0 14px;
  height: 100%;
`;

const SelectedGames = ({ selectedGames }) => {

  const translate = useTranslation();
  const selectedGameMapByCategory = useMemo(() => {
    return selectedGames.reduce((acc, element) => {
      if (acc[element.category]) {
        acc[element.category].push(element);
      } else {
        acc[element.category] = [element];
      }
      return acc;
    }, {});
  }, [selectedGames]);

  return (
    <Paper
      padding={0}
      paperDirection="column"
      shadow
      border
      isTransparent
      cornerRadius="full-radius"
      withLine
    >
      <ContainerTitle text={translate(TranslationKeys.SELECTED_GAMES)} value={selectedGames.length}/>
      <Divider
        type="horizontal"
        size="379"
      />
      <Content>
        <Scrollbar className="select-games-checkbox">
          {Object.keys(selectedGameMapByCategory).map((category) => {
            return (
              <Details
                key={category}
                title={translate(GameCategoryNames[category])}
                subs={selectedGameMapByCategory[category]?.map(({ label, value }) => ({
                  value,
                  title: label,
                }))}
              />
            );
          })}
        </Scrollbar>
      </Content>
    </Paper>
  );
};

export default SelectedGames;

