import React from 'react';
import { observer } from 'mobx-react-lite';
import { ComboTable, Paper } from '@geneui/components';

import { selectorData as defaultSelectorData } from '../../../../core/constants/selectorData';
import { tableDataFormatter } from '../../../../core/helpers/tableDataFormatter';
import useTableColumns from '../../../../hooks/useTableColumns';
import { currenciesStore } from '../../../../module';

import './style.css';

export const UncontrolledTable = observer(({
  name,
  rows,
  border,
  columns,
  rowsCount,
  hideHeader,
  hideFooter,
  mappedRows,
  padding = 0,
  cornerRadius,
  rowKey = 'id',
  className = '',
  limitHeight = true,
  selectorData = defaultSelectorData,
  ...restProps
}) => {

  const { current: currentCurrency } = currenciesStore;
  const { cols } = useTableColumns(columns, currentCurrency);
  const tableRows = mappedRows || tableDataFormatter(rows);

  const _className = `${limitHeight ? 'table-height-limit' : ''} ${hideHeader ? 'table-without-header' : ''} ${hideFooter ? 'table-without-footer' : ''} ${className}`;

  return (
    <Paper
      isTransparent
      border={border}
      padding={padding}
      cornerRadius={cornerRadius}
    >
      <ComboTable
        name={name}
        columns={cols}
        rowKey={rowKey}
        rows={tableRows}
        rowsCount={rowsCount}
        className={_className}
        selectorData={selectorData}
        {...restProps}
      />
    </Paper>
  );
});
