import React from 'react';

import { Button } from '../../button';

export const PGTransferListArrows = ({
  onLeftArrowClick,
  onRightArrowClick,
  leftArrowDisabled,
  rightArrowDisabled
}) => {
  return (
    <div className="pg-transfer-list-arrows">
      <Button
        size="big"
        appearance="clean"
        icon="bc-icon-arrow-left"
        onClick={onLeftArrowClick}
        disabled={leftArrowDisabled}
      />
      <Button
        size="big"
        appearance="clean"
        icon="bc-icon-arrow-right"
        onClick={onRightArrowClick}
        disabled={rightArrowDisabled}
      />
    </div>
  );
};
