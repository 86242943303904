import React, { useCallback, useMemo } from 'react';
import { withRouter, useLocation, useHistory } from 'react-router-dom';
import { NavigationMenu } from '@geneui/components';
import { observer } from 'mobx-react-lite';

import { navigations } from '../../../core/constants/navigationConstants';
import { hasPermission } from '../../../core/helpers/permissionsHelper';
import { useMobileSize } from '../../../hooks/useMobileSize';
import useTranslation from '../../../hooks/useTranslation';
import useNavigation from '../../../hooks/useNavigation';
import { subRouteConfig } from './constants';

import './navigation.css';

const DashboardNavigation = React.memo(observer(() => {

  const translate = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { isMobile } = useMobileSize();
  const { getSearch, getSubSearch } = useNavigation();

  const handleChange = useCallback((path, item) => {
    const search = item.sharedParams ? getSubSearch(item.id, item.params) : getSearch(item.params);
    history.push(`/${path}${search}`);
  }, [history, getSearch, getSubSearch]);
  
  const mapNavConstantsToNavMenuModel = useCallback((navigationList) => {
    if (!navigationList) return null;
    return navigationList.map(({ key, text, subs, permissions, sharedParams, params = {} }) => {
      return {
        params,
        id: key,
        sharedParams,
        title: translate(text),
        isHidden: !hasPermission(permissions, false),
        data: mapNavConstantsToNavMenuModel(subs)
      };
    });
  }, [translate]);

  const navigationMenuData = useMemo(() => {
    return navigations
      .filter(({ showOnMobile }) => isMobile ? showOnMobile : true)
      .map(({ to, text, icon, permissions }) => {
        const id = to.replace('/', '');
        return {
          id,
          icon,
          title: translate(text),
          isHidden: !hasPermission(permissions, false),
          data: isMobile ? null : mapNavConstantsToNavMenuModel(subRouteConfig[id])
        };
      })
  }, [translate, isMobile, mapNavConstantsToNavMenuModel]);

  return (
    <NavigationMenu
      onChange={handleChange}
      data={navigationMenuData}
      value={location.pathname}
    />
  );
}));

export default withRouter(DashboardNavigation);
