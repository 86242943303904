export const mapDataToTransferListDataModel = (data, translateFn) => {
  const translate = translateFn || (arg => arg);
  return data.map(({ id, name }) => ({
    id: `${id}`,
    name: translate(name),
    title: translate(name)
  }));
};

const mapToOrderable = (list) => {
  return list.map((item, index) => ({ ...item, order: index + 1 }));
};

export const addOrderParamToPGTransferList = (data) => {
  const [ leftContainerName, rightContainerName ] = Object.keys(data);
  return {
    [leftContainerName]: mapToOrderable(data[leftContainerName]),
    [rightContainerName]: mapToOrderable(data[rightContainerName])
  };
};
