/**
 * @name FormType
 * @enum {number}
 * @type {{Number: number, RangeDate: number, Checkbox: number, Dropdown: number, SingleDate: number, Text: number, Radio: number, Time: number, RadioValue: number}}
 */
export const FormType = {
  Text: 0,
  Number: 1,
  RangeDate: 2,
  SingleDate: 3,
  Radio: 4,
  RadioValue: 5,
  Checkbox: 6,
  Time: 7,
  Dropdown: 8,
  Switcher: 9,
  File: 10,
  Object: 11,
};
