import React from 'react';
import { MobilePopup as CoreMobilePopup } from '@geneui/components';

import { TranslationKeys as T } from '../../../../core/constants/translationKeys';
import useTranslation from '../../../../hooks/useTranslation';

export const MobilePopup = ({
  leftAction,
  ...props
}) => {

  const translate = useTranslation();

  return (
    <CoreMobilePopup
      leftAction={{ text: translate(T.CLOSE) }}
      {...props}
    />
  );
};
