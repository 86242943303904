import React from 'react';
import { Checkbox as CoreCheckbox } from '@geneui/components';

export const Checkbox = ({
  ...props
}) => {
  return (
    <CoreCheckbox
      {...props}
    />
  );
};
