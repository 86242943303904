import { GameCategory } from './GameCategory';
import { TranslationKeys as T } from '../constants/translationKeys';

export const GameLineType = {
  Bog: 'bog',
  Arcade: 'arcade',
  Lottery: 'lottery',
  SportFun: 'sport-fun',
  Slot: 'slot',
  TableGames: 'table-games',
  BetShop: 'betshop',
  Virtual: 'virtual',
  CryptoGames: 'crypto-games',
  Bonus: 'bonus',
  Instant: 'instant',
};

export const GameLineTypeName = {
  [GameCategory.BetOnGames]: GameLineType.Bog,
  [GameCategory.Arcade]: GameLineType.Arcade,
  [GameCategory.BetOnLottery]: GameLineType.Lottery,
  [GameCategory.SportFan]: GameLineType.SportFun,
  [GameCategory.Slot]: GameLineType.Slot,
  [GameCategory.TableGames]: GameLineType.TableGames,
  [GameCategory.BetShop]: GameLineType.BetShop,
  [GameCategory.VirtualGames]: GameLineType.Virtual,
  [GameCategory.CryptoGames]: GameLineType.CryptoGames,
  [GameCategory.Bonus]: GameLineType.Bonus,
  [GameCategory.Instant]: GameLineType.Instant,
};

export const GameLineNames = {
  [GameLineType.Bog]: T.BET_ON_GAMES,
  [GameLineType.Arcade]: T.ARCADE,
  [GameLineType.Lottery]: T.BET_ON_LOTTERY,
  [GameLineType.SportFun]: T.SPORT_FUN,
  [GameLineType.Slot]: T.SLOT,
  [GameLineType.TableGames]: T.TABLE_GAMES,
  [GameLineType.BetShop]: T.BET_SHOP,
  [GameLineType.Virtual]: T.VIRTUAL_GAMES,
  [GameLineType.CryptoGames]: T.CRYPTO_GAMES,
  [GameLineType.Bonus]: T.BONUS,
  [GameLineType.Instant]: T.INSTANT,
};