import { httpClient } from '../httpClient/httpClient';
import { ApiVersion } from '../../core/enums/ApiVersion';
import {
  getStaticContentsUrl,
  addStaticContentsUrl,
  editStaticContentsUrl,
  deleteStaticContentsUrl,
} from '../../core/constants/endpoints';

class StaticContentsService {
  /**
   * @name getStaticContents
   * @description this endpoint need  for get static contents
   * @param {StaticContentsParams=} params
   * @param {ApiOption=} option
   * @returns {Promise<StaticContentsResponse>}
   */
  async getStaticContents(params = {}, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(getStaticContentsUrl(option), { params });
  }

  /**
   * @name addStaticContents
   * @description this endpoint need  for add static contents
   * @param {StaticContentBody} data
   * @param {ApiOption=} option
   * @returns {Promise<void>}
   */
  async addStaticContents(data, option = { version: ApiVersion.V1 }) {
    return await httpClient.post(addStaticContentsUrl(option), data);
  }

  /**
   * @name editStaticContents
   * @description this endpoint need for edit static contents
   * @param {StaticContentBody} data
   * @param {ApiOption=} option
   * @returns {Promise<void>}
   */
  async editStaticContents(data, option = { version: ApiVersion.V1 }) {
    return await httpClient.put(editStaticContentsUrl(option), data);
  }

  /**
   * @name deleteStaticContents
   * @description this endpoint need for delete static contents
   * @param {number} id
   * @param {ApiOption=} option
   * @returns {Promise<void>}
   */
  async deleteStaticContents(id, option = { version: ApiVersion.V1 }) {
    return await httpClient.delete(deleteStaticContentsUrl(id, option));
  }
}

export const staticContentsService = new StaticContentsService();
