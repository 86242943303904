import React from 'react';
import styled from 'styled-components';

import { TranslationKeys } from '../../../core/constants/translationKeys';
import useTranslation from '../../../hooks/useTranslation';
import Logo from '../../../assets/images/logo.png';
import { Icon } from '../../components/shared';

const Content = styled.section`
  left: 50%;
  top: 50%;
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: center;
  transform: translate(-50%, -50%);
`;

const ContentBottom = styled.section`
  display: flex;
  align-items: center;
`;

const Text = styled.section`
  margin-left: 1rem;
`;

const LogoImg = styled.img`
  padding: 2rem;
  width: 24rem;
`;

const AccessDenied = () => {

  const translate = useTranslation();

  return (
    <Content>
      <LogoImg src={Logo} />
      <ContentBottom>
        <Icon type="bc-icon-blocked" />
        <Text>{translate(TranslationKeys.USER_HAS_NO_PERMISSION)}</Text>
      </ContentBottom>
    </Content>
  );
};

export default AccessDenied;
