import axios from 'axios';

import { errorHandlerService } from '../errorHandlerModule';
import { combineQuery } from '../../core/helpers/queryHelper';

export const DEFAULT_HEADERS = {
  'Access-Control-Allow-Origin': '*',
  'Content-type': 'application/json',
};

export const httpClient = axios.create({
  withCredentials: true,
  headers: DEFAULT_HEADERS,
  paramsSerializer: (params) => {
    return combineQuery(params).slice(1);
  }
});

export const onFulfilled = res => res.data.result ?? res;

export const onRejected = error => {
  errorHandlerService.handle(error);
  throw error.response;
};

// httpClient is default
httpClient.interceptors.response.use(onFulfilled, onRejected);

export const httpClientV2 = axios.create({
  withCredentials: true,
  headers: DEFAULT_HEADERS,
  paramsSerializer: (params) => {
    return combineQuery(params).slice(1);
  }
});

export const onRejectedV2 = (error) => {
  errorHandlerService.handle(error);
  if (axios.isCancel(error)) {
    throw error.response;
  }
  return error?.response || { data: { errorMessage: 'SOMETHING_WENT_WRONG' } };
};

// httpClientV2 is designed for specific calls to handle errors
httpClientV2.interceptors.response.use(onFulfilled, onRejectedV2);
