import React, { useCallback, useMemo, useState } from 'react';
import { ExtendedInput } from '@geneui/components';
import { Icon } from '..';

import './style.css';

export const PasswordInput = ({
  value,
  labelAppearance = 'swap',
  ...props
}) => {

  const [ visible, setVisible ] = useState();

  const handleVisibilityIconClick = useCallback(() => {
    setVisible(prev => !prev);
  }, []);

  const toggleVisibilityIcon = useMemo(() => (
    <Icon
      type={visible ? 'bc-icon-activate-cms' : 'bc-icon-inactivate-cms'}
      onClick={handleVisibilityIconClick}
      className="visibility-icon"
    />
  ), [visible]);

  const endAdornment = useMemo(() => value ? toggleVisibilityIcon : null, [value, visible]);

  return (
    <ExtendedInput
      value={value}
      endAdornment={endAdornment}
      labelAppearance={labelAppearance}
      type={visible ? 'text' : 'password'}
      {...props}
    />
  );
};
